import { DatePipe } from '@angular/common';
import {
  HttpClient,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Stepper from 'bs-stepper';
// import * as $ from 'jquery';
import $ from 'jquery';
// import * as moment from 'moment';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { async } from '@angular/core/testing';
import { type, platform } from 'os';
// import { Querystring } from 'request/lib/querystring.js';
// import { ToastrManager } from 'ng6-toastr-notifications';
import { ToastrService } from 'ngx-toastr';
import { CdkStepperNext } from '@angular/cdk/stepper';
import { Observable, fromEvent, merge, of, Subject } from 'rxjs';
import { mapTo, takeUntil } from 'rxjs/operators';
import {
  headSpinToken,
  InterceptorSkipHeader
} from './../../../core/auth/jwt.interceptor';

import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Condition } from 'selenium-webdriver';
import { environment } from '../../../../environments/environment';
import { Constvar } from '../../../models/constVar';
import { DataService } from '../../../shared/services/data.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { Type } from '../../models';
import { AuthenticationService } from '../../../shared/services/authentication.service';

declare var $: any;
const BUCKET_NAME = 'wtafbucket2019';
const UPLOAD_FOLDER = '';

@Component({
  selector: 'wtaf-start-execution',
  templateUrl: './start-execution.component.html',
  styleUrls: ['./start-execution.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class StartExecutionComponent implements OnInit {
  //internet offline,online check
  online$: Observable<boolean>;
  name: string;
  languageId: Number = 0;
  active_step = 0;
  devicepoolCapId: any;
  S3: any;
  file: File;
  isArnTemplate: boolean = false;
  ByUpload: any = '';
  serverDevicepools: any;
  isfromExecution:boolean =false;
  scheduleFlag: boolean = false;
  jenkinsJobIdNum: number = 0;
  buildJobInfo: any;
  localJobIdNum: number = 0;
  serverDevices: any = [];
  serverDevices_df: any = [];
  devicePoolRules: any = [];
  Devices: any = [];
  //========duplicate jason data remove====

  objcapability_findUdid: any = [{ capabilityName: '' }];
  serverDevicesDuplicate: any = [];
  count: number = 0;
  counts: number = 0;
  start = false;
  Apptype = '';
  IsZipSuccess = '';
  //=======================================
  ddldevicepool = 0;
  selectedAppFileName = '';
  varplatformId = 0;
  widthfooter = '0%';
  steps = '';
  appArn = '';
  file_arn = '';
  appversion = '';
  SelectedDevicePool_arn = '';
  testPackageArn = '';
  listofUploads: any = [];
  regionList: any = [];
  capTempError = '';
  submitted = false;
  brandList: any = [];
  appDataByBrandandEnv:any=[];
  testSuiteList: any = [];
  testTempSuiteList: any = [];
  testCaseList: any = [];
  allCapabilityList: any = [];
  allCapabilityTemplate: any = [];
  updatedCapTempList;
  languageList: any = [];
  capabilityTemplate;
  capabilityName;
  capabilityTemplateValue;
  ListOfCapabilities: any = [];
  containers = [];
  deviceJson: any = [];
  devicePool: string;
  TestCase: any = [];
  deletedCapList: any = [];
  isEditable: boolean = false;
  capabilitytemplateForm: UntypedFormGroup;
  isconsoleOut: boolean = false;
  timeStart_execution;
  oldMobile_app_path = '';
  isWeb: string;
  nodeUpdate = '';
  allUploadedFiles: any = [];
  isnotSelectedCap: boolean = false;
  ValidSteps = [
    { step0: true, step1: false, step2: false, step3: false, step4: false }
  ];

  WebCapabilities = [
    {
      id: 101,
      name: 'Google Chrome',
      os: '10.5',
      formFactor: 'Browser',
      availability: 'AVAILABLE',
      platform: 'Windows',
      udid: '',
      selected: false
    },
    {
      id: 102,
      name: 'Mozilla Firefox',
      os: '10.588.25',
      formFactor: 'Browser',
      availability: 'AVAILABLE',
      platform: 'Windows',
      udid: '',
      selected: false
    },
    {
      id: 103,
      name: 'Internet Explorer',
      os: '10.5869',
      formFactor: 'Browser',
      availability: 'AVAILABLE',
      platform: 'Windows',
      udid: '',
      selected: false
    },
    {
      id: 104,
      name: 'Microsoft Edge',
      os: '11.58',
      formFactor: 'Browser',
      availability: 'AVAILABLE',
      platform: 'Windows',
      udid: '',
      selected: false
    }
  ];
  // ----------- Tree View
  s3url: string;
  TotalSelectedTestCases = 0;
  brandName = '';
  AppVersionName = 'Smoke';
  AppName = '';
  machineName = '';
  executionMode:any = 'BrowserStack';
  environment_type = 'STG';
  deviceType:any = 'BrowserStack';
  Iscalled: boolean = true;
  Sele_testSuiteText = '';
  Sele_testSuiteid = '';
  Sele_testCaseText = '';
  Sele_testCaseid = '';
  selectedTestSuiteName_step = '';
  selectedtSuiteStep_stepper = 0;
  selectedtSuiteId_stepper = 0;
  caseList_stepper: any = [];
  DsList_stepper: any = [];
  
  var_manual_templatename_Error = '';
  glob_regionId: any;
  glob_brandId: any;
  glob_parentId_: any;
  glob_childId_: any;

  glob_suiteId_: any;
  glob_testsuite: any;
  globCase_parentId_: any;
  globCase_childId_: any;
  // ---------- View data set-----------------

  activeIndexPaginition = 0;
  testSuiteId = 0;
  no_of_iteration_exist = 0;
  max_it_id = 0;
  isdsError = '';
  dataSetName = '';
  dtName = '';
  testSuitName = '';
  TestCaseName = '';
  No_of_col: any = [];
  collen = 0;
  Test_steps_iterations: any = [];
  testSteps: any = [];
  templates: any = [];
  Iterations_keys: any;
  jsonDS_Iterations = [];
  IsData: boolean = false;
  deletedItern_Details: any = [];
  Issteps = false;
  capabilityTemplatedetails: any = [];
  IsFromTestCase = false;
  isFromStartExe = false;
  VarDS_Iterations: any;
  testCaseId: number = 1;
  Message_ds_modal: String = '';
  msg_capTemplate: String = '';
  dup_capTemplate: string = '';
  cor_capTemplate: string = '';
  dataset_list: any = [];
  selectedData_set: any = [];
  allTemplateResp: any = [];
  Selected_dataSetName: string = '';
  deletedCapability: any = [];
  capTemplateIdValue: any = {};
  successMessage: String = '';
  errorMessage: String = '';
  capabilityError: string = '';
  inValid: Boolean = false;
  capTemplateList: any = [];
  filtered: any = [];
  selectedValueds = 0;
  selectedValueTemplate = 0;
  selectedCapTemplateId = 0;
  CaseJson: any = [];
  selectedDevices: any;
  addCapSuccess: string = '';
  jobNameFromGetLocalDevices: string = '';
  // --------End-- View data set-----------------

  // ----------------- Test Execution Table
  // appversion="";
  TestSuitList: any = [];
  df_templateName: string;
  TestCaseList: any = [];
  platformList: any = [];
  projectTypeId = 0;
  platformId = 0;
  projectTypeList: any = [];
  projectNameList: any = [];
  projectName = '';
  strAvailable: string;
  regionId: number = 0;
  brandId: number = 0;
  appId:Number=0;
  headspinAppArn = [];
  arn_SelectedDevicePool = '';
  githubCheckout = environment.githubCheckout; //'GitHub-Checkout-AWS';

  // ---------End-------- Test Execution Table

  // ------------ Add Test Execution
  txtjobName = '';
  jsonExecutiontbl: any = [];
  Errormsg_Add_Execution: '';
  IsjobName_error = '';
  
  Recentapps = 0;
  selectDevice_error = '';

  isDevicesLoading: boolean = false;
  isDevicesListLoading: boolean = false;
  isUpdateListOfUpload: boolean = true;
  isTempName_checked: string = '';
  capDevice: any = [];

  executionForm = this.formBuilder.group({
    deviceType: ['defaultChecked']
  });

  arrJenkinsSlave: any = [];
  selectedSlave = '';
  slaveOs = '';
  slaveWorkspace = '';
  interval: any;
  isFileUpload = false;
  jenkinsNode = 0;
  isValidCap = true;
  platform = 'Android';
  showPopup = false;
  isInValid = false;
  private stepper: Stepper;
  strTitle = 'Select Devices';
  isMobile: Boolean = true;
  browserList: any = [];
  projectTypeName = 'Mobile';
  select_browseer_temp_name: string;
  IsTemplateNameExist = false;
  isErrorExist = false;
  isPreviousArray: any = [];
  selectedDeviceNames: any = [];
  arrMandatoryCapabilities: any = [];
  historyId: number = 0;
  userId: number;
  deviceBrowserName: string;
  capabilityRes: any = [];
  exeHeaderId: number;
  exeHistoryId: number;
  isFromHistory: boolean = false;
  historyId_fromHistory: number;
  headerId_fromHistory: number;
  TemplateList_forReexe: any = [];
  UserTemplateName_forReexe: any = [];
  // prashant code
  isTestCaseSelected = false;
  updatedStepOrderNum: any;
  parameters: Array<any> = [];
  sequenceNum: any;
  sortedStepId: any;
  sortedSteps: any = [];
  isDraggable = false;
  isSort = false;
  dataOrder: any = [];
  stepOrderJson: [];
  reExeEnvDetails: any;
  scheduleDetails: any;
  isDPselect: boolean = false;
  mobileAppPath_Skipped = '';
  df_devices_template_list: any = [];
  selectedSuiteList = [];
  jsonCaselist: any;
  jsonDatasetList: any;
  df_nextToken: string;
  languageCode: string;
  progress: number = 0;
  localeCapabilityValue = 'US';
  allUploadedHeadspinFiles = [];
  selectedFileExe = '';
  selectedHeadspinDevices = [];
  selectBrowserStackDevices = [];
  bundleId = '7228ac95-a50a-4bc1-8396-48737953893f';
  appVersion = '';
  allFilesList = '';
  headspinDevicesOS = [];
  browserStackDevicesOS = [];
  hsAnyDeviceSelectedOS: string = '';
  isHSAnyDeviceSelected: boolean = false;
  isBSDeviceselected: boolean = false;
  headspinDevicesAppiumUrls: any;
  reExecution = false;
  isAppChange = false;
  appPackage = '';
  headSpinCapabilitytemplates: any;
  templateListData: any = [];
  capabilitiesByBrand: any = [];
  replacedTemplateList: any = [];
  jenkinJobsPath: any;
  defaultCapability =
    'deviceName, udid, platform, deviceType, mobileAppPath, appiumServerURL, osVersion';
  osVersion = '10';
  ishsIsTrue = false;
  consoleOut_datares: any= [];
  constructor(
    public toastr: ToastrService,
    private ref: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private formBuilder: UntypedFormBuilder,
    public dataservice: DataService,
    private router: Router,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public loaderService: LoaderService,
    private elementRef: ElementRef,
    public authService: AuthenticationService
  ) {}
  @ViewChild('createTemplateModal', { static: false })
  createTemplateModal: TemplateRef<any>;

  @ViewChild('appfile', { static: false }) appfile: ElementRef;

  async ngOnInit() {
     
    $('.modal-backdrop').remove();
    let tokens: any = localStorage.getItem('currentUser');
    console.log('token....', this.authService.authToken);

    this.userId = parseInt(localStorage.getItem('userId'));
    console.log('local usr id', this.userId);
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
    this.capabilitytemplateForm = this.formBuilder.group({
      templateName: ['', Validators.required],
      capabilityIdValuesViews: this.formBuilder.array([
        this.formBuilder.group({
          capabilityId: ['', Validators.required],
          capabilityValue: ['', Validators.required],
          capabilityName: ['']
        })
      ])
    });
    await this.getAllProjectType();
   // this.getJenkinsSlave();
     
    this.getAllCapabilitytemplate();
    // await this.getTestSuiteByPlatform('');
    await this.getAllLanguage();

    this.TestSuitList = [{ TestSuitName: '' }];
    this.TestCaseList = [{}];

    let param = {
      arn: environment.AwsConfig.ProjectArn
    };

    this.activatedRoute.params.forEach((params: Params) => {
      this.exeHeaderId = +params['executionId'];
      this.exeHistoryId = +params['executionId'];
      console.log('executionId --> ', this.exeHeaderId);
      console.log('exeHistoryId --> ', this.exeHistoryId);
    });
    this.headerId_fromHistory = parseInt(
      this.activatedRoute.snapshot.paramMap.get('executionId')
    );
    this.historyId_fromHistory = parseInt(
      this.activatedRoute.snapshot.paramMap.get('exeHistoryId')
    );

    console.log('executionId --> ', this.headerId_fromHistory);
    console.log('exeHistoryId --> ', this.historyId_fromHistory);

    if (
      this.headerId_fromHistory != null &&
      this.headerId_fromHistory != undefined &&
      !isNaN(this.headerId_fromHistory) &&
      this.historyId_fromHistory != null &&
      this.historyId_fromHistory != undefined &&
      !isNaN(this.historyId_fromHistory)
    ) {
      this.isFromHistory = true;
      this.ValidSteps[0]['step0'] = false;
      this.active_step = this.stepper['_currentIndex'] + 1;
      this.highlightRecentsteps(this.active_step);
      this.stepper.next();
      this.reExecution_withCustomChanges(
        this.headerId_fromHistory,
        this.historyId_fromHistory
      );
    } else {
      this.isFromHistory = false;
    }
    function fixWidthHelper(e, ui) {
      ui.children().each(function() {
        $(this).width($(this).width());
      });
      return ui;
    }
    let jQueryInstance = this;
    $('#tblTesttable').sortable({
      //   connectWith: 'table',
      items: 'tbody tr',
      //  items: 'tr',
      cursor: 'pointer',
      axis: 'y',
      opacity: 0.35,
      dropOnEmpty: true,
      helper: fixWidthHelper,
      containment: '#tblTesttable',

      start: function(e, ui) {
        jQueryInstance.isSort = true;
        console.log('UI----', ui);
        jQueryInstance.sortedSteps = [];
        jQueryInstance.dataOrder = [];
        $('.testcasetr').remove();
        ui.item.addClass('selected');
      },
      stop: function(e, ui) {
        jQueryInstance.isSort = true;
        ui.item.removeClass('selected');
        var listValues = [];
        $(this)
          .find('tr')
          .each(function(index) {
            if (index > 0) {
              var rowDetails = $(this)
                .find('td')
                .eq(0)
                .html('<span><i class="caret"></i></span>' + index);
              console.log('rowDetails----', rowDetails);
              jQueryInstance.updatedStepOrderNum = index;
              var sortstepId = $(this)
                .find('td')
                .eq(1)
                .html();
              console.log(sortstepId);
              jQueryInstance.sortedStepId = sortstepId;
              console.log('New sequence==', jQueryInstance.updatedStepOrderNum);
              console.log('New sequence==', jQueryInstance.sortedStepId);
              listValues.push(jQueryInstance.updatedStepOrderNum);
              jQueryInstance.sortedSteps.push(jQueryInstance.sortedStepId);

              jQueryInstance.dataOrder = listValues;
              console.log('steps', jQueryInstance.dataOrder);
              console.log('ID', jQueryInstance.sortedSteps);
              var sortedIDs = $(this).sortable('toArray');
              console.log('sortedIds----', sortedIDs);
            }
            var widget = $('.selector').sortable('widget');
          });
        jQueryInstance.submitReorders();
      }
    });
  }

  changeRegion(e) {
    $('#All').prop('checked', false);
    this.testSuiteList = [];
    this.testSuiteId = 0;
    this.jsonExecutiontbl = [];
    this.ListOfCapabilities = [];
    this.projectNameList = [];
    this.projectName = '';
    this.ValidSteps = [
      { step0: true, step1: true, step2: false, step3: false, step4: false }
    ];

    this.regionId = parseInt(e.target.value);

    this.getBrandsByRegionId_(this.regionId);
  }
  changeBrand(e) {
    this.testSuiteList = [];
    this.testSuiteId = 0;
    this.ListOfCapabilities = [];
    this.brandName = e.target.options[e.target.options.selectedIndex].text;
    this.brandId = parseInt(e.target.value);
    this.getAllProjectNameByTypeId();
  }
  //new code by mustaid
  async changeProjectType(event) {
    this.brandId = 0;
    this.brandList = [];
    this.testSuiteList = [];
    this.projectNameList = [];
    this.projectName = '';
    this.ListOfCapabilities = [];
    this.ValidSteps = [
      { step0: true, step1: true, step2: false, step3: false, step4: false }
    ];
    $('#All').prop('checked', false);
    this.jsonExecutiontbl = [];
    this.projectTypeId = parseInt(event.target.value);

    this.projectTypeName =
      event.target.options[event.target.options.selectedIndex].text;
     
    if (this.projectTypeName == 'API' && this.deviceType == 'DeviceFarm') {
      this.toastr.error(
        'You cannot select API project type for Device Farm'
      );
    }

    if (this.projectTypeName == 'Mobile') {
      this.strAvailable =
        this.deviceType == Constvar.Headspin ||
        this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
          ? 'Select upto 3 devices below OR'
          : 'Select Devices';
      // this.strTitle = 'Select Devices';
      this.deviceBrowserName = 'Device Name';
      // this.strAvailable = 'Available Devices';
      this.isMobile = true;
    } else if (this.projectTypeName == 'Web') {
      this.strTitle = 'Select Browsers';
      this.deviceBrowserName = 'Browser Name';
      this.strAvailable = 'Available Browsers';
      this.isMobile = false;
    }

    if (this.projectTypeId != 0) {
      await this.getAllRegion_byProjectTypeId(this.projectTypeId);
      await this.getPlatformByProjectTypeId(this.projectTypeId);
    }
  }

  async getAllRegion_byProjectTypeId(typeId) {
    await this.dataservice
      .getAllRegionByProjectType(typeId)
      .toPromise()
      .then(async (response: {}) => {
        this.regionList = response;
        //to place NAR region at first position in drop down
        this.regionList.unshift(
          this.regionList.splice(
            this.regionList.findIndex(item => item.regionName === 'NAR'),
            1
          )[0]
        );
        console.log(this.regionList);
        if (response != null) {
          if (this.regionList.length == 1) {
            this.regionId = this.regionList[0].regionId;
          } else {
            this.regionId = 0;
          }

          if (this.regionId > 0) {
            this.getBrandsByRegionId_(this.regionId);
          }
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  changeProjectName(e) {
    this.testSuiteList = [];
    this.projectName = e.target.value;

    if (this.platformId > 0) {
      this.getTestSuiteNameByPlatformId(this.platformId);
      this.getTestSuiteByPlatform(this.platformId);
    }
  }

  async getBrandsByRegionId_(regionId) {
    await this.dataservice
      .getBrandsByRegionId(regionId)
      .toPromise()
      .then((response: {}) => {
        this.brandList = response;
        if (response != null) {
          if (this.brandList.length == 1) {
            this.brandId = this.brandList[0].brandId;
          } else {
            this.brandId = 0;
          }
        } else {
          this.brandId = 0;
        }
        if (this.brandId > 0) {
          this.getAllProjectNameByTypeId();
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  // --------Get All Project Types
  async getAllProjectType() {
    this.regionId = 0;
    this.brandId = 0;
    this.projectName = '';
    this.platformId = 0;
    await this.dataservice
      .getAllProjectType()
      .toPromise()
      .then(response => {
        // Success
        this.projectTypeList = response;
        if (response != null) {
          if (this.projectTypeList.length == 1) {
            this.projectTypeId = this.projectTypeList[0].projectTypeId;
            this.getAllRegion_byProjectTypeId(this.projectTypeId);
          } else {
            this.projectTypeId = 0;
          }
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  async getAllProjectTypeByBrandId(brandId) {
    await this.dataservice
      .getProjectTypeByBrandId(brandId)
      .toPromise()
      .then((response: {}) => {
        this.projectTypeList = response;
        console.log('response', response);
        if (response != null) {
          if (this.projectTypeList.length == 1) {
            this.projectTypeId = this.projectTypeList[0].projectTypeId;
            this.getPlatformByProjectTypeId(this.projectTypeId);
          } else {
            this.projectTypeId = 0;
          }
        } else {
          this.projectTypeId = 0;
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  async getAllProjectNameByTypeId() {
    await this.dataservice
      .getProjectNameByTypeId_exc(
        this.regionId,
        this.brandId,
        this.projectTypeId
      )
      .toPromise()
      .then((response: {}) => {
        this.projectNameList = response;
        console.log('response-project name list', response);
        if (response != null) {
          if (this.projectNameList.length == 1) {
            this.projectName = this.projectNameList[0].projectName;
          } else {
            this.projectName = '';
          }
        } else {
          this.projectName = '';
        }
        if (this.platformId != 0) {
          this.getTestSuiteNameByPlatformId(this.platformId);
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  async getPlatformByProjectTypeId(projectTypeId) {
    if (!this.isFromHistory) {
      await this.dataservice
        .getPlatformByProjectTypeId(projectTypeId)
        .subscribe(
          (response: {}) => {
            this.platformList = response;
            this.browserList = response;
            if (response != null) {
              if (this.platformList.length == 1) {
                this.platformId = this.platformList[0].platformId;
                this.varplatformId = this.platformList[0].platformId;
              } else {
                if (this.projectTypeName == 'Web') {
                  this.platformId = this.platformList[0].platformId;
                  this.varplatformId = this.platformList[0].platformId;
                } else {
                  this.platformId = 0;
                }
              }
            } else {
              this.platformId = 0;
            }
          },
          error => {
            console.log(error.message);
            this.toastr.error('Something went Wrong ');
          }
        );
    } else {
      this.platformId = this.reExeEnvDetails.platformId;
      this.varplatformId = this.reExeEnvDetails.platformId;
    }
  }

  async getTestSuiteNameByProjectName(projectName) {
    this.jsonExecutiontbl = [];
    let TestSuiteId = Type.TestSuiteId;
    let desc = Type.descending;
    await this.dataservice
      .getAllTestSuite(
        TestSuiteId,
        desc,
        this.regionId,
        this.brandId,
        this.projectTypeId,
        projectName,
        0,
        0
      )
      .toPromise()
      .then((response: {}) => {
        this.testSuiteList = response;
        this.testTempSuiteList = response;
        console.log('----------------->', this.testSuiteList);
        if (response != null) {
          this.testSuiteList.forEach(element => {
            element.IsSelected = false;
            let modifiedOn_LocalTime = moment.utc(element.modifiedOn).toDate();
            console.log('date time transform', modifiedOn_LocalTime);
            element.convert_modifiedTime_format = moment(
              modifiedOn_LocalTime
            ).format('YYYY-MM-DD HH:mm:ss');
            console.log(
              'modifiedOn_LocalTime..',
              element.convert_modifiedTime_format
            );
          });
          this.checkRecentSelctedTest_Suite(0);
          if (this.testSuiteList.length == 1) {
            this.testSuiteId = this.testSuiteList[0].testSuiteId;
          } else {
            this.testSuiteId = 0;
          }
        } else {
          this.testSuiteId = 0;
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  async getTestSuiteNameByPlatformId(platformId) {
    this.platformId = platformId;
    this.jsonExecutiontbl = [];
    this.testSuiteList = [];
    this.selectedSuiteList = [];
    let TestSuiteId = Type.TestSuiteId;
    let desc = Type.descending;
    this.selectedSuiteList = [];

    await this.dataservice
      .getAllTestSuiteByPlatformId(
        TestSuiteId,
        desc,
        this.regionId,
        this.brandId,
        0,
        this.projectName,
        this.platformId
      )
      .toPromise()
      .then((response: {}) => {
        this.testSuiteList = response;
        this.testTempSuiteList = response;
        console.log('----------------->', this.testSuiteList);
        if (response != null) {
          this.testSuiteList.forEach(element => {
            element.IsSelected = false;
            let modifiedOn_LocalTime = moment.utc(element.modifiedOn).toDate();
            console.log('date time transform', modifiedOn_LocalTime);
            element.convert_modifiedTime_format = moment(
              modifiedOn_LocalTime
            ).format('YYYY-MM-DD HH:mm:ss');
            console.log(
              'modifiedOn_LocalTime..',
              element.convert_modifiedTime_format
            );
          });
          this.checkRecentSelctedTest_Suite(0);
          if (this.testSuiteList.length == 1) {
            this.testSuiteId = this.testSuiteList[0].testSuiteId;
          } else {
            this.testSuiteId = 0;
          }
        } else {
          this.testSuiteId = 0;
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  get f() {
    return this.capabilitytemplateForm.controls;
  }
  sortsave() {
    console.log(this.jsonExecutiontbl);
  }

  GetEnv_type(e) {
    this.environment_type = e.target.value;
  }
  GetAppVersion(e) {
    this.AppVersionName = e.target.value.toString();
  }
  async getValue(e) {
    await this.getAllCapabilitytemplate();
    this.deviceType = this.isFromHistory ? this.deviceType : e.target.value;
    let selectedValue = this.deviceType;
    if (!this.isFromHistory) {
      if (
        selectedValue != null &&
        selectedValue != '' &&
        selectedValue != undefined
      ) {
        this.appArn = '';
        this.file = null;
      }
    }

    if (this.deviceType == Constvar.Headspin) {
      this.getAllHeadspinDeviceAppiumUrl();
    }
    this.isFromHistory
      ? this.SelectedDevicePool_arn
      : (this.SelectedDevicePool_arn = '');
    this.Recentapps = 0;
    this.jenkinsNode = 0;
    this.selectedSlave = '';
    this.isArnTemplate = false;
    this.dataservice.isDevicesLoading = false;
    this.ListOfCapabilities = [];
    // this.getcapabilityByProjectTypeId();
    this.executionMode = this.isFromHistory
      ? this.executionMode
      : e.target.options[e.target.options.selectedIndex].text;
    if (this.deviceType == 'DeviceFarm') {
      this.ListOfCapabilities = [];
      $('#templateid').prop('disabled', true);
      $('#btnAddSuiteTemplate').prop('disabled', true);
      $('#btnCreateSuiteTemplate').prop('disabled', true);
     this.listDevicePools();
      this.getUploadedApps(false);
    } else {
      this.isFromHistory ? this.AppName : this.AppName = '';
      this.ByUpload = '';
      $('#templateid').prop('disabled', false);
      $('#btnAddSuiteTemplate').prop('disabled', false);
      $('#btnCreateSuiteTemplate').prop('disabled', false);
    }
  }
  getAllHeadspinDeviceAppiumUrl() {
    this.dataservice
      .getAllHeadspinDevicesAppiumUrl()
      .toPromise()
      .then(response => {
        this.headspinDevicesAppiumUrls = Object.values(response);
      })
      .catch(_err => {
        this.toastr.error(this.dataservice.error_500_status);
      });
  }
  //Get all headspin Devices List
  getDeviceHeadspinDevicesList() {
    let headspinDeviceOS = [];
    this.headspinDevicesOS = [];
    // this.templateListData = [];
    if (this.executionMode == Constvar.Headspin) {
      if (
        this.appArn == '' ||
        this.appArn == undefined ||
        this.appArn == null
      ) {
        return this.toastr.error('Please upload / select app file');
      }
    }
    this.selectedHeadspinDevices = [];
    $('.checkBoxClass').prop('checked', false);
    $('.hsanycheck').attr('disabled', false);
    $('.hsanycheck').prop('checked', false);
    this.hsAnyDeviceSelectedOS = '';
     
    this.df_templateName = '';
    let deviceStatusList;
    this.dataservice.isDevicesLoading = true;
    this.dataservice
      .getAllHeadspinDevices()
      .subscribe((allDevicesList: any) => {
        this.serverDevices = allDevicesList;
        let platformName;

        if (this.isFromHistory) {
          platformName = this.platform;
        } else {
          let objPlatform = this.platformList.filter(
            pl => pl.platformId == this.platformId
          );
          platformName = objPlatform[0].platformName.toLowerCase();
        }

        if (
          (platformName != null && platformName != undefined) ||
          this.isFromHistory
        ) {
          this.serverDevices = this.serverDevices.filter(devicesList => {
            return platformName.toLowerCase() == devicesList.platform;
          });
          console.log('list of upload', this.serverDevices);
          this.serverDevices.map(element => {
            headspinDeviceOS.push(element.osVersion);
            if (this.headspinDevicesOS.indexOf(element.osVersion) === -1) {
              let versionNumber = +element.osVersion;
              if (!isNaN(versionNumber)) {
                let versionNumbers = versionNumber + '.0.0';
                this.headspinDevicesOS.push(parseFloat(versionNumbers));
              } else {
                this.headspinDevicesOS.push(parseFloat(element.osVersion));
              }
            }

          });
          this.selectedHeadspinDevices = [];
          this.isHSAnyDeviceSelected = false;
          this.dataservice.isDevicesLoading = false;
          this.headspinDevicesOS = [...new Set(this.headspinDevicesOS)];
          headspinDeviceOS = [...new Set(headspinDeviceOS)];
          this.headspinDevicesOS.sort(function(a, b) {
            return b - a;
          });
          this.headspinDevicesOS = this.headspinDevicesOS.map(vesrion => {
            let osver = vesrion.toString();
            let index = headspinDeviceOS.findIndex(os => os.includes(osver));
            vesrion = headspinDeviceOS[index];
            return vesrion;
          });
          $('#SelectDeviceTemplateModal').modal('show');
        }
      },
      error => {
        console.log(error.message);
      }
    );
  }

  getDeviceBrowserStackDevicesList() {
    let headspinDeviceOS = [];
    this.headspinDevicesOS = [];
    // this.templateListData = [];
    if (this.executionMode == Constvar.BrowserStack || this.executionMode=='LocalBrowserStack') {
      if (
        this.appArn == '' ||
        this.appArn == undefined ||
        this.appArn == null
      ) {
        return this.toastr.error('Please upload / select app file');
      }
    }
    this.selectedHeadspinDevices = [];
    $('.checkBoxClass').prop('checked', false);
    $('.hsanycheck').attr('disabled', false);
    $('.hsanycheck').prop('checked', false);
    this.hsAnyDeviceSelectedOS = '';

    this.df_templateName = '';
    let deviceStatusList;
    this.dataservice.isDevicesLoading = true;
    this.dataservice.getAllBrowserStackDevices(this.platform).subscribe(
      (allDevicesList: any) => {
        this.serverDevices = allDevicesList;
        let platformName;

        if (this.isFromHistory) {
          platformName = this.platform;
        } else {
          let objPlatform = this.platformList.filter(
            pl => pl.platformId == this.platformId
          );
          platformName = objPlatform[0].platformName.toLowerCase();
        }

        if (
          (platformName != null && platformName != undefined) ||
          this.isFromHistory
        ) {
          this.serverDevices = this.serverDevices.filter(devicesList => {
            return platformName.toLowerCase() == devicesList.os;
          });
          console.log('list of upload', this.serverDevices);
          this.serverDevices.map(element => {
            headspinDeviceOS.push(element.os_version);
            if (this.headspinDevicesOS.indexOf(element.os_version) === -1) {
              let versionNumber = +element.os_version;
              if (!isNaN(versionNumber)) {
                let versionNumbers = versionNumber + '.0.0';
                this.headspinDevicesOS.push(parseFloat(versionNumbers));
              } else {
                this.headspinDevicesOS.push(parseFloat(element.osVersion));
              }
            }
          });
          this.selectedHeadspinDevices = [];
          this.isHSAnyDeviceSelected = false;
          this.dataservice.isDevicesLoading = false;
          this.headspinDevicesOS = [...new Set(this.headspinDevicesOS)];
          headspinDeviceOS = [...new Set(headspinDeviceOS)];
          this.headspinDevicesOS.sort(function(a, b) {
            return b - a;
          });
          this.headspinDevicesOS = this.headspinDevicesOS.map(vesrion => {
            let osver = vesrion.toString();
            let index = headspinDeviceOS.findIndex(os => os.includes(osver));
            vesrion = headspinDeviceOS[index];
            return vesrion;
          });
          $('#SelectDeviceTemplateModal').modal('show');
        }
      },
      error => {
        console.log(error.message);
      }
    );
  }

  clearFiles() {
    !this.isUpdateListOfUpload ? this.listofUploads : (this.listofUploads = []);
    this.file = null;
    this.selectedAppFileName = '';
    this.AppName = '';
    if (this.appfile != undefined) {
      this.appfile.nativeElement.value = '';
    }
  }

  getDevices() {
    console.log(this.deviceType);
    this.serverDevices = [];
    this.dataservice.serverDevices = [];
    this.var_manual_templatename_Error = '';

    if (this.deviceType == 'DeviceFarm') {
      this.listDevicePools();
    } else if (this.deviceType == 'Local') {
      if (this.isMobile == false) {
        this.getBrowserList();
      } else {
        if (
          this.selectedSlave == '' ||
          this.selectedSlave == null ||
          this.selectedSlave == undefined
        ) {
          this.toastr.error('Please select node to get devices');
          return;
        }
        //check for slave value is null
        else if (
          this.slaveOs != null &&
          this.slaveOs != undefined &&
          this.slaveOs != ''
        ) {
           this.getlocalDeviceFromJenkinsJob(false);         
        } else {
          this.toastr.error('Slave OS is not active,please try again...');
          return;
        }
      }
    }
  }

  getBrowserList() {
    this.WebCapabilities = [
      {
        id: 101,
        name: 'Google Chrome',
        os: '10.5',
        formFactor: 'Browser',
        availability: 'AVAILABLE',
        platform: 'Windows',
        udid: '',
        selected: false
      },
      {
        id: 102,
        name: 'Mozilla Firefox',
        os: '10.588.25',
        formFactor: 'Browser',
        availability: 'AVAILABLE',
        platform: 'Windows',
        udid: '',
        selected: false
      },
      {
        id: 103,
        name: 'Internet Explorer',
        os: '10.5869',
        formFactor: 'Browser',
        availability: 'AVAILABLE',
        platform: 'Windows',
        udid: '',
        selected: false
      },
      {
        id: 104,
        name: 'Microsoft Edge',
        os: '11.58',
        formFactor: 'Browser',
        availability: 'AVAILABLE',
        platform: 'Windows',
        udid: '',
        selected: false
      }
    ];
    this.serverDevices = [];
    if (this.WebCapabilities != null) {
      this.serverDevices = this.WebCapabilities;
      setTimeout(() => {
        this.IsjobName_error = '';
        this.ref.detectChanges();
      }, 2500);
      $('#SelectDeviceTemplateModal').modal('show');
    }
  }

  getRecentapp_arn(event) {
     
    this.isUpdateListOfUpload = false;
    this.clearFiles();
    this.appId=event.target.value;
    console.log("appId:",this.appId);
    this.selectedAppFileName= event.target.options[event.target.options.selectedIndex].text;
    this.AppName =
      event.target.options[event.target.options.selectedIndex].text;
    this.mobileAppPath_Skipped =
      event.target.options[event.target.options.selectedIndex].text;
      this.appArn=this.appArn;
     let selectedAppData= this.appDataByBrandandEnv.filter(e=>{
          return e.appId == this.appId
          // {
          //   return e.appId
          // }
      })
      console.log('selectedAppData',selectedAppData);
    this.ByUpload = 'Using_Arn';
  }
  getRecentapp_id(e) {
     
    // this.getAllUploadedHeadspinFiles(false);
    this.appId = e.target.value;
    if (this.executionMode == Constvar.Headspin) {
      this.dataservice.getHeadSpinAppArn(this.appId).subscribe(
        (getAppArn: any) => {
          console.log('headspin app Arn by appId:', getAppArn);
          this.headspinAppArn = getAppArn;
          this.appArn =
            this.platform == Constvar.Android
              ? getAppArn.apkId
              : getAppArn.ipaId;
          this.AppName = e.target.options[e.target.options.selectedIndex].text;
          if (this.platform == Constvar.Android) {
            this.bundleId = this.appArn;
            this.getappPackageAfterUploadApp(this.appArn);
          } else {
            this.getBundleIdAfterUploadApp(this.appArn);
          }
        },
        error => {
          console.log(error.message);
        }
      );
    } else if (this.executionMode == Constvar.BrowserStack || this.executionMode=='LocalBrowserStack') {
      this.dataservice
        .uploadFileForBrowserStack(this.appId)
        .toPromise()
        .then(async (response: {}) => {
          console.log('BrowserStack app Arn by appId:', response);
          this.AppName = e.target.options[e.target.options.selectedIndex].text;
          this.appArn = response['appId'];
        })
        .catch(err => {
          console.log(err);
          this.toastr.error('Something went wrong');
        });
    }
  }

  getappPackageAfterUploadApp(appArn) {
     
    let selectApp = this.allUploadedHeadspinFiles.filter(app => {
      return app.appId == appArn;
    });
   // this.AppName = selectApp[0].fileName;
    //this.appPackage = selectApp[0].app_package;
    //console.log('app package', this.appPackage);
  }

  getBundleIdAfterUploadApp(appArn) {
     
    let selectApp = this.allUploadedHeadspinFiles.filter(app => {
      return app.appId == appArn;
    });
    this.appArn = appArn;
    this.bundleId = this.headspinAppArn['bundleIdentifier'];
    this.appVersion = this.headspinAppArn['bundleShortVersionString'];
   //this.AppName = selectApp[0].hsTag;
  }

  async getlocalDeviceFromJenkinsJob(iFromSelectDevice: boolean) {
    let get_Data_frm_jobName;
    this.dataservice.serverDevices = [];
    this.serverDevices = [];
     
    if (this.active_step == 4) {
      this.dataservice.isconsoleOut = true;
    }

    if (!this.isFromHistory) {
      let objPlatform = this.platformList.filter(
        pl => pl.platformId == this.platformId
      );
      this.platform = objPlatform[0].platformName;
    } else {
      this.platform = this.reExeEnvDetails.platformName;
    }
    if (
      this.selectedSlave == '' ||
      this.selectedSlave == null ||
      this.selectedSlave == undefined
    ) {
      this.toastr.error('Please select node to get devices');
      this.dataservice.isDevicesLoading = false;
      $('#btnSelectDevices').prop('disabled', false);
      $('#startExeBtnView').prop('disabled', false);
      this.ref.detectChanges();
      await new Promise<void>(resolve => setTimeout(() => resolve(), 2500))
        .then(() => {
          this.IsjobName_error = '';
          this.ref.detectChanges();
        })
        .catch(err => {
          console.log(err);
        });
      return;
    }
    $('#btnSelectDevices').prop('disabled', true);
    $('#startExeBtnView').prop('disabled', true);
    this.dataservice.isDevicesLoading = true;
    console.log('iFromSelectDevice....', iFromSelectDevice);

    if (!iFromSelectDevice) {
      let doc = this;
      this.jobNameFromGetLocalDevices = '';
      // this.jobNameFromGetLocalDevices = await this.dataservice.getLocalDevices(
      //   this.slaveOs,
      //   this.selectedSlave,
      //   this.platform,
      //   true
      // );
      await doc.sleep(5000);
    await  this.dataservice.startDeviceNodeJob(this.selectedSlave,this.platform).subscribe(res=>{
        console.log('get devices list',res);
        new Promise<void>(resolve => setTimeout(() => resolve(), 6000))
        .then(async () => {
       await this.dataservice.getBuildNumberForDeviceList(this.selectedSlave,this.platform).subscribe((buildNumRes:any)=>{
          console.log('get devices list',buildNumRes.buildNumber);
          this.consoleOutPut(buildNumRes.buildNumber,this.platform);
        
        },
        error =>{
          console.log(error);
          
        })
      })
      },
      error =>{
        console.log(error);
        
      })
    }

    console.log(
      '.jobNameFromGetLocalDevices.......',
      this.jobNameFromGetLocalDevices
    );

  }

  consoleOutPut(buildNumber,platform)
  {
    this.dataservice.getConsoleText(buildNumber,platform).
    subscribe((res:any)=>{
       
      console.log('console res',res);
      if (res.result == 'IN PROGRESS')
      {
         
       this.consoleOutPut(buildNumber,platform);
      }
      else if(res.result == 'FAILURE')
      {
        $('#popProgress').modal('hide');
        this.isDevicesLoading = false;
        this.dataservice.isDevicesLoading=false;
        clearInterval(this.interval);
        this.toastr.info('Something went wrong, please try again.', 'Error');
        $('#btnSelectDevices').prop('disabled', false);
        return;
      }
      else if(res.result == 'SUCCESS')
      {
        this.consoleOutData(res,this,'getDevices')
      }
    },
    error=>{

    })
  }
  async consoleOutData(data, doc, jobType) {
    doc = this;

    console.log('data.body----------------------', data.ConsoleText);

    // if (data.body.indexOf('Finished: SUCCESS') > -1) {
      //clear selected device list
     this.serverDevices = [];
      clearInterval(this.interval);
      if (jobType == 'getDevices') {
        if (
          doc.platform == 'Android' &&
          data.ConsoleText.indexOf('[ro.boot.serialno]') == -1
        ) {
          clearInterval(this.interval);
          this.dataservice.isDevicesLoading = false;
          $('#btnSelectDevices').prop('disabled', false);
          $('#startExeBtnView').prop('disabled', false);

          this.toastr.error(
            'Failed to load devices. Please connect devices.'
          );

          setTimeout(() => {
            this.IsjobName_error = '';
          }, 2500);
          return;
        } else {
          //  Akash
          if (
            this.slaveOs != null &&
            this.slaveOs != undefined &&
            this.slaveOs != ''
          ) {
            if (this.slaveOs.toString().indexOf('Windows') > -1) {
              //  For Android
              this.serverDevices = await this.dataservice.parseAndroidFile(
                data,
                this,
                this.active_step
              );
              console.log('this.serverDevices', this.serverDevices);
              if (this.active_step == 4) {
                this.timeStart_execution = await new Promise<void>(resolve =>
                  setTimeout(() => resolve(), 1000)
                )
                  .then(async () => {
                    if (this.dataservice.isconsoleOut == false) {
                      clearInterval(this.timeStart_execution);
                      await this.check_data_consoleOutFuc(this.scheduleDetails);
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    this.toastr.error('Something went wrong');
                  });
              }
              if (this.serverDevices != null && this.serverDevices != undefined) {
                setTimeout(() => {
                  $('#btnSelectDevices').prop('disabled', false);
                  $('#startExeBtnView').prop('disabled', false);
                  this.isDevicesLoading = false;
                }, 5000);
              }
            } else {
              if (this.platform == 'Android') {
                this.serverDevices = await this.dataservice.parseAndroidFile(
                  data,
                  this,
                  this.active_step
                );

                if (this.active_step == 4) {
                  this.timeStart_execution = await new Promise<void>(resolve =>
                    setTimeout(() => resolve(), 1000)
                  )
                    .then(async () => {
                      if (this.dataservice.isconsoleOut == false) {
                        clearInterval(this.timeStart_execution);
                        await this.check_data_consoleOutFuc(
                          this.scheduleDetails
                        );
                      }
                    })
                    .catch(err => {
                      console.log(err);
                      this.toastr.error('Something went wrong');
                    });
                }
              } else {
                // For IOS

                this.serverDevices = await this.dataservice.parseIosFile(
                  data,
                  this,
                  this.active_step
                );
                console.log(
                  'data service serverDevices',
                  this.dataservice.serverDevices.length
                );

                if (this.active_step == 4) {
                  this.timeStart_execution = await new Promise<void>(resolve =>
                    setTimeout(() => resolve(), 1000)
                  )
                    .then(async () => {
                      if (this.dataservice.isconsoleOut == false) {
                        clearInterval(this.timeStart_execution);
                        await this.check_data_consoleOutFuc(
                          this.scheduleDetails
                        );
                      }
                    })
                    .catch(err => {
                      console.log(err);
                      this.toastr.error('Something went wrong');
                    });
                }
              }
            }
          } else {
            this.toastr.error('Something went wrong.Please try again.');
          }
        }
      } else if (jobType == 'checkOut') {
        $('#popProgress').modal('hide');
        this.toastr.info('Framework zip has been generated.', 'Success');
        return;
      }
  }

  async listDevicePools() {
    $('#btnViewDevices').prop('disabled', true);
    $('#startExeBtnView').prop('disabled', true);

    this.dataservice.isDevicesLoading = true;
   this.dataservice.listDevicePools().subscribe((data) => {
           this.serverDevicepools = data;
          console.log('device farm data', data);
           console.log('device list-----', this.serverDevicepools);
         $('#btnViewDevices').prop('disabled', false);
         $('#startExeBtnView').prop('disabled', false);
         this.dataservice.isDevicesLoading = false;
     },
     error => {
      $('#btnViewDevices').prop('disabled', false);
      $('#startExeBtnView').prop('disabled', false);
       this.dataservice.isDevicesLoading = false;
      console.log(error.message);
     });
  }


  async CheckISCapbilityTemplateExist(devicepool): Promise<Boolean> {
    let ISExist = false;
    let CapabilityDetails = {
      resource: devicepool,
      type: Type.CapabilityTemplate,
      optionalId:
        this.platformList != null || this.platformList.length > 0
          ? this.platformId
          : 0,
      id: 0
    };
    await this.dataservice
      .checkDuplicationwithOptionalId_fmicro(CapabilityDetails)
      .toPromise()
      .then(async response => {
        ISExist = Boolean(response);
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
    return ISExist;
  }

  getAWSDevForCapability_Creation(e) {
    this.ListOfCapabilities = [];
    this.SelectedDevicePool_arn = e.target.value;
    console.log("this.SelectedDevicePool_arn:",this.SelectedDevicePool_arn);
    this.devicePool = e.target.options[e.target.selectedIndex].text;
    this.serverDevices = [];
    let var_arn = this.SelectedDevicePool_arn;
    this.arn_SelectedDevicePool = var_arn;

 }

  async getServerDevices() {
    if (this.projectTypeName == 'Mobile') {
      this.strAvailable =
        this.deviceType == Constvar.Headspin ||
        this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
          ? 'Select upto 3 devices below OR'
          : 'Select Devices';
      // this.strTitle = 'Select Devices';
      this.deviceBrowserName = 'Device Name';
      // this.strAvailable = 'Available Devices';
      this.isMobile = true;
    } else if (this.projectTypeName == 'Web') {
      this.strTitle = 'Select Browsers';
      this.deviceBrowserName = 'Browser Name';
      this.strAvailable = 'Available Browsers';
      this.isMobile = false;
    }
    this.dataservice.error_500_status = '';
    this.df_templateName = '';
    this.var_manual_templatename_Error = '';
    $('#btnViewDevices').prop('disabled', true);
    $('#startExeBtnView').prop('disabled', true);
    this.dataservice.isDevicesLoading = true;
    this.serverDevices = [];
    
    let var_arn = this.SelectedDevicePool_arn;
    if (var_arn != '') {
      await new Promise(async (resolve, reject) => {
         this.dataservice.getDevicePool(this.SelectedDevicePool_arn).subscribe((devicepool_data) => {
         
             this.devicePoolRules = devicepool_data;
            let ResourceType = '';
             let FilterKeys = [];
             this.devicePoolRules['rules'].forEach(function(element) {
                
               if (element.attribute === 'ARN') {
                 ResourceType = 'ARN';
                 element['values'] = element.value;
                } 
              else {
                let ele_value = element.value.substring(
                  1,
                  element.value.length - 1
                );
                element['values'] = [ele_value];
                FilterKeys.push({
                  key: element.attribute,
                  op: element.operator,
                  val: ele_value
                });
              }

              delete element.value;
            });
            //--selectAll checckbox disabled--Akash
            $('#selectall').prop('disabled', false);

            if (ResourceType != 'ARN') {
              var params_devicelist = {
                filters: this.devicePoolRules
              };
            } else {
               
              let devicePoolRules2=this.devicePoolRules['rules'];
              let arnStr = devicePoolRules2[0].values.replace('[','');
              arnStr = arnStr.replace(']', '');
              var arnlist = arnStr.split(',');
                for (let l = 0; l < arnlist.length; l++) {
                   let var_arn = arnlist[l].substring(1, arnlist[l].length - 1);
                var arn_params = [
                        var_arn
                ];
                 
                    // new Promise(async (resolve, reject) => {
                    this.dataservice.getDevice(arn_params).toPromise().then((data:any) => {
                    console.log("getDevice",data);
                     
                            this.serverDevices=data;
                          console.log('this.serverDevices', this.serverDevices);
                  })
                  .catch(err => {
                    $('#btnViewDevices').prop('disabled', false);
                    $('#startExeBtnView').prop('disabled', false);
                    this.dataservice.isDevicesLoading = false;
                    $('#btnViewDevices').prop('disabled', false);
                      this.isDevicesListLoading = false;
                    console.log(err);
                  });
                  // });
                if (l == arnlist.length - 1) {
                  setTimeout(() => {
                    $('#btnViewDevices').prop('disabled', false);
                    $('#startExeBtnView').prop('disabled', false);

                    this.dataservice.isDevicesLoading = false;
                    $('#SelectDeviceTemplateModal').modal('show');
                  }, 2500);
                }
                }
            
           }

            let index = 1;
            this.serverDevices.forEach(function(element) {
              element.selected = false;
              element.id = index;
              index = index + 1;
            });
        
          // }
        },
        error => {
          $('#btnViewDevices').prop('disabled', false);
            this.isDevicesListLoading = false;
          console.log(error.message);
        }
      );
      });
    } else {
      $('#btnViewDevices').prop('disabled', false);
      $('#startExeBtnView').prop('disabled', false);
      this.dataservice.isDevicesLoading = false;
      this.clear_TestExecutionMsg('Select device pool');
      this.toastr.info('Select device pool');
    }
  }

  selectDevices(event, index) {
    if (event.target.checked) {
      this.Devices = index;
      this.selectedHeadspinDevices.push({
        deviceName: this.Devices.device,
        osVersion: this.Devices.os_version
      });
    } 
  }


  async listoffileuploads(data, refreshAppfile) {
    let status = Constvar.SucceedStatus;
    await this.sortPushFilesName(data, refreshAppfile);
  }

  // Filter files as per Platfrom and Push into Array list
  async sortPushFilesName(data, refreshAppfile) {
     
    for (let k = 0; k < data.length; k++) {
      this.platform.toUpperCase() == Constvar.Android.toUpperCase()
      if (data[k].platformName.toUpperCase() == Constvar.Android.toUpperCase() || 
      data[k].platformName.toUpperCase() == Constvar.Ios.toUpperCase()) {
        let createdOn = this.datepipe.transform(
          data[k].createdOn,
          'yyyy-MM-dd HH:mm:ss'
        );
        this.allUploadedFiles.push({
          arn: data[k].deviceFarmAppArn,
          appId:data[k].appId,
          createdOn: createdOn,
          name: data[k].fileName,
         // type: data.uploads[k].type
        });
      }
    }
    this.allUploadedFiles.sort(function(uploadedFile, uploadedFileToCompare) {
      return (
        new Date(uploadedFileToCompare.createdOn).valueOf() -
        new Date(uploadedFile.createdOn).valueOf()
      );
    });

    let sortedListOfUploads = [];
    //To sort duplicate data
  
    const map = new Map();
    for (const item of this.allUploadedFiles) {
      
      if (!map.has(item.appId)) {
        map.set(item.appId, true); // set any value to Map

        sortedListOfUploads.push({
          arn: item.arn,
          appId:item.appId,
          createdOn: item.createdOn,
          name: item.name,
          type: item.type
        });
      }
    
    }
    this.allUploadedFiles = sortedListOfUploads;
    console.log('list of uploads after sort', this.allUploadedFiles);

   // Is from refresh button
    // if (refreshAppfile || this.isFromHistory) {
    //   await this.filterFilesOnPlatformId();
    // }
  }

  filterFilesOnPlatformId() {
     
    this.listofUploads = this.allUploadedFiles.filter(uploadedFiles => {
      return this.platformId == 1
      ? uploadedFiles.type == Constvar.AndroidApptype
      : uploadedFiles.type == Constvar.IosApptype;
  });
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  async scheduleRun(HistoryID) {
     
    await this.dataservice.updatePackageArn(HistoryID, this.file_arn).subscribe(
      res => {
        console.log(res);
      },
      error => {
        console.log(error);
      }
    );

    if (
      this.localeCapabilityValue == undefined ||
      this.localeCapabilityValue == null
    ) {
      this.localeCapabilityValue = Constvar.localeUS;
    }

    // var devicefarm = new AWS.DeviceFarm();
    if (!this.scheduleFlag) {
      let param = {
        appArn: this.appArn,
        configuration: {
          locale: this.languageCode + '_' + this.localeCapabilityValue
        },
        executionConfiguration: {
          // jobTimeoutMinutes: 30,
          videoCapture: true
        },
        name: this.txtjobName,
        devicePoolArn: this.SelectedDevicePool_arn,
        projectArn: environment.AwsConfig.ProjectArn,
        test: {
          type: 'APPIUM_JAVA_TESTNG',
          testPackageArn: this.file_arn,
          testSpecArn:
            // 'arn:aws:devicefarm:us-west-2:493213171340:upload:ef187498-937e-4d02-8977-6df957f19849/36a1d311-3610-45b2-aa56-7879072a3bdf'
            this.platform == 'Android'
              ? environment.AwsConfig.testSpecArnAndroid
              : this.platform == 'IOS'
              ? environment.AwsConfig.testSpecArnIOS
              : ''
        }
      };
      this.steps = 'Scheduling Test [Step: 3 / 3]';
      console.log('this.appArn', this.appArn);
      console.log('testPackageArn', this.file_arn);
      console.log('Run Test params', param);

      console.log('schedule run params', param);

    } else {
      let scheduleObj = {
        appArn: this.appArn,
        devicePoolArn: this.SelectedDevicePool_arn,
        testpackageArn: this.file_arn,
        testSpecArn:
          this.platform == 'Android'
            ? environment.AwsConfig.testSpecArnAndroid
            : this.platform == 'IOS'
            ? environment.AwsConfig.testSpecArnIOS
            : '',
        locale: this.languageCode + '_' + this.localeCapabilityValue,
        jobName: this.txtjobName
      };

      this.dataservice
        .scheduleRun(scheduleObj)
        .toPromise()
        .then(res => {
          console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
      this.toastr.info(
        'Schedule has been successfully created.......',
        'Success'
      );
      $('#popProgress').modal('hide');
      $('.modal-backdrop').remove();
      this.router.navigate(['/execution/scheduled']);
    }
  }
  // ============highlight_Tree view elements===============
  highLightFolder() {
    $(function() {
      $('#highlight ul li li').delegate('li', 'click', function() {
        $('.highlight_li').removeClass('highlight_li');
        $('.ico').removeClass('ico');
        $(this)
          .addClass('highlight_li')
          .siblings()
          .removeClass('highlight_li');
        $(this)
          .addClass('highlight_li')
          .children('i')
          .addClass('ico');
      });
    });
  }

  get capabilityIdValuesViews() {
    
    return this.capabilitytemplateForm.get(
      'capabilityIdValuesViews'
    ) as UntypedFormArray;
  }

  createTemplatesModal() {
    this.clear();
    this.capabilitytemplateForm = this.formBuilder.group({
      templateName: ['', Validators.required],
      capabilityIdValuesViews: this.formBuilder.array([
        this.formBuilder.group({
          capabilityId: ['', Validators.required],
          capabilityValue: ['', Validators.required],
          capabilityName: ['']
        })
      ])
    });
    // this.getcapabilityByProjectTypeId();
    $('#createTemplateModal').modal('show');
  }

  addcapabilityRow() {
     
    if (this.isEditable != true) {
      this.capabilityIdValuesViews.push(
        this.formBuilder.group({
          capabilityId: ['', Validators.required],
          capabilityValue: ['', Validators.required],
          capabilityName: ['']
        })
      );
    } else {
      this.capabilityIdValuesViews.push(
        this.formBuilder.group({
          capabilityId: ['', Validators.required],
          capabilityValue: ['', Validators.required],
          capabilityName: [''],
          capTemplateId: 0,
          platformId: this.platformId
          // brandId:this.brandId
        })
      );
    }
  }

  //delete
  deleteCapabilityRow(index) {
    this.capabilityError = '';
    this.deletedCapability = this.capabilityIdValuesViews.value[index];

    let ds = this.deletedCapability.capabilityId;
    this.filtered = this.capabilityIdValuesViews.value.filter(item => {
      return item.capabilityId == ds;
    });
    if (this.filtered.length > 0) {
      this.deletedCapList.push(this.deletedCapability);
    }
    this.capabilityIdValuesViews.removeAt(index);
    delete this.capTemplateList[index];
  }

  getAllRegion() {
    this.testCaseList = [];
    this.dataservice.getAllRegion().subscribe(
      (response: {}) => {
        this.regionList = response;
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }

  /* get all brands by region Id */
  getBrandsByRegionId(region, regionId, regionCode, ele_Id) {
    this.testCaseList = [];
    this.Iscalled = false;
    this.dataservice.getBrandsByRegionId(regionId).subscribe(
      (response: {}) => {
        console.log('brands', this.brandList);
        region.brandList = response;
        this.brandList = response;
        this.createBrandTreeView(region, regionId, regionCode);
        this.Expand_Collape_Tree(ele_Id, regionCode);
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }

  /* get all test suite  */
  getAllTestSuite(regionId, brandId) {
    //
    this.testCaseList = [];
    let TestSuiteId = Type.TestSuiteId;
    let desc = Type.descending;
    this.dataservice
      .getAllTestSuite(TestSuiteId, desc, regionId, brandId, 0, '', 0, 0)
      .subscribe(
        (response: {}) => {
          //
          this.testSuiteList = response;
        },
        error => {
          console.log(error.message);
          this.toastr.error('Something went Wrong ');
        }
      );
  }

  /* get all test case  */
  async getAllTestCase(region, regionId, brandId, testSuiteId) {
    let testCaseId = Type.TestCaseId;
    let desc = Type.descending;
    await this.dataservice
      .getAllSharedTestCasebyProjects(
        testCaseId,
        desc,
        regionId,
        brandId,
        this.projectTypeId,
        this.projectName,
        false,
        testCaseId,
        0,
        0
      )
      .toPromise()
      .then(async (response: {}) => {
        this.testCaseList = response;
        if (response != null) {
          this.testCaseList.forEach(element => {
            element.IsSelected = false;
          });
        }
        console.log('this.testCaseList', this.testCaseList);
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  async getAllTestCase_ByProjects(suiteId) {
    if (!this.isFromHistory) {
      let testCaseId = Type.TestCaseId;
      let desc = Type.descending;
      await this.dataservice
        .getAllSharedTestCasebyProjects(
          testCaseId,
          desc,
          this.regionId,
          this.brandId,
          this.projectTypeId,
          this.projectName,
          false,
          suiteId,
          0,
          0
        )
        .toPromise()
        .then(async (response: {}) => {
          this.testCaseList = response;
          if (response != null) {
            this.testCaseList.forEach(element => {
              element.IsSelected = false;
            });
          }
          console.log('this.testCaseList', this.testCaseList);
        })
        .catch(err => {
          console.log(err);
          this.toastr.error('Something went wrong');
        });
    } else {
      let rowdata = this.testSuiteList.filter(testcase => {
        if (testcase.testSuiteId === suiteId) {
          return testcase;
        }
      });
      this.testCaseList = rowdata[0].testCases;
      let temp_testCase_list = this.testCaseList;
      const testCases_result = [];
      const map = new Map();
      for (const item of temp_testCase_list) {
        if (!map.has(item.testCaseId)) {
          map.set(item.testCaseId, true); // set any value to Map
          testCases_result.push({
            brandId: item.brandId,
            modifiedBy: item.modifiedBy,
            modifiedOn: item.modifiedOn,
            projectName: item.projectName,
            projectTypeId: item.projectTypeId,
            regionId: item.regionId,
            testCaseDesc: item.testCaseDesc,
            testCaseId: item.testCaseId,
            testCaseName: item.testCaseName,
            testCaseNumber: item.testCaseNumber,
            testSuiteId: item.testSuiteId,
            testSuiteName: item.testSuiteName,
            total_Number_Of_TestSteps: item.total_Number_Of_TestSteps,
            userId: item.userId
          });
        }
      }
      console.log('testCases_result-------ffffffffff', testCases_result);
      this.testCaseList = testCases_result;
      if (rowdata != null) {
        this.testCaseList.forEach(element => {
          element.IsSelected = false;
        });
      }
      console.log(this.testCaseList);
    }
  }

  //get capability by project type Id
  async getcapabilityByProjectTypeId() {
    let platformId = this.isFromHistory
      ? this.reExeEnvDetails.platformId
      : this.platformId;
    this.allCapabilityList = [];
    await this.dataservice
      .getCapabilitiesByProjectTypeId(platformId)
      .toPromise()
      .then(async (response: any) => {
        this.allCapabilityList = response;
        // if(this.deviceType == 'DeviceFarm')
        // {
        //   console.log('AWS capability');

        //   this.allCapabilityList= this.allCapabilityList.filter(
        //   o => {return o.capabilityName != 'mobileAppPath'}
        // );
        // }

        console.log('allcapabilities', this.allCapabilityList);
      })
      .catch(err => {
        this.loaderService.hide();
        console.log(err);
      });
  }

  async saveDevicePoolCapability(templateName, arnvalue, objDevice) {
    // this.loaderService.show();
    // this.isnotSelectedCap=true;
    this.isArnTemplate = false;
    let isDuplicate = false;
    this.devicepoolCapId = 0;
    let Base_df_Capabilities = [];
    let Push_temp_capabilities = [];
    // this.df_devices_template_list = [];
    // let Base_df_Capabilities = [];
    this.ListOfCapabilities = [];
    // let list_of_template_capability=[];
    let template_Name;
    let capabilityResponse;
    let list_of_template_capability = [];
    // await this.getcapabilityByProjectTypeId();
    if (this.executionMode != Constvar.BrowserStack || this.executionMode!='LocalBrowserStack') {
      Base_df_Capabilities = [
        { capabilityName: 'deviceName', capabilityValue: templateName },
        {
          capabilityName: 'udid',
          capabilityValue: this.isHSAnyDeviceSelected
            ? 'NA'
            : this.SelectedDevicePool_arn + '_' + this.devicePool
        },
        { capabilityName: 'platform', capabilityValue: this.platform },
        {
          capabilityName: 'osVersion',
          capabilityValue: this.osVersion
        },
        { capabilityName: 'deviceType', capabilityValue: 'Physical' },
        // { capabilityName: 'test_cap', capabilityValue: 'test_cap_value' },
        {
          capabilityName: 'mobileAppPath',
          capabilityValue:
            this.deviceType == Constvar.Headspin ||
            this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
              ? this.AppName
              : this.mobileAppPath_Skipped
        }
      ];
    } else {
      Base_df_Capabilities = [
        { capabilityName: 'deviceName', 
          capabilityValue: this.isHSAnyDeviceSelected
          ? templateName
          : this.Devices.device },
        {
          capabilityName: 'udid',
          capabilityValue: this.isHSAnyDeviceSelected
            ? 'NA'
            : this.Devices.device
        },
        { capabilityName: 'platform', capabilityValue: this.platform },
        {
          capabilityName: 'osVersion',
          capabilityValue: this.Devices.os_version
        },
        { capabilityName: 'deviceType', capabilityValue: 'Physical' },
        // { capabilityName: 'test_cap', capabilityValue: 'test_cap_value' },
        {
          capabilityName: 'mobileAppPath',
          capabilityValue: Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
            ? this.AppName
            : this.mobileAppPath_Skipped
        }
      ];
    }
    if (this.capabilitiesByBrand != null) {
      if (this.capabilitiesByBrand.length > 0) {
        this.capabilitiesByBrand.map(capability => {
          let capabilityObject = {
            capabilityName: capability.capabilityName,
            capabilityValue: capability.capabilityValue
          };
          Base_df_Capabilities.push(capabilityObject);
        });
      }
    }

    if (this.isHSAnyDeviceSelected && this.deviceType == Constvar.Headspin) {
      Base_df_Capabilities.push({
        capabilityName: 'appiumServerURL',
        capabilityValue: Constvar.anyDeviceAppiumUrl
      });
      if (this.platform == Constvar.Android) {
        Base_df_Capabilities.push({
          capabilityName: 'appId',
          capabilityValue: this.appArn
        });
      } else {
        Base_df_Capabilities.push(
          {
            capabilityName: 'appId',
            capabilityValue: this.appArn
          }
        );
      }
      template_Name = this.df_templateName;
      // this.df_devices_template_list.push({
      //   capabilityTemplateName: template_Name
      // });
      let Push_temp_capabilities = [];
      for (let j = 0; j < Base_df_Capabilities.length; j++) {
        let capabilityName = Base_df_Capabilities[j].capabilityName;
        let capabilityValue = Base_df_Capabilities[j].capabilityValue;
        let CapabilityDetails = {
          resource: capabilityName,
          type: Type.Capability,
          id: this.projectTypeId,
          optionalId:
            this.platformList != null || this.platformList.length > 0
              ? this.platformId
              : 0
        };
        // await this.dataservice
        //   .checkDuplicationwithOptionalId_framemicro(CapabilityDetails)
        //   .toPromise()
        //   .then(async response => {
        //     isDuplicate = Boolean(response);

        for (let i = 0; i <= this.allCapabilityList.length; i++) {
          isDuplicate = Boolean(
            this.allCapabilityList.find(p1 =>
              p1.capabilityName === CapabilityDetails.resource ? true : false
            )
          );
        }
        console.log('isDuplicate', isDuplicate);
        if (isDuplicate == true) {
          let IsTemplateExist = this.allCapabilityList.filter(
            o => o.capabilityName === capabilityName
          );
          console.log('IsTemplateExist', IsTemplateExist);

          if (IsTemplateExist.length > 0) {
            this.devicepoolCapId = IsTemplateExist[0].capabilityId;
            Push_temp_capabilities.push({
              capTemplateId: 0,
              capabilityId: this.devicepoolCapId,
              capabilityName: capabilityName,
              capabilityValue: capabilityValue,
              brandId: this.brandId,
              platformId: this.isFromHistory
                ? this.reExeEnvDetails.platformId
                : this.varplatformId
            });
          }
        } else {
          this.toastr.error(
            'Capability' +
              ' ' +
              CapabilityDetails.resource +
              ' ' +
              'is not present in Capability Master'
          );
          $('#popProgress').modal('hide');
          return;
        }
        // });
      }
      list_of_template_capability.push({
        capabilityIdValuesViews: Push_temp_capabilities,
        templateName: template_Name,
        userId: this.userId,
        usrTemplateName: this.df_templateName,
        hsAnyDevice: this.isHSAnyDeviceSelected ? true : false,
        templateType: this.deviceType,
        env: this.environment_type
      });

      console.log(
        'list_of_template_capability-----devices',
        list_of_template_capability
      );
      console.log('templateName', templateName);
      this.isFromStartExe = true;
      this.df_devices_template_list.push({
        capabilityTemplateName: template_Name
      });

      if (this.isFromHistory) {
        this.TemplateList_forReexe = this.df_devices_template_list;
        console.log(
          'this.TemplateList_forReexe====>',
          this.TemplateList_forReexe
        );
      }
      await this.addTemplateCapability_arn(templateName);
      // await this.addSelectedCapabilities();

      await this.dataservice
        .addDeviceFarmCapabilityTemplate(list_of_template_capability)
        .toPromise()
        .then(async response2 => {
          console.log('capability saved response', response2);
          if (response2 != null) {
            $('#popProgress').modal('hide');
            this.toastr.success(
              'Capability details saved successfully...'
            );
            // setTimeout(async () => {
            $('#SelectDeviceTemplateModal').modal('hide');
            this.showPopup = false;

            // await this.getAllCapabilitytemplate();
            await this.addTemplateCapability_arn(templateName);
            await this.checkMandatoryCapabilities('', false);
            // }, 1000);
          }
          // capabilityResponse = response2;
          // await this.getcapabilityByProjectTypeId();
          await this.getAllCapabilitytemplate();
        })
        .catch(err => {
          console.log(err);
          this.toastr.error('Something went wrong');
        });
    } else {
      if (this.serverDevices != null) {
        if (this.deviceType == Constvar.Headspin) {
          Base_df_Capabilities.push({
            capabilityName: 'appiumServerURL',
            capabilityValue: ''
          });
          if (this.platform == Constvar.Android) {
            Base_df_Capabilities.push({
              capabilityName: 'appId',
              capabilityValue: this.appArn
            });
          } else {
            Base_df_Capabilities.push(
              {
                capabilityName: 'appId',
                capabilityValue: this.appArn
              }
            );
          }
        }
        if (this.executionMode == Constvar.BrowserStack || this.executionMode=='LocalBrowserStack') {
          //for (let i = 0; i < this.Devices.length; i++) {
          let deviceName = this.Devices.device;
          let osVersion = this.Devices.os_version;
          let udid = this.Devices.device;
          if(this.isHSAnyDeviceSelected){
            udid = 'NA'
          }
          if (this.platform == Constvar.Android) {
            Base_df_Capabilities.push({
              capabilityName: 'appId',
              capabilityValue: this.appArn
            });
          } else {
            Base_df_Capabilities.push(
              {
                capabilityName: 'appId',
                capabilityValue: this.appArn
              }
            );
          }
          Base_df_Capabilities.push({
            capabilityName: 'appiumServerURL',
            capabilityValue: Constvar.bsAppiumUrl
          });

          if (this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack') {
            if(this.isHSAnyDeviceSelected){
              template_Name = this.df_templateName;
            }else{
              template_Name = deviceName + '_' + this.df_templateName;
            }
            
          } else {
            template_Name = this.df_templateName;
          }

          let Push_temp_capabilities = [];
          for (let j = 0; j < Base_df_Capabilities.length; j++) {
            let capabilityName = Base_df_Capabilities[j].capabilityName;
            let capabilityValue;
            if (capabilityName == 'osVersion') {
              capabilityValue = osVersion;
            } else if (capabilityName == 'udid') {
              capabilityValue = udid;
            } else {
              capabilityValue = Base_df_Capabilities[j].capabilityValue;
            }

            let CapabilityDetails = {
              resource: capabilityName,
              type: Type.Capability,
              id: this.projectTypeId,
              optionalId:
                this.platformList != null || this.platformList.length > 0
                  ? this.platformId
                  : 0
            };

            for (let i = 0; i <= this.allCapabilityList.length; i++) {
              isDuplicate = Boolean(
                this.allCapabilityList.find(p1 =>
                  p1.capabilityName === CapabilityDetails.resource
                    ? true
                    : false
                )
              );
              console.log('isDuplicate....1', isDuplicate);
            }
            console.log('isDuplicate...2', isDuplicate);

            if (isDuplicate == true) {
              let IsTemplateExist = this.allCapabilityList.filter(
                o => o.capabilityName === capabilityName
              );
              console.log('IsTemplateExist', IsTemplateExist);

              if (IsTemplateExist.length > 0) {
                this.devicepoolCapId = IsTemplateExist[0].capabilityId;
                this.capabilityName = IsTemplateExist[0].capabilityName;
                Push_temp_capabilities.push({
                  capTemplateId: 0,
                  capabilityId: this.devicepoolCapId,
                  capabilityName: this.capabilityName,
                  capabilityValue: capabilityValue,
                  brandId: this.brandId,
                  platformId: this.isFromHistory
                    ? this.reExeEnvDetails.platformId
                    : this.varplatformId
                });
              }
            } else {
              this.toastr.error(
                'Capability' +
                  ' ' +
                  CapabilityDetails.resource +
                  ' ' +
                  'is not present in Capability Master'
              );
              $('#popProgress').modal('hide');
              return;
            }
          }
          list_of_template_capability.push({
            capabilityIdValuesViews: Push_temp_capabilities,
            templateName: template_Name,
            userId: this.userId,
            usrTemplateName: this.df_templateName,
            hsAnyDevice: this.isHSAnyDeviceSelected ? true : false,
            templateType: this.deviceType,
            env: this.environment_type
          });

          console.log(
            'list_of_template_capability-----devices',
            list_of_template_capability
          );
          console.log('templateName', templateName);

          this.isFromStartExe = true;

          this.df_devices_template_list.push({
            capabilityTemplateName: template_Name
          });

          console.log(
            'this.df_devices_template_list',
            this.df_devices_template_list
          );

          if (this.isFromHistory) {
            this.TemplateList_forReexe = this.df_devices_template_list;
            console.log(
              'this.TemplateList_forReexe====>',
              this.TemplateList_forReexe
            );
          }
          await this.addTemplateCapability_arn(templateName);
          //}
        } else {
          for (let i = 0; i < this.serverDevices.length; i++) {
            let deviceName =
              this.deviceType == Constvar.Headspin
                ? this.serverDevices[i].deviceName
                : this.serverDevices[i].name;
            let osVersion =
              this.deviceType == Constvar.Headspin
                ? this.serverDevices[i].osVersion
                : this.serverDevices[i].os;
            let udid =
              this.deviceType == Constvar.Headspin
                ? this.serverDevices[i].deviceId
                : this.SelectedDevicePool_arn + '_' + this.devicePool;
            let AppiumUrl =
              this.deviceType == Constvar.Headspin
                ? this.serverDevices[i].AppiumUrl
                : '';
            if (this.deviceType == Constvar.Headspin) {
              template_Name = deviceName + '_' + this.df_templateName;
            } else {
              template_Name =
                deviceName + '_' + this.devicePool + '_' + this.df_templateName;
            }

            let Push_temp_capabilities = [];
            for (let j = 0; j < Base_df_Capabilities.length; j++) {
              let capabilityName = Base_df_Capabilities[j].capabilityName;
              let capabilityValue;
              if (capabilityName == 'osVersion') {
                capabilityValue = osVersion;
              } else if (capabilityName == 'udid') {
                capabilityValue = udid;
              } else if (capabilityName == 'appiumServerURL') {
                capabilityValue = AppiumUrl;
              } else {
                capabilityValue = Base_df_Capabilities[j].capabilityValue;
              }

              // let capabilityValue = Base_df_Capabilities[j].capabilityValue;
              let CapabilityDetails = {
                resource: capabilityName,
                type: Type.Capability,
                id: this.projectTypeId,
                optionalId:
                  this.platformList != null || this.platformList.length > 0
                    ? this.platformId
                    : 0
              };

              for (let i = 0; i <= this.allCapabilityList.length; i++) {
                isDuplicate = Boolean(
                  this.allCapabilityList.find(p1 =>
                    p1.capabilityName === CapabilityDetails.resource
                      ? true
                      : false
                  )
                );
                console.log('isDuplicate....1', isDuplicate);
              }
              console.log('isDuplicate...2', isDuplicate);

              if (isDuplicate == true) {
                let IsTemplateExist = this.allCapabilityList.filter(
                  o => o.capabilityName === capabilityName
                );
                console.log('IsTemplateExist', IsTemplateExist);

                if (IsTemplateExist.length > 0) {
                  this.devicepoolCapId = IsTemplateExist[0].capabilityId;
                  this.capabilityName = IsTemplateExist[0].capabilityName;
                  Push_temp_capabilities.push({
                    capTemplateId: 0,
                    capabilityId: this.devicepoolCapId,
                    capabilityName: this.capabilityName,
                    capabilityValue: capabilityValue,
                    brandId: this.brandId,
                    platformId: this.isFromHistory
                      ? this.reExeEnvDetails.platformId
                      : this.varplatformId
                  });
                }
              } else {
                this.toastr.error(
                  'Capability' +
                    ' ' +
                    CapabilityDetails.resource +
                    ' ' +
                    'is not present in Capability Master'
                );
                $('#popProgress').modal('hide');
                return;
              }
              // });
            }
            list_of_template_capability.push({
              capabilityIdValuesViews: Push_temp_capabilities,
              templateName: template_Name,
              userId: this.userId,
              usrTemplateName: this.df_templateName,
              hsAnyDevice: this.isHSAnyDeviceSelected ? true : false,
              templateType: this.deviceType,
              env: this.environment_type
            });

            console.log(
              'list_of_template_capability-----devices',
              list_of_template_capability
            );
            console.log('templateName', templateName);

            this.isFromStartExe = true;
            // if(!this.isHSAnyDeviceSelected)
            // {
            this.df_devices_template_list.push({
              capabilityTemplateName: template_Name
            });
            // }

            console.log(
              'this.df_devices_template_list',
              this.df_devices_template_list
            );

            if (this.isFromHistory) {
              this.TemplateList_forReexe = this.df_devices_template_list;
              console.log(
                'this.TemplateList_forReexe====>',
                this.TemplateList_forReexe
              );
            }
            await this.addTemplateCapability_arn(templateName);
          }
        }
        // await this.addSelectedCapabilities();
        await this.dataservice
          .addDeviceFarmCapabilityTemplate(list_of_template_capability)
          .toPromise()
          .then(async response2 => {
            console.log('capability saved response', response2);
            if (response2 != null) {
              $('#popProgress').modal('hide');
              this.toastr.success(
                'Capability details saved successfully...'
              );
              // setTimeout(async () => {
              $('#SelectDeviceTemplateModal').modal('hide');
              this.showPopup = false;

              // await this.getAllCapabilitytemplate();
              await this.addTemplateCapability_arn(templateName);
              await this.checkMandatoryCapabilities('', false);
              // }, 1000);
            }
            // capabilityResponse = response2;
            // await this.getcapabilityByProjectTypeId();
            await this.getAllCapabilitytemplate();
          })
          .catch(err => {
            console.log(err);
            this.toastr.error('Something went wrong');
          });
      }
    }
  }

  async CreateTemplate_D_Farm() {
    let Base_df_Capabilities = [];
    this.ListOfCapabilities = [];
    await this.dataservice
      .getcapabilityTemplateByName(this.df_templateName)
      .toPromise()
      .then(res => {
        if (res != null) {
          Base_df_Capabilities = res['capabilityIdValuesViews'];
        }
      })
      .catch(err => {
        console.log(err);
      });
    let templateName;
    let capabilityResponse;
    let list_of_template_capability = [];

    if (this.serverDevices != null) {
      for (let i = 0; i < this.serverDevices.length; i++) {
        let deviceName = this.serverDevices[i].name;
        let osVersion = this.serverDevices[i].os;
        console.log('osVersion..DF', osVersion);

        templateName = deviceName + '_' + this.devicePool;
        let obj_capability = [];
        for (let j = 0; j < Base_df_Capabilities.length; j++) {
          let capabilityName = Base_df_Capabilities[j].capabilityName;
          let capabilityValue;
          if (capabilityName == 'osVersion') {
            capabilityValue = osVersion;
          } else {
            capabilityValue = Base_df_Capabilities[j].capabilityValue;
          }
          // let capabilityValue = Base_df_Capabilities[j].capabilityValue;
          this.devicepoolCapId = Base_df_Capabilities[j].capabilityId;
          obj_capability.push({
            capTemplateId: 0,
            capabilityId: this.devicepoolCapId,
            capabilityName: capabilityName,
            capabilityValue: capabilityValue,
            barndId: this.brandId,
            platformId: this.isFromHistory
              ? this.reExeEnvDetails.platformId
              : this.varplatformId
          });
        }
        list_of_template_capability.push({
          capabilityIdValuesViews: obj_capability,
          templateName: templateName,
          userId: this.userId,
          hsAnyDevice: this.isHSAnyDeviceSelected ? true : false,
          templateType: this.deviceType,
          usrTemplateName: this.df_templateName,
          env: this.environment_type
        });

        console.log(
          'list_of_template_capability-----devices',
          list_of_template_capability
        );
        console.log('templateName', templateName);
        this.isFromStartExe = true;
        await this.addTemplateCapability_arn(templateName);
      }
      await this.addSelectedCapabilities();
      await this.dataservice
        .addDeviceFarmCapabilityTemplate(list_of_template_capability)
        .toPromise()
        .then(async response2 => {
          console.log('capability saved response', response2);
          capabilityResponse = response2;
          // await this.getcapabilityByProjectTypeId();
          await this.getAllCapabilitytemplate();
        })
        .catch(err => {
          console.log(err);
          this.toastr.error('Something went wrong');
        });
    }
  }

  async saveCapabilityTemplate_arn(
    templateName,
    arnvalue,
    capabilityId,
    capabilityName
  ) {
    let list_of_template_capability = {
      capabilityIdValuesViews: [
        {
          capTemplateId: 0,
          capabilityId: capabilityId,
          capabilityName: capabilityName,
          capabilityValue: arnvalue,
          barndId: this.brandId,
          platformId: this.isFromHistory
            ? this.reExeEnvDetails.platformId
            : this.varplatformId
        }
      ],
      templateName: templateName,
      userId: this.userId,
      env: this.environment_type
    };
    console.log('arn_capabilityTemplateDetails', list_of_template_capability);
    let capabilityResponse: any;
    await this.dataservice
      .addDeviceFarmCapabilityTemplate(list_of_template_capability)
      .toPromise()
      .then(async response2 => {
        console.log('capability saved response', response2);
        capabilityResponse = response2;
        // await this.getcapabilityByProjectTypeId();
        await this.getAllCapabilitytemplate();
        await this.addTemplateCapability_arn(templateName);
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }
 
  //Save capability Template
  async savecapabilityTemplate() {
    this.isAppChange = false;
    let local_new_capatempView;
    this.submitted = true;
    if (!this.isEditable) {
      //
    }
    if (this.capabilitytemplateForm.invalid == true) {
      return;
    }
    console.log(
      'this.deletedCapability_________this.deletedCapability',
      this.deletedCapability
    );

    if (this.deletedCapability != null) {
      for (let k = 0; k < this.deletedCapability.length; k++) {
        let capid = this.deletedCapability[k].capabilityId;
        let IsDelete = this.capabilityIdValuesViews.value.filter(function(
          item
        ) {
          //
          return item.capabilityId == capid;
        });
        if (IsDelete > 0) {
          delete this.deletedCapability[k];
        }
      }
    }
    if (
      this.capabilitytemplateForm.valid &&
      !this.inValid &&
      this.capabilityError == ''
    ) {
      this.capabilityTemplatedetails = this.capabilitytemplateForm.value;

      if (this.capabilityTemplatedetails.capabilityIdValuesViews.length == 0) {
        // this.capTemplate("Please add capabilities to template");
        this.toastr.error('Please add capabilities to template');
        return;
      }
      if (this.isEditable) {
        this.ListOfCapabilities.forEach((item, index) => {
          if (
            item.capabilityTemplateName ===
            this.capabilityTemplatedetails.templateName
          ) {
            this.ListOfCapabilities.splice(index, 1);
          }
        });
      }
      console.log();

      this.ListOfCapabilities.push({
        capabilityTemplateName: this.capabilityTemplatedetails.templateName
      });

      console.log(
        ' this.capabilityTemplatedetails---',
        this.capabilityTemplatedetails
      );
      if (
        this.deviceType == 'DeviceFarm' ||
        this.deviceType == Constvar.Headspin ||
        this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
      ) {
        console.log(
          'this.df_devices_template_list',
          this.df_devices_template_list
        );
        this.templateListData = [];
        this.replacedTemplateList = [];
        this.ishsIsTrue = false;
        let selected_devices_List_templates = [];
        const user_templateName = this.capabilityTemplatedetails.templateName;
        console.log(
          'selected_devices_List_templates---------1',
          selected_devices_List_templates
        );

        if (this.deviceType != Constvar.Headspin && this.deviceType != Constvar.BrowserStack &&  this.deviceType != 'LocalBrowserStack') {
          for (let j = 0; j < this.df_devices_template_list.length; j++) {
            let temp_data = Object.assign({}, this.capabilityTemplatedetails);
            let df_templateName = this.df_devices_template_list[j]
              .capabilityTemplateName;
            temp_data.templateName = df_templateName;
            temp_data.userId = this.userId;
            temp_data.usrTemplateName = user_templateName;
            (temp_data.hsAnyDevice = this.isHSAnyDeviceSelected ? true : false),
              (temp_data.templateType = this.deviceType),
              console.log('this. user temp name', temp_data);

            selected_devices_List_templates.push(temp_data);
            temp_data = {};
          }
          let osversion = this.objcapability_findUdid.filter(capability => {
            return capability.capabilityName == 'osVersion';
          });
          let osVersionId = osversion[0].capabilityId;
          let temp_data1 = Object.assign({}, this.capabilityTemplatedetails);
          temp_data1.userId = this.userId;
          temp_data1.usrTemplateName = user_templateName;
          (temp_data1.hsAnyDevice = this.isHSAnyDeviceSelected ? true : false),
            (temp_data1.templateType = this.deviceType),
            console.log('this. user temp name', temp_data1);

          selected_devices_List_templates.push(temp_data1);
          temp_data1 = {};
          // selected_devices_List_templates.push(this.capabilityTemplatedetails);
          selected_devices_List_templates.map((data, index) => {
            data.capabilityIdValuesViews = data.capabilityIdValuesViews.filter(
              capability => {
                return capability.capabilityId != osVersionId;
              }
            );
          });
        } else {
          this.df_devices_template_list.map(template => {
            template.capabilityTemplateName;
            let capTemplate = this.headSpinCapabilitytemplates.filter(
              captemp => {
                return template.capabilityTemplateName == captemp.templateName;
              }
            );
            capTemplate[0].userId = this.userId;
            this.capabilityTemplatedetails.capabilityIdValuesViews.map(cap => {
              let obj = capTemplate[0].capabilityIdValuesViews.find(
                o => o.capabilityId == cap.capabilityId
              );
              if (obj) {
                if (
                  obj.capabilityName != 'udid' &&
                  obj.capabilityName != 'appiumServerURL' &&
                  obj.capabilityName != 'osVersion'
                ) {
                  const index = capTemplate[0].capabilityIdValuesViews.findIndex(
                    el => el.capabilityId == obj.capabilityId
                  );
                  capTemplate[0].capabilityIdValuesViews[
                    index
                  ].capabilityValue = cap.capabilityValue;
                }
              } else {
                capTemplate[0].capabilityIdValuesViews.push(cap);
              }
            });
            selected_devices_List_templates.push(capTemplate[0]);
          });
        }

        console.log(
          'selected_devices_List_templates',
          selected_devices_List_templates
        );

        // REST API (After Merging)
        let selectedDevicesUpdateJson = selected_devices_List_templates;
        console.log('updateJson', selectedDevicesUpdateJson);

        await this.dataservice
          .addDeviceFarmCapabilityTemplate(selectedDevicesUpdateJson)
          .toPromise()
          .then(async response2 => {
            console.log('capability saved response', response2);
            if (response2 != null) {
              $('#popProgress').modal('hide');
              if (this.isEditable) {
                this.toastr.success('Capability updated successfully...');
                //changes by mustaid not to show default pop up
                $('#createTemplateModal').modal('hide');
                this.showPopup = false;
              } else if (!this.isEditable) {
                this.toastr.success('Capability saved successfully...');
                //changes by mustaid not to show default pop up
                $('#createTemplateModal').modal('hide');
                this.showPopup = false;
              } else {
                this.toastr.error('Failed');
              }
              //delete template capability by captemplateId
              if (this.deletedCapability != null && this.isEditable == true) {
                for (let k = 0; k < this.deletedCapList.length; k++) {
                  this.updatedCapTempList = this.deletedCapList[
                    k
                  ].capTemplateId;
                  let df_capabilityId = this.deletedCapList[k].capabilityId;
                  let df_usertemplate_name = user_templateName;

                  //
                  await this.dataservice


                    // Micro API (After Merging)
                    .deleted_dfCapability(
                      df_capabilityId,
                      df_usertemplate_name,
                      this.userId
                    )
                    .toPromise()
                    .then(async res => {
                      const response = res;
                      console.log('delete df_template capability', response);
                      if (response != null) {
                      }
                      if (
                        this.deletedCapList[k].capabilityName == Constvar.locale
                      ) {
                        this.localeCapabilityValue = Constvar.localeUS;
                      }
                    })
                    .catch(err => {
                      console.log(err);
                      this.toastr.error('Something went wrong');
                    });
                }
              }
            }
            await this.getAllCapabilitytemplate();
            this.showPopup = false;
            this.checkMandatoryCapabilities('', false);
          })
          .catch(err => {
            console.log(err);
            this.toastr.error('Something went wrong');
          });
      } else if (this.deviceType == 'Local') {
        let templateName = this.capabilityTemplatedetails.templateName;
        const capTempValue = this.capTemplateIdValue;
        this.capabilityTemplatedetails.userId = this.userId;
        this.capabilityTemplatedetails.usrTemplateName = templateName;
        (this.capabilityTemplatedetails.env = this.environment_type),
          console.log(
            'capabilityTemplatedetails',
            this.capabilityTemplatedetails
          );
        // local_new_capatempView = this.capabilityTemplatedetails.capabilityIdValuesViews;
        // local_new_capatempView.map(obj => {
        //   obj.userId = this.userId
        //   obj.usrTemplateName = templateName
        //   obj.templateName = templateName
        //   obj.env=this.environment_type
        // })
        await this.dataservice
          .AddCapabilityTemplate(this.capabilityTemplatedetails)
          .toPromise()
          .then(async response => {
            const AddCapTempRes = response;

            if (this.isEditable) {
              this.toastr.success('Capability updated successfully...');
              //changes by mustaid not to show default pop up
              $('#createTemplateModal').modal('hide');
              this.showPopup = false;
            } else if (!this.isEditable) {
              this.toastr.success('Capability saved successfully...');
              //changes by mustaid not to show default pop up
              $('#createTemplateModal').modal('hide');
              this.showPopup = false;
            } else {
              this.toastr.error('Failed');
            }
            //delete template capability by captemplateId
            if (this.deletedCapability != null && this.isEditable == true) {
              for (let k = 0; k < this.deletedCapList.length; k++) {
                this.updatedCapTempList = this.deletedCapList[k].capTemplateId;
                //
                await this.dataservice
                  .deleteCapabilityTemplateById(
                    this.updatedCapTempList,
                    this.userId
                  )
                  .toPromise()
                  .then(async res => {
                    const response = res;
                    console.log('delete template capability', response);
                    if (response != null) {
                    }
                  })
                  .catch(err => {
                    console.log(err);
                    this.toastr.error('Something went wrong');
                  });
              }
            }
            await this.getAllCapabilitytemplate();
            this.showPopup = false;
            this.checkMandatoryCapabilities('', false);
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else {
      this.toastr.warning(
        'Please try to enter different template name, because this template name already exist'
      );
    }
  }

  // ------ Showing message if success or error on dataset modal
  capTemplate(message) {
    this.msg_capTemplate = message;
    setTimeout(() => {
      if (this.msg_capTemplate.toString().indexOf(' missing ') == -1) {
        this.msg_capTemplate = '';
      }
    }, 2000);
  }

  //Get capability template
  getAllCapabilitytemplate() {
     
    this.dataservice

      // REST API (After Merging)
      .getcapabilityTemplateName(this.userId)

      // Micro API (After Merging)
      // .getcapabilityTemplateName_micro()
      .toPromise()
      .then(response => {
        this.allCapabilityTemplate = response;
        if (this.deviceType == Constvar.Headspin) {
          this.allCapabilityTemplate = this.allCapabilityTemplate.filter(
            template => {
              return template.templateType == Constvar.Headspin;
            }
          );
        }
        console.log('all capability template', this.allCapabilityTemplate);
      })
      .catch(err => {
        this.toastr.error(this.dataservice.error_500_status);
      });
  }
  GetCapabilityValues(e) {
    this.capabilityTemplateValue = e.target.value;
    this.capabilityTemplate = e.target.options[e.target.selectedIndex].text;
  }

  //Add template Capability
  addTemplateCapability() {
     
    this.isEditable = false;
    this.clearFormGroup();
    if (this.capabilityTemplate != null && this.capabilityTemplate != '') {
      let ds = this.capabilityTemplate;
      let filtered = this.ListOfCapabilities.filter(function(item) {
        //
        return item.capabilityTemplateName == ds;
      });
      if (filtered.length <= 0) {
        this.ListOfCapabilities.push({
          capabilityTemplateName: this.capabilityTemplate
        });
      } else {
        this.toastr.error('Selected capability template already added.');
      }
    } else {
      this.toastr.error('Please select capability template.');
    }
  }

  async addTemplateCapability_arn(arn) {
    // this.isEditable = false;
    // this.clearFormGroup();
    if (arn != null && arn != '' && arn != undefined) {
      let ds = arn;
      let filtered = this.ListOfCapabilities.filter(function(item) {
        return item.capabilityTemplateName == ds;
      });
      if (filtered.length <= 0) {
        this.ListOfCapabilities.push({ capabilityTemplateName: arn });
        console.log('this.ListOfCapabilities......', this.ListOfCapabilities);
      } else {
      }
      $('#SelectDeviceTemplateModal').modal('hide');
      this.showPopup = false;
      if (this.isFromStartExe == false) {
        await this.checkMandatoryCapabilities('', false);
      }
    } else {
      // this.show_errorMsg("Please select capability template.")
      this.toastr.error('Please select capability template.');
    }
  }

  show_errorMsg(message) {
    this.capTempError = message;
    setTimeout(() => {
      this.capTempError = '';
    }, 2500);
  }
  async getAllCapability(platformId) {
    this.allCapabilityList = [];
    await this.dataservice
      .getCapabilitiesByProjectTypeId(platformId)
      .toPromise()
      .then(async (response: any) => {
        this.allCapabilityList = response;
        console.log('allcapabilities', this.allCapabilityList);
      })
      .catch(err => {
        this.loaderService.hide();
        console.log(err);
      });
  }
  onSelectionChange(e, row_index) {
     
    this.capabilityError = '';
    const capabilityId = e.target.value;
    let ds = capabilityId;
    this.filtered = this.capabilityIdValuesViews.value.filter(function(item) {
      //
      return item.capabilityId == ds;
    });
    if (this.filtered.length > 1) {
      this.toastr.error('Selected Template Capability already added.');
    } else {
      this.capabilityError = '';
    }
  }
  deletecapabilitytemplateByName(capabilityTemplateName) {
    this.msg_capTemplate = '';
    this.capabilityError = '';
    console.log(this.ListOfCapabilities);
    let filtered: any;
    filtered = this.ListOfCapabilities.filter(function(item) {
      return item.capabilityTemplateName !== capabilityTemplateName;
    });
    this.ListOfCapabilities = filtered;
  }

  clearFormGroup() {
    //
    this.capabilitytemplateForm = this.formBuilder.group({
      templateName: [''],
      capabilityIdValuesViews: this.formBuilder.array([])
    });
    this.capabilitytemplateForm = this.formBuilder.group({
      templateName: ['', Validators.required],
      capabilityIdValuesViews: this.formBuilder.array([
        this.formBuilder.group({
          capabilityId: ['', Validators.required],
          capabilityValue: ['', Validators.required],
          capabilityName: ['']
        })
      ])
    });

    this.submitted = false;
  }

  //get capabiity template by name
  async getTemplateByName(
    capabilityTemplate,
    isMissingCapability,
    isfromValidateFourStep
  ) {
    this.loaderService.show();
    this.objcapability_findUdid = [];
    this.msg_capTemplate = '';
    this.dup_capTemplate = '';
    this.cor_capTemplate = '';
    this.capTemplateList = [];
    this.deletedCapList = [];
    this.capabilityRes = [];
    this.errorMessage = '';
    this.inValid = false;
    // await this.getcapabilityByProjectTypeId();
    this.capabilitytemplateForm = this.formBuilder.group({
      templateName: ['', Validators.required],
      capabilityIdValuesViews: this.formBuilder.array([])
    });
    if (
      this.deviceType != Constvar.Headspin &&
      this.deviceType != Constvar.BrowserStack && this.deviceType!='LocalBrowserStack'
    ) {
      await this.dataservice
        .getcapabilityTemplateByName(capabilityTemplate)
        .toPromise()
        .then((res: any) => {
          console.log('capability Template Name', res);
          let capabilityIdValuesViews_res = res['capabilityIdValuesViews'];
          const capability_result = [];
          const map = new Map();
          for (const item of capabilityIdValuesViews_res) {
            if (!map.has(item.capabilityId)) {
              map.set(item.capabilityId, true); // set any value to Map
              capability_result.push({
                capTemplateId: item.capTemplateId,
                capabilityId: item.capabilityId,
                capabilityName: item.capabilityName,
                capabilityValue: item.capabilityValue,
                platformId: item.platformId
              });
            }
          }
          if (this.deviceType == 'DeviceFarm') {
            capability_result.map(capability => {
              if (capability.capabilityName == 'mobileAppPath') {
                this.oldMobile_app_path = capability.capabilityValue;
                capability.capabilityValue =
                  this.deviceType == Constvar.Headspin
                    ? this.bundleId
                    : this.mobileAppPath_Skipped;
              }

            });
          }

          if (
            this.deviceType == Constvar.Headspin ||
            this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
          ) {
            capability_result.map(capability => {
              if (capability.capabilityName == 'mobileAppPath') {
                this.oldMobile_app_path = capability.capabilityValue;
                capability.capabilityValue =this.AppName
              }
              if(this.platform.toUpperCase() == Constvar.Android.toUpperCase())
              {
                if (capability.capabilityName == 'appId') {
                  capability.capabilityValue = this.appArn;
                }
              }
              else if(this.platform == Constvar.Ios)
              {
                if (capability.capabilityName == 'appId') {
                  capability.capabilityValue = this.appArn;
                  console.log('app id', capability.capabilityValue);
                }
                if (capability.capabilityName == 'bundleId') {
                  capability.capabilityValue = this.bundleId;
                  console.log('bundle id', capability.capabilityValue);
                }
              }
            });
          }

          res['capabilityIdValuesViews'] = capability_result;
          console.log(
            'unique_result......get tempalte name',
            capability_result
          );
          capability_result.forEach(item => {
            if (item.capabilityName === Constvar.locale) {
              this.localeCapabilityValue = item.capabilityValue;
            }
          });
          this.capabilityRes = res;
           
          this.dup_capTemplate = this.dataservice.checkIfCapabilityTemplateHasDuplicates(
            capability_result
          );
          console.log('this.dup_capTemplate', this.dup_capTemplate);
          if (this.dup_capTemplate != null) {
            this.toastr.error(this.dup_capTemplate);
          }
          this.cor_capTemplate = this.dataservice.checkIfCapabilityTemplateIsCorrputed(
            capability_result
          );
          console.log('this.cor_capTemplate', this.cor_capTemplate);
          if (this.cor_capTemplate != null) {
            this.toastr.error(this.cor_capTemplate);
          }

          if (res != null) {
            this.isEditable = true;
            // this.getAllCapability(this.platformId);
            //
            for (let i = 0; i < res['capabilityIdValuesViews'].length; i++) {
              //
              this.objcapability_findUdid.push({
                capabilityName:
                  res['capabilityIdValuesViews'][i].capabilityName,
                capabilityId: res['capabilityIdValuesViews'][i].capabilityId
              });
              // if(this.deviceType == 'DeviceFarm')
              // {
              //   if(res['capabilityIdValuesViews'][i].capabilityName == 'mobileAppPath')
              //   {
              this.capabilityIdValuesViews.push(
                this.formBuilder.group({
                  capabilityId: [
                    res['capabilityIdValuesViews'][i].capabilityId,
                    Validators.required
                  ],
                  capabilityValue: [
                    res['capabilityIdValuesViews'][i].capabilityName,
                    Validators.required
                  ],
                  capabilityName:
                    res['capabilityIdValuesViews'][i].capabilityName,
                  capTemplateId: res['capabilityIdValuesViews'][i].capTemplateId
                })
              );
              //   }

              // }
            }

            this.capabilitytemplateForm.patchValue(res);
            //REST API (After Merging)
            this.capabilitytemplateForm.controls['templateName'].setValue(
              res.usrTemplateName
            );

            //Micro API (After Merging)
            // this.capabilitytemplateForm.controls['templateName'].setValue(
            //   res.usrTemplateName
            // );
            console.log(
              'sfssssffff',
              this.capabilitytemplateForm.controls['templateName'].setValue(
                res.usrTemplateName
              )
            );

            if (isMissingCapability != true) {
              this.showPopup = true;
              this.checkMandatoryCapabilities(res['templateName'], false);
              if (!isfromValidateFourStep) {
                $('#createTemplateModal').modal('show');
              }
            }
            this.capDevice = res;
          } else {
            this.showPopup = false;
            this.checkMandatoryCapabilities(res['templateName'], false);
            if (!isfromValidateFourStep) {
              $('#createTemplateModal').modal('show');
            }
            return;
          }
          console.log();
        })
        .catch(err => {
          console.log(err);
          this.toastr.error('Something went wrong');
        });
    } else {
      await this.dataservice
        .getcapabilityTemplateByNameHeadspin(
          this.deviceType,
          capabilityTemplate,
          this.userId
        )
        .toPromise()
        .then(res => {
          this.headSpinCapabilitytemplates = res;
          this.loaderService.hide();
          res[0]['capabilityIdValuesViews'].forEach(item => {
            if (item.capabilityName === Constvar.locale) {
              this.localeCapabilityValue = item.capabilityValue;
            }
          });
          if (
            this.deviceType == Constvar.Headspin ||
            this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
          ) {
            res[0]['capabilityIdValuesViews'].map(capability => {
              if (capability.capabilityName == 'mobileAppPath') {
                this.oldMobile_app_path = capability.capabilityValue;
                capability.capabilityValue = this.AppName;
              }
              if (
                this.platform.toUpperCase() == Constvar.Android.toUpperCase()
              ) {
                if (capability.capabilityName == 'appId') {
                  capability.capabilityValue = this.appArn;
                }
              } else if (
                this.platform.toUpperCase() == Constvar.Ios.toUpperCase()
              ) {
                if (capability.capabilityName == 'appId') {
                  capability.capabilityValue = this.appArn;
                  console.log('app id', capability.capabilityValue);
                }
              }
            });
          }

          this.capabilityRes = res[0];
          this.dup_capTemplate = this.dataservice.checkIfCapabilityTemplateHasDuplicates(
            res[0]['capabilityIdValuesViews']
          );
          console.log('this.dup_capTemplate', this.dup_capTemplate);
          if (this.dup_capTemplate != null) {
            this.toastr.error(this.dup_capTemplate);
          }

          this.cor_capTemplate = this.dataservice.checkIfCapabilityTemplateIsCorrputed(
            res[0]['capabilityIdValuesViews']
          );
          console.log('this.cor_capTemplate', this.cor_capTemplate);
          if (this.cor_capTemplate != null) {
            this.toastr.error(this.cor_capTemplate);
          }

          if (res != null) {
            this.isEditable = true;
            //
            for (let i = 0; i < res[0]['capabilityIdValuesViews'].length; i++) {
              //
              this.objcapability_findUdid.push({
                capabilityName:
                  res[0]['capabilityIdValuesViews'][i].capabilityName,
                capabilityId: res[0]['capabilityIdValuesViews'][i].capabilityId
              });
              // if(this.deviceType == 'DeviceFarm')
              // {
              //   if(res['capabilityIdValuesViews'][i].capabilityName == 'mobileAppPath')
              //   {
              this.capabilityIdValuesViews.push(
                this.formBuilder.group({
                  capabilityId: [
                    res[0]['capabilityIdValuesViews'][i].capabilityId,
                    Validators.required
                  ],
                  capabilityValue: [
                    res[0]['capabilityIdValuesViews'][i].capabilityName,
                    Validators.required
                  ],
                  capabilityName:
                    res[0]['capabilityIdValuesViews'][i].capabilityName,
                  capTemplateId:
                    res[0]['capabilityIdValuesViews'][i].capTemplateId
                })
              );
              //   }

              // }
            }

            this.capabilitytemplateForm.patchValue(res[0]);
            this.capabilitytemplateForm.controls['templateName'].setValue(
              res[0].usrTemplateName

              // )
            );
            console.log(
              'sfssssffff',
              this.capabilitytemplateForm.controls['templateName'].setValue(
                res[0].usrTemplateName
              )
            );

            if (this.reExecution == true && this.isAppChange == true) {
              this.savecapabilityTemplate();
            }

            if (isMissingCapability != true) {
              this.showPopup = true;
              this.checkMandatoryCapabilities(res[0]['templateName'], false);

              if (!isfromValidateFourStep) {
                $('#createTemplateModal').modal('show');
              }
            }
            this.capDevice = res;
          } else {
            return;
          }
        });
    }
  }


  /* for duplication check */
  checkDuplication() {
    //
    const capTemplateDetails = this.capabilitytemplateForm.value;
    capTemplateDetails.resource = this.capabilitytemplateForm.value.templateName;
    capTemplateDetails.type = Type.CapabilityTemplate;
    capTemplateDetails.id = 0;
    this.dataservice.checkDuplication_Execution(capTemplateDetails).subscribe(
      (response: Boolean) => {
        this.inValid = response;
        if (this.inValid) {
          // this.errorMessage = "Capability Template Name is already in use for selected region";
          this.toastr.error('Capability Template Name is already in use');
        } else {
          this.errorMessage = '';
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }

  //close modal
  closeModal() {
    this.errorMessage = '';
    $('#createTemplateModal').modal('hide');
  }

  //reset capability template form
  clear() {
    //
    this.isEditable = false;
    this.clearFormGroup();
    this.capabilitytemplateForm.reset();

    this.inValid = false;
    this.errorMessage = '';
    this.capabilityError = '';
    this.capTemplateList = [];
  }

  // ------------------------ dataset operations------------------

  getSelectedDataSet(e) {
    this.Selected_dataSetName = e.target.value;
  }

  addDataSetList() {
    if (this.Selected_dataSetName != '') {
      let ds = this.Selected_dataSetName;
      let filtered = this.selectedData_set.filter(function(item) {
        return item.dataSetName == ds;
      });
      if (filtered.length <= 0) {
        this.selectedData_set.push({ dataSetName: this.Selected_dataSetName });
      } else {
        this.toastr.error('Selected dataset already added.');
      }
    } else {
      this.toastr.error('Please select dataset name.');
    }
  }

  show_dsMsg(message) {
    this.isdsError = message;
    setTimeout(() => {
      this.isdsError = '';
    }, 2500);
  }

  deleteDatasetFromList(dataSetName) {
    if (dataSetName != '') {
      let filtered = this.selectedData_set.filter(function(item) {
        return item.dataSetName != dataSetName;
      });
      this.selectedData_set = filtered;
    }
  }

  async getAllDataSetById(testsuiteId) {
    this.dataset_list = [];
    this.dataSetName = '';
    await this.dataservice
      .getAllDataSetBySuiteId(testsuiteId)
      .toPromise()
      .then(async (response: {}) => {
        this.dataset_list = response;
        console.log(this.dataset_list);
        this.dataset_list = this.dataset_list.filter(ele => {
          return ele.dataSetName.trim().length != 0;
        });
        console.log('after filter');

        console.log(this.dataset_list);

        if (response != null) {
          this.dataset_list.forEach(element => {
            element.IsSelected = false;
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }

  async getAllTestStepsById(suiteId) {
    this.testSteps = [];
    this.Test_steps_iterations = [];
    // this.dataservice.getTestStepByCase(suiteId).subscribe((response: {}) => {
    await this.dataservice
      .getTestStepBysuite(suiteId)
      .toPromise()
      .then((response: {}) => {
        console.log('Steps', response);

        this.testSteps = response;

        if (response != null) {
          this.Issteps = false;
          this.testSuiteId = suiteId;
        } else {
          this.Issteps = true;
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
  }
  isDatasetValid() {
    if (this.dataSetName == '') {
      this.IsData = true;
    } else {
      this.IsData = false;
    }
  }

  async GetDatasetDetails(dataSetName) {
    await this.getAllTestStepsById(parseInt(this.testSuiteId.toString()));

    this.deletedItern_Details = [];
    this.jsonDS_Iterations = [];
    this.Test_steps_iterations = [];

    this.dataSetName = dataSetName;
    this.jsonDS_Iterations = [];
    if (this.dataSetName != '') {
      if (this.testCaseId > 0) {
        this.dataservice
          .getAllDataSetIterationsByDsName(
            this.dataSetName,
            this.testSuiteId
          )
          .subscribe(
            (ItRes: {}) => {
              if (ItRes != null) {
                if (ItRes['steps'] != null) {
                  this.jsonDS_Iterations = ItRes['steps'];
                  this.max_it_id = ItRes['maxItrId'];
                  this.convert_jsTo_Tab();
                }
              } else {
                this.AddIteration();
              }
            },
            error => {
              console.log(error);
              this.toastr.error('Something went Wrong ');
            }
          );
      }
    } else {
      this.IsData = true;
    }
  }

  async convert_jsTo_Tab() {
    if (this.jsonDS_Iterations != null && this.jsonDS_Iterations.length > 0) {
      this.Test_steps_iterations = [];
      this.Iterations_keys = [];
      this.jsonDS_Iterations.sort(function(a, b) {
        if (a.testStepId > b.testStepId) return 1;
        return -1;
      });

      var o;
      o = this.jsonDS_Iterations.reduce((a, b) => {
        a[b.testStepId] = a[b.testStepId] || [];
        a[b.testStepId].push({
          ['testStepName']: b.testStepName,
          [b.iterationId]: b.inputData,
          ['keyword']: b.keyword,
          ['element']: b.element,
          ['isdataRequired']: b.isdataRequired,
          ['testCaseName']: b.testCaseName,
          ['testCaseId']: b.testCaseId,
          ['stepNumber']: b.stepNumber,
          ['testStepId']: b.testStepId,
          ['dataSetId']: b.dataSetId
        });
        return a;
      }, {});

      // let IsMissingItr = await this.FindMissingIterations(o);
      // if (IsMissingItr == true) {
      //   o = this.jsonDS_Iterations.reduce((a, b) => {
      //     a[b.testStepId] = a[b.testStepId] || [];
      //     a[b.testStepId].push({ ["testStepName"]: b.testStepName, [b.iterationId]: b.inputData, ["keyword"]: b.keyword, ["element"]: b.element, ["isdataRequired"]: b.isdataRequired, ["testCaseName"]: b.testCaseName, ["testCaseId"]: b.testCaseId, ["stepNumber"]: b.stepNumber, ["testStepId"]: b.testStepId, ["dataSetId"]: b.dataSetId });
      //     return a;
      //   }, {});
      // }

      var FormatedJson = Object.keys(o).map(function(k) {
        let keylen = Object.keys(o[k]).length;
        let testStepId;
        let var_Keyword;
        let var_Element;
        let Var_isdataRequired;
        let var_testCaseName;
        let var_stepNumber;
        let var_testCaseId;
        let var_testStepName;
        for (let l = 0; l < keylen; l++) {
          testStepId = o[k][l].testStepId;
          var_Keyword = o[k][l].keyword;
          var_Element = o[k][l].element;
          Var_isdataRequired = o[k][l].isdataRequired;
          var_stepNumber = o[k][l].stepNumber;
          var_testCaseName = o[k][l].testCaseName;
          var_testCaseId = o[k][l].testCaseId;
          var_testStepName = o[k][l].testStepName;
          delete o[k][l].testStepId;
          delete o[k][l].keyword;
          delete o[k][l].element;
          delete o[k][l].isdataRequired;
          delete o[k][l].stepNumber;
          delete o[k][l].testCaseName;
          delete o[k][l].testCaseId;
          delete o[k][l].testStepName;
        }
        return {
          testStepName: var_testStepName,
          keyword: var_Keyword,
          element: var_Element,
          isdataRequired: Var_isdataRequired,
          testCaseName: var_testCaseName,
          testCaseId: var_testCaseId,
          stepNumber: var_stepNumber,
          TestStep: k,
          testStepId: testStepId,
          inputData: Object.assign.apply({}, o[k])
        };
      });
      this.VarDS_Iterations = FormatedJson;

      // this.max_it_id

      // if (tempKeys.length > 1) {
      this.collen = 0;
      let no_ofKeys: any = [];

      no_ofKeys = Object.keys(this.VarDS_Iterations[0].inputData);
      for (let j = 0; j < no_ofKeys.length; j++) {
        if (no_ofKeys[j] != 'dataSetId') {
          this.collen = this.collen + 1;
        }
      }
      // }

      this.No_of_col = Array(this.collen)
        .fill(0)
        .map((x, i) => i);
      this.Test_steps_iterations = [];
      for (let i = 0; i < this.VarDS_Iterations.length; i++) {
        let len = Object.keys(this.VarDS_Iterations[i].inputData).length;
        this.Test_steps_iterations.push({
          testStepName: this.VarDS_Iterations[i].testStepName,
          testStepId: this.VarDS_Iterations[i].testStepId,
          keyword: this.VarDS_Iterations[i].keyword,
          element: this.VarDS_Iterations[i].element,
          isdataRequired: this.VarDS_Iterations[i].isdataRequired,
          testCaseName: this.VarDS_Iterations[i].testCaseName,
          testCaseId: this.VarDS_Iterations[i].testCaseId,
          stepNumber: this.VarDS_Iterations[i].stepNumber
        });
        this.Iterations_keys = Object.keys(this.VarDS_Iterations[i].inputData);
        for (let k = 0; k < len; k++) {
          if (this.Iterations_keys[k] == 'dataSetId') {
            this.Test_steps_iterations[i][
              'dataSetId' + k
            ] = this.VarDS_Iterations[i].inputData['dataSetId'];
            delete this.Iterations_keys[k];
          } else {
            this.Test_steps_iterations[i][
              'Iteration' + this.Iterations_keys[k]
            ] = this.VarDS_Iterations[i].inputData[this.Iterations_keys[k]];
          }
        }
      }
      this.Test_steps_iterations.sort(function(a, b) {
        return a.testCaseId - b.testCaseId || a.testStepId - b.testStepId;
        return -1;
      });
      $('#viewDataTemplateModal').modal('show');
    }
  }

  AddIteration() {
    let NoOfSteps = 0;
    let iterationId = 0;
    if (this.jsonDS_Iterations.length > 0) {
      this.saveExistingtbl();
      let iterationId_temp = Math.max.apply(
        Math,
        this.jsonDS_Iterations.map(function(o) {
          return o.iterationId;
        })
      );
      if (this.max_it_id > 0 && iterationId_temp + 1 == this.max_it_id) {
        iterationId = this.max_it_id;
      } else {
        iterationId = Math.max.apply(
          Math,
          this.jsonDS_Iterations.map(function(o) {
            return o.iterationId;
          })
        );
      }
    }
    if (this.testSteps != null) {
      this.Issteps = false;
      for (let m = 0; m < this.testSteps.length; m++) {
        let step = this.testSteps[m].testStepName;
        let keyword =
          this.testSteps[m].keyword == null
            ? ''
            : this.testSteps[m].keyword.toLowerCase();
        let element =
          this.testSteps[m].element == null
            ? ''
            : this.testSteps[m].element.toLowerCase();
        let testCaseName =
          this.testSteps[m].testCaseName == null
            ? ''
            : this.testSteps[m].testCaseName;
        let testCaseId =
          this.testSteps[m].testCaseId == null
            ? ''
            : this.testSteps[m].testCaseId;

        let isdataRequired = this.testSteps[m].isdataRequired;
        let stepNumber = this.testSteps[m].stepNumber;
        let NewIteration: any = {
          keyword: keyword,
          element: element,
          isdataRequired: isdataRequired,
          testCaseName: testCaseName,
          testCaseId: testCaseId,
          stepNumber: stepNumber,
          testStepId: this.testSteps[m].testStepId,
          testStepName: step.trim(),
          iterationId: iterationId + 1,
          inputData: '',
          dataSetId: '0'
        };
        this.jsonDS_Iterations.push(NewIteration);
      }
      this.convert_jsTo_Tab();
    } else {
      this.Issteps = true;
    }
  }

  deleteIteration(id) {
    if (this.jsonDS_Iterations.length > this.testSteps.length) {
      if (this.testSuiteId! > 0 && this.dataSetName != '') {
        this.saveExistingtbl();
        let IterationId = +id;
        if (IterationId > 0) {
          this.deletedItern_Details.push({
            dataSetName: this.dataSetName,
            iterationId: IterationId,
            testsuiteId: this.testSuiteId
          });
          let filtered: any;
          filtered = this.jsonDS_Iterations.filter(function(item) {
            return item.iterationId.toString() !== IterationId.toString();
          });
          this.jsonDS_Iterations = filtered;
          this.convert_jsTo_Tab();
        }
      } else {
        let msg =
          this.testSuiteId <= 0
            ? 'Select test suite'
            : 'Select / Enter dataset name';
        this.ShowMessage(msg);
      }
    } else {
      // this.ShowMessage("You can't delete this iteration, At least one iteration required...");
      this.toastr.error(
        "You can't delete this iteration, At least one iteration required..."
      );
      setTimeout(() => {
        this.errorMessage = '';
      }, 2000);
    }
  }

  addDataSet() {
    let { dataSetName } = this;
    if (dataSetName != '' || dataSetName != undefined) {
      const tblData: any[] = [];
      const keys: string[] = [];
      //  ----------- tblIterationData is a table name
      const tableEl = document.getElementById('tblIterationData');
      [].forEach.call(
        tableEl.querySelectorAll('tr'),
        (lineElement: HTMLElement) => {
          const rows = lineElement.querySelectorAll('td,th');
          /**
           * If is th tag, we store all items to keys array.
           */
          if (rows[0].nodeName === 'TH') {
            //We replace remove all whitespace char from key.
            rows.forEach((e: HTMLElement) =>
              keys.push(e.innerText.replace(/\s*/g, ''))
            );
          } else {
            // We craft dynamically item object.
            let item: any = {};
            keys.forEach((key, index) => {
              // We use keys array to populate it.
              if (key != 'StepNo.') {
                if (key == 'Steps') {
                  item[key] = rows[index].id;
                } else if (key == 'TestCase') {
                  item['TestCase'] = rows[index].innerHTML;
                  item['testCaseId'] = rows[index].id;
                } else {
                  let val = rows[index].innerHTML
                    .split('&lt;')
                    .join('')
                    .split('&gt;')
                    .join('')
                    .split('&amp')
                    .join('')
                    .split('amp')
                    .join('')
                    .split('lt')
                    .join('')
                    .split('gt')
                    .join('')
                    .split('<')
                    .join('')
                    .split('>')
                    .join('')
                    .split(';')
                    .join('')
                    .split(',')
                    .join('')
                    .split('br')
                    .join('')
                    .split('div')
                    .join('')
                    .split('/')
                    .join('');
                  item[key] = val;
                  item['dataSetId'] = rows[index].id;
                }
              }
            });
            //We Store it
            tblData.push(item);
          }
        }
      );

      let Iterations: any = [];
      for (let k = 0; k < tblData.length; k++) {
        let stepId = tblData[k].Steps;
        let varKeyword = tblData[k].Keyword.trim();
        let var_Element = tblData[k].Element.trim();
        let var_testCaseName = tblData[k].TestCase.trim();
        let var_testCaseId = tblData[k].testCaseId;

        let var_languageId = tblData[k].languageId;
        let IsrequiredObj = this.jsonDS_Iterations.filter(
          o => o.keyword === varKeyword
        );
        let Var_isdataRequired = true;
        let var_stepNumber = 0;
        if (IsrequiredObj != null) {
          Var_isdataRequired = IsrequiredObj[0].isdataRequired;
          var_stepNumber = IsrequiredObj[0].stepNumber;
        }

        let stepKeys = Object.keys(tblData[k]);
        for (let l = 0; l < stepKeys.length; l++) {
          if (
            stepKeys[l] != 'Steps' &&
            stepKeys[l] != 'dataSetId' &&
            stepKeys[l] != 'testStepName' &&
            stepKeys[l].toLowerCase() != 'keyword' &&
            stepKeys[l].toLowerCase() != 'element' &&
            stepKeys[l] != 'TestCase' &&
            stepKeys[l] != 'testCaseId'
          ) {
            let keyname = stepKeys[l];
            let PushIndex = Iterations.length;
            Iterations.push({
              testStepId: stepId,
              keyword: varKeyword,
              element: var_Element,
              isdataRequired: Var_isdataRequired,
              testCaseName: var_testCaseName,
              testCaseId: var_testCaseId,
              stepNumber: var_stepNumber,
              languageId: var_languageId
            });
            Iterations[PushIndex]['iterationId'] = keyname.replace(
              'Iteration',
              ''
            );
            Iterations[PushIndex]['inputData'] = tblData[k][keyname];
            Iterations[PushIndex]['testCaseId'] = tblData[k]['testCaseId'];
            Iterations[PushIndex]['languageId'] = tblData[k]['languageId'];
            Iterations[PushIndex]['dataSetId'] = tblData[k][
              'dataSetId'
            ].toString();
          }
        }
      }

      let jsnDataset: any = {};
      (jsnDataset.dataSetName = dataSetName),
        (jsnDataset.testSuiteId = this.testSuiteId),
        (jsnDataset.userId = this.userId);
      jsnDataset.steps = Iterations;

      this.dataservice.saveDataset(jsnDataset).subscribe(
        response => {
          if (response != null) {
            if (this.deletedItern_Details.length > 0) {
              for (let k = 0; k < this.deletedItern_Details.length; k++) {
                this.dataservice
                  .DeleteIterations(
                    this.deletedItern_Details[k].dataSetName,
                    this.deletedItern_Details[k].iterationId,
                    this.deletedItern_Details[k].testsuiteId
                  )
                  .subscribe(response => {});
              }
            }
            this.toastr.success('Dataset updated successfully');
            setTimeout(() => {
              $('#viewDataTemplateModal').modal('hide');
            }, 2500);
          }
        },
        error => {
          console.log(error);
          this.toastr.error('Something went Wrong ');
        }
      );
    } else {
      this.toastr.error('Enter Dataset Name');
    }
  }

  ResetSuiteData(val) {
    this.executionMode = '';
    var r = false;
    if (val == 0) {
      if (
        (this.projectTypeId != 0 ||
          this.regionId != 0 ||
          this.brandId != 0 ||
          this.platformId != 0 ||
          this.projectNameList != 0) &&
        val == 0
      ) {
        r = confirm('Are you sure you want to reset data?');
      } else {
        r = true;
      }
    } else if (val == 1) {
      if (this.testSuiteList != null) {
        if (this.testSuiteList.length > 0 && val == 0) {
          r = confirm('Are you sure you want to reset data?');
        } else {
          r = true;
        }
      }
    } else {
      r = true;
    }
    if (r == true) {
      this.regionId = 0;
      this.brandId = 0;
      this.projectTypeId = 0;
      (this.projectName = ''), (this.platformId = 0);
      this.testSuiteId = 0;
      this.testCaseId = 0;
      this.projectNameList = [];
      this.testSuiteList = [];
      this.testCaseList = [];
      this.platformList = [];
      this.projectNameList = [];
      // this.projectTypeList = [];
      this.regionList = [];
      this.brandList = [];
      this.jsonExecutiontbl = [];
      this.varplatformId = 0;
      this.checkRecentSelctedTest_Suite(0);
      $('#All').prop('checked', false);
    }
  }

  saveExistingtbl() {
    let { dataSetName } = this;
    if (dataSetName != '' || dataSetName != undefined) {
      const tblData: any[] = [];
      const keys: string[] = [];
      //  ----------- tblIterationData is a table name
      const tableEl = document.getElementById('tblIterationData');

      [].forEach.call(
        tableEl.querySelectorAll('tr'),
        (lineElement: HTMLElement) => {
          const rows = lineElement.querySelectorAll('td,th');
          /**
           * If is th tag, we store all items to keys array.
           */
          if (rows[0].nodeName === 'TH') {
            //We replace remove all whitespace char from key.
            rows.forEach((e: HTMLElement) =>
              keys.push(e.innerText.replace(/\s*/g, ''))
            );
          } else {
            // We craft dynamically item object.
            let item: any = {};
            keys.forEach((key, index) => {
              if (key != 'StepNo.') {
                // We use keys array to populate it.

                if (key == 'Steps') {
                  item[key] = rows[index].id;
                  item['testStepName'] = rows[index].innerHTML;
                } else if (key == 'TestCase') {
                  item['TestCase'] = rows[index].innerHTML;
                  item['testCaseId'] = rows[index].id;
                } else {
                  let val = rows[index].innerHTML
                    .split('&lt;')
                    .join('')
                    .split('&gt;')
                    .join('')
                    .split('&amp')
                    .join('')
                    .split('amp')
                    .join('')
                    .split('lt')
                    .join('')
                    .split('gt')
                    .join('')
                    .split('<')
                    .join('')
                    .split('>')
                    .join('')
                    .split(';')
                    .join('')
                    .split(',')
                    .join('')
                    .split('br')
                    .join('')
                    .split('div')
                    .join('')
                    .split('/')
                    .join('');
                  item[key] = val;
                  item['dataSetId'] = rows[index].id;
                }
              }
            });
            //We Store it
            tblData.push(item);
          }
        }
      );

      let Iterations: any = [];
      for (let k = 0; k < tblData.length; k++) {
        let stepId = tblData[k].Steps;
        let varKeyword = tblData[k].Keyword.trim();
        let var_Element = tblData[k].Element.trim();
        let var_testCaseName = tblData[k].TestCase.trim();
        let var_testCaseId = tblData[k].testCaseId;
        let IsrequiredObj = this.jsonDS_Iterations.filter(
          o => o.keyword === varKeyword
        );
        let Var_isdataRequired = true;
        let var_stepNumber = 0;
        if (IsrequiredObj != null) {
          Var_isdataRequired = IsrequiredObj[0].isdataRequired;
          var_stepNumber = IsrequiredObj[0].stepNumber;
        }

        let stepKeys = Object.keys(tblData[k]);
        for (let l = 0; l < stepKeys.length; l++) {
          if (
            stepKeys[l] != 'Steps' &&
            stepKeys[l] != 'dataSetId' &&
            stepKeys[l] != 'testStepName' &&
            stepKeys[l].toLowerCase() != 'keyword' &&
            stepKeys[l].toLowerCase() != 'element' &&
            stepKeys[l] != 'TestCase' &&
            stepKeys[l] != 'testCaseId'
          ) {
            let keyname = stepKeys[l];
            let PushIndex = Iterations.length;
            Iterations.push({
              testStepId: stepId,
              keyword: varKeyword,
              element: var_Element,
              isdataRequired: Var_isdataRequired,
              testCaseName: var_testCaseName,
              testCaseId: var_testCaseId,
              stepNumber: var_stepNumber
            });
            Iterations[PushIndex]['iterationId'] = keyname.replace(
              'Iteration',
              ''
            );
            Iterations[PushIndex]['inputData'] = tblData[k][keyname];
            Iterations[PushIndex]['testStepName'] = tblData[k][
              'testStepName'
            ].trim();
            Iterations[PushIndex]['dataSetId'] = tblData[k][
              'dataSetId'
            ].toString();
          }
        }
      }

      this.jsonDS_Iterations = Iterations;
      // this.convert_jsTo_Tab();
    }
  }
  // ------------------------ dataset operations------------------

  // ------ Showing message if success or error on dataset modal
  ShowMessage(message) {
    this.Message_ds_modal = message;
    setTimeout(() => {
      this.Message_ds_modal = '';
    }, 2000);
  }

  // ----------------- Selected List Table Operations
  hideShowtableRow(id) {
    if ($('#TS' + id).hasClass('closeaccord')) {
      $('#TS' + id).addClass('openaccord');
      $('#TS' + id).removeClass('closeaccord');
      const newdata = this.jsonExecutiontbl.filter(testdata => {
        if (testdata.testSuiteId === id) {
          return testdata;
        }
      });
      let templateString = '';
      let arr = newdata[0].testCases;
      arr.forEach(element => {
        templateString +=
          '<tr class="tblexec-row" > <td class="tblexec-td" width="40%"></td>\
      <td class="tblexec-td">' +
          element.testCaseName +
          '</td> <td class="tblexec-td col-delete-nested WrapInLine">\
      <a class="tscdel" data-std="' +
          id +
          '" data-sctd="' +
          element.testCaseId +
          '">\
          <img src="assets/images/trash.png">\
      </a>\
  </td></tr>';
      });
      $('#TS' + id)
        .closest('tr')
        .after(
          '<tr class="testcasetr" id="TSC' +
            id +
            '"><td colspan="5"><table id="casetableExecution" class="table" width="100%">\
    <tbody>' +
            templateString +
            '</tbody></td></tr>'
        );
      let children = document.getElementsByClassName('tscdel');
      for (let i = 0; i < children.length; i++) {
        children[i].addEventListener('click', (event: Event) => {
          this.ConfirmThisOrder(event);
        });
      }
    } else if ($('#TS' + id).hasClass('openaccord')) {
      $('#TS' + id).addClass('closeaccord');
      $('#TS' + id).removeClass('openaccord');
      $('#TSC' + id).remove();
    }
    if ($('#nn' + id).hasClass('out')) {
      $('#nn' + id).addClass('in');
      $('#nn' + id).removeClass('out');
      $('#rr' + id).removeClass('caret');
      $('#rr' + id).addClass('caret caret-down');
    } else {
      $('#nn' + id).addClass('out');
      $('#nn' + id).removeClass('in');
      $('#rr' + id).removeClass('out');

      $('#rr' + id).removeClass('caret caret-down');
      $('#rr' + id).addClass('caret');
    }
  }
  ConfirmThisOrder(e) {
    let currentTarget = e.currentTarget.dataset;
    let testid = currentTarget.std;
    let testcaseid = currentTarget.sctd;
    this.deleteTestCase(testid, testcaseid);
  }
  // -------------- Tree View Operations

  createBrandTreeView(region, regionId, regionCode) {
    this.Sele_testSuiteid = '';
    this.Sele_testSuiteText = '';
    this.Sele_testCaseid = '';
    this.Sele_testCaseText = '';
    if (this.brandList != null) {
      var parentDiv = document.getElementById(regionCode);
      if (parentDiv.childElementCount <= 0) {
        var eleNext_ul = document.createElement('ul');
        eleNext_ul.className = 'nested ' + regionCode;
        var time = regionId + '_' + regionCode.replace(/\s+/g, ''); // new Date();
        for (let k = 0; k < this.brandList.length; k++) {
          var eleNext_li = document.createElement('li');

          var eleNext_span = document.createElement('span');
          eleNext_span.className = 'caret';
          eleNext_span.innerHTML = ' ' + this.brandList[k].brandName;
          eleNext_span.id =
            k +
            '_' +
            this.brandList[k].brandName.replace(/\s+/g, '') +
            '_' +
            time;

          var eleNext_img = document.createElement('img');
          eleNext_img.src = 'assets/images/brand.png';
          eleNext_span.prepend(eleNext_img);

          eleNext_li.appendChild(eleNext_span);
          eleNext_span.addEventListener(
            'click',
            this.createSuiteTreeView.bind(
              this,
              regionId,
              this.brandList[k].brandId,
              k +
                '_' +
                this.brandList[k].brandName.replace(/\s+/g, '') +
                '_' +
                time,
              this.brandList[k].brandName.replace(/\s+/g, '') + '_' + time
            )
          );
          var eleNext_suiteDiv = document.createElement('div');
          eleNext_suiteDiv.id =
            this.brandList[k].brandName.replace(/\s+/g, '') + '_' + time;

          eleNext_li.appendChild(eleNext_suiteDiv);
          eleNext_ul.appendChild(eleNext_li);
        }

        parentDiv.appendChild(eleNext_ul);
      }
      //  this.setCaretFunctioning();
    }
  }
  createSuiteTreeView(regionId, brandId, parentId_, childId_) {
    alert('createSuiteTreeView');
    this.glob_regionId = regionId;
    this.glob_brandId = brandId;
    this.glob_parentId_ = parentId_;
    this.glob_childId_ = childId_;

    this.Sele_testSuiteid = '';
    this.Sele_testSuiteText = '';
    this.Sele_testCaseid = '';
    this.Sele_testCaseText = '';
    console.log(childId_);
    var parentDiv = document.getElementById(childId_);
    if (parentDiv != null) {
      if (parentDiv.childElementCount <= 0) {
        var time = regionId + '_' + brandId; //new Date().getTime();
        console.log('getTestSuiteNameAndId.......');
        this.dataservice
          .getAllTestSuite_withoutProject(regionId, brandId, 0, '')
          .subscribe(
            (response: {}) => {
              this.testSuiteList = response;
              if (this.testSuiteList != null) {
                var eleNext_ul = document.createElement('ul');
                eleNext_ul.className = 'nested ' + childId_;
                for (let k = 0; k < this.testSuiteList.length; k++) {
                  var eleNext_li = document.createElement('li');

                  //  eleNext_li.className = "caret"
                  var eleNext_span = document.createElement('span');
                  eleNext_span.innerHTML =
                    ' ' + this.testSuiteList[k].testSuiteName;
                  eleNext_span.className = 'caret';
                  eleNext_span.id =
                    k +
                    '_' +
                    this.testSuiteList[k].testSuiteName.replace(/\s+/g, '') +
                    '_' +
                    time;

                  var eleNext_img = document.createElement('img');
                  eleNext_img.src = 'assets/images/test_suite.png';
                  eleNext_span.prepend(eleNext_img);

                  eleNext_li.appendChild(eleNext_span);
                  eleNext_span.addEventListener(
                    'click',
                    this.createCaseTreeView.bind(
                      this,
                      regionId,
                      brandId,
                      this.testSuiteList[k].testSuiteId,
                      this.testSuiteList[k].testSuiteName,
                      k +
                        '_' +
                        this.testSuiteList[k].testSuiteName.replace(
                          /\s+/g,
                          ''
                        ) +
                        '_' +
                        time,
                      this.testSuiteList[k].testSuiteName.replace(/\s+/g, '') +
                        '_' +
                        time
                    )
                  );
                  var eleNext_suiteDiv = document.createElement('div');
                  eleNext_suiteDiv.id =
                    this.testSuiteList[k].testSuiteName.replace(/\s+/g, '') +
                    '_' +
                    time;
                  eleNext_li.appendChild(eleNext_suiteDiv);
                  eleNext_ul.appendChild(eleNext_li);
                }
                parentDiv.appendChild(eleNext_ul);

                //  this.setCaretFunctioning();
                // }
              }
              this.Expand_Collape_Tree(parentId_, childId_);
            },
            error => {
              console.log(error);
              this.toastr.error('Something went Wrong ');
            }
          );
      } else {
        this.Expand_Collape_Tree(parentId_, childId_);
      }
    }
  }

  createCaseTreeView(
    regionId,
    brandId,
    testsuitId,
    testsuite,
    parentId_,
    childId_
  ) {
    this.clearSelectedSuite('');
    this.testSuiteId = testsuitId;
    this.Sele_testSuiteid = testsuitId;
    this.Sele_testSuiteText = testsuite;
    this.Sele_testCaseid = '';
    this.Sele_testCaseText = '';
    let objPlatform = this.testSuiteList.filter(
      suite => suite.testSuiteId == this.Sele_testSuiteid
    );
    this.platform = objPlatform[0].platformName;

    this.dataset_list = [];
    this.Selected_dataSetName = '';
    this.selectedValueds = 0;
    this.selectedData_set = [];

    this.getAllDataSetById(this.testSuiteId);
    this.globCase_childId_ = childId_;
    this.globCase_parentId_ = parentId_;
    this.glob_testsuite = testsuite;
    this.glob_suiteId_ = testsuitId;

    var parentDiv = document.getElementById(childId_);
    if (parentDiv != null) {
      if (parentDiv.childElementCount <= 0) {
        let testcaseId = Type.TestCaseId;
        let desc = Type.descending;
        this.dataservice
          .getTestCaseByTestSuiteId(testcaseId, desc, testsuitId)
          .subscribe(
            (response: {}) => {
              this.testCaseList = response;
              console.log('case list', this.testCaseList);

              if (this.testCaseList != null) {
                var eleNext_ul = document.createElement('ul');
                eleNext_ul.className = 'nested ' + childId_;

                for (let k = 0; k < this.testCaseList.length; k++) {
                  var eleNext_li = document.createElement('li');

                  var eleNext_i = document.createElement('i');
                  eleNext_i.className =
                    'fa fa-file-text-o ico_before inlineCase';
                  eleNext_i.id = this.testCaseList[k].testCaseId;

                  eleNext_li.appendChild(eleNext_i);
                  var eleNext_span = document.createElement('span');
                  eleNext_span.innerHTML = this.testCaseList[k].testCaseName;
                  eleNext_span.className = 'inlineCase '; //highlight_li

                  eleNext_li.appendChild(eleNext_span);
                  eleNext_span.addEventListener(
                    'click',
                    this.GetTestCaseDetails.bind(
                      this,
                      testsuitId,
                      testsuite,
                      this.testCaseList[k].testCaseId,
                      this.testCaseList[k].testCaseName
                    )
                  );
                  var eleNext_suiteDiv = document.createElement('div');
                  eleNext_suiteDiv.id =
                    this.testCaseList[k].testCaseName + '_' + testsuitId;

                  eleNext_li.appendChild(eleNext_suiteDiv);
                  eleNext_ul.appendChild(eleNext_li);
                }
                parentDiv.appendChild(eleNext_ul);
                // }
              }
              this.Expand_Collape_Tree(parentId_, childId_);
            },
            error => {
              console.log(error);
              this.toastr.error('Something went Wrong ');
            }
          );
      } else {
        this.Expand_Collape_Tree(parentId_, childId_);
      }
    }
  }

  GetTestCaseDetails(testsuitId, testsuite, test_caseId, testcaseName) {
    this.Sele_testCaseid = '';
    this.Sele_testCaseText = '';

    this.Sele_testSuiteText = testsuite;
    this.Sele_testSuiteid = testsuitId;
    this.Sele_testCaseid = test_caseId;
    this.Sele_testCaseText = testcaseName;
    this.testCaseId = test_caseId;
  }

  Expand_Collape_Tree(parentId, childId) {
    let toggler = $('#' + childId).find('.' + childId);

    if ($('#' + parentId).hasClass('caret caret-down')) {
      $('#' + parentId).removeClass('caret caret-down');
      $('#' + parentId).addClass('caret');
      toggler.removeClass('active');
    } else {
      $('#' + parentId).removeClass('caret');
      toggler.addClass('active');
      $('#' + parentId).addClass('caret caret-down');
    }
  }

  // ------------------ Clear button operations
  // -- Clear dataset list
  // -- Clear Capability list
  //--- If clearFrom==0 then clear all test cases else nothing

  clearSelectedSuite(clearfrom) {
    if (clearfrom == '0') {
      this.createSuiteTreeView(
        this.glob_regionId,
        this.glob_brandId,
        this.glob_parentId_,
        this.glob_childId_
      );
    }
    this.isArnTemplate = false;
    this.selectedData_set = [];
    this.Sele_testCaseid = '';
    this.Sele_testCaseText = '';
    this.dataset_list = [];
    this.Selected_dataSetName = '';
    this.selectedValueds = 0;
    this.ListOfCapabilities = [];
    this.Sele_testSuiteText = '';
    this.Sele_testSuiteid = '';
    this.selectedValueTemplate = 0;
    this.capabilityTemplateValue = '';
    this.capabilityTemplate = '';
    this.Recentapps = 0;
    this.file = null;
    this.ddldevicepool = 0;
    this.jenkinsNode = 0;
    this.isUpdateListOfUpload = true;
    // this.listofUploads = [];
    this.clearFiles();
  }

  // ... Clear Test selected Test case details
  clear_case_list() {
    this.selectedData_set = [];
    this.Sele_testCaseid = '';
    this.Sele_testCaseText = '';
    this.dataset_list = [];
    this.Selected_dataSetName = '';
    this.selectedValueds = 0;

    $('.highlight_li').removeClass('highlight_li');
    $('.ico').removeClass('ico');
  }

  // ---------- Used to add selected datasets and capability , test case , test suite into table
  AddTestExecutionData() {
    let IsInsert: boolean = true;

    this.showPopup = false;
    this.checkMandatoryCapabilities('', false);

    if (this.isValidCap != true) {
      return;
    }

    if (this.Sele_testSuiteid == '') {
      IsInsert = false;
      this.toastr.error('Please select test suite');
    } else if (this.Sele_testCaseid == '') {
      IsInsert = false;
      this.toastr.error('Please select test case');
    } else if (
      this.ListOfCapabilities.length <= 0 &&
      this.deviceType == 'Local'
    ) {
      IsInsert = false;
      this.toastr.error('Please add one / more Capabilities templates');
    } else if (this.selectedData_set.length <= 0) {
      IsInsert = false;
      this.toastr.error('Please add one / more datasets');
    } else if (
      this.deviceType == 'DeviceFarm' &&
      this.SelectedDevicePool_arn == ''
    ) {
      IsInsert = false;
      this.toastr.error('Select device pool');
    } else {
      IsInsert = true;
    }
    let lastSuiteRowId = -1;
    for (let k = 0; k < this.jsonExecutiontbl.length; k++) {
      for (let l = 0; l < this.jsonExecutiontbl[k].testCases.length; l++) {
        let tempTestSuiteId = this.jsonExecutiontbl[k].testSuiteId;
        if (
          this.jsonExecutiontbl[k].testCases[l].testCaseId ==
            this.Sele_testCaseid &&
          tempTestSuiteId == this.Sele_testSuiteid
        ) {
          this.toastr.error('Selected Test case is already added...');
          IsInsert = false;
          break;
        }
      }
      if (IsInsert == false) {
        break;
      } else {
        if (this.jsonExecutiontbl[k].testSuiteId == this.Sele_testSuiteid) {
          lastSuiteRowId = k;
          break;
        }
      }
    }
    if (IsInsert == true) {
      if (lastSuiteRowId >= 0) {
        this.jsonExecutiontbl[lastSuiteRowId].templateNameList = [];
        this.jsonExecutiontbl[
          lastSuiteRowId
        ].templateNameList = this.ListOfCapabilities;
        this.jsonExecutiontbl[lastSuiteRowId].testCases.push({
          testCaseId: this.Sele_testCaseid,
          testCaseName: this.Sele_testCaseText,
          dataSetNameList: this.selectedData_set
        });
      } else {
        this.jsonExecutiontbl.push({
          testSuiteName: this.Sele_testSuiteText,
          testSuiteId: this.Sele_testSuiteid,
          templateNameList: this.ListOfCapabilities,
          testCases: [
            {
              testCaseId: this.Sele_testCaseid,
              testCaseName: this.Sele_testCaseText,
              dataSetNameList: this.selectedData_set
            }
          ]
        });
      }
      this.clear_case_list();
    }
    console.log('jsion' + this.jsonExecutiontbl);
  }
  clear_addmessage(message) {
    this.Errormsg_Add_Execution = message;
    setTimeout(() => {
      this.Errormsg_Add_Execution = '';
    }, 2500);
  }
  clear_TestExecutionMsg(message) {
    this.IsjobName_error = message;
    setTimeout(() => {
      this.IsjobName_error = '';
    }, 3000);
  }
  // ------- Delete test case from table [local json]
  deleteTestCase(testSuitId, testcaseId) {
    let isConfirm = confirm(
      'Are you sure that you want to delete this test case?'
    );
    if (isConfirm == true) {
      if (testSuitId != '' && testcaseId != '') {
        for (let k = 0; k < this.jsonExecutiontbl.length; k++) {
          let jsonTestSuiteId = this.jsonExecutiontbl[k].testSuiteId;
          if (testSuitId == jsonTestSuiteId) {
            this.jsonExecutiontbl[k].testCases = this.jsonExecutiontbl[
              k
            ].testCases.filter(function(item) {
              return item.testCaseId != testcaseId;
            });
            this.TotalSelectedTestCases = this.jsonExecutiontbl[
              k
            ].testCases.length;
            // this.jsonExecutiontbl[k].testCases = testcase;
            if (this.jsonExecutiontbl[k].testCases.length == 0) {
              this.deleteTestSuite(testSuitId);
            } else {
              //  this.setNext_Prev(this.selectedtSuiteStep_stepper, '');
            }

            break;
          }
        }
        $('.testcasetr').remove();
      } else {
        this.toastr.error('Unable to delete this test case');
      }
    }
  }

  deleteTestSuite(testSuitId) {
    let isConfirm = confirm(
      'Are you sure that you want to delete this test suite?'
    );
    if (isConfirm == true) {
      if (testSuitId != '') {
        let testsuites = this.jsonExecutiontbl.filter(function(item) {
          return item.testSuiteId != testSuitId;
        });
        this.jsonExecutiontbl = testsuites;
        if (this.jsonExecutiontbl.length <= 0) {
          this.testCaseList = [];
          this.dataset_list = [];
          this.TotalSelectedTestCases = 0;
          this.caseList_stepper = [];
          this.DsList_stepper = [];
          this.selectedTestSuiteName_step = '';
          $('#chkcaseAll').prop('checked', false);
          $('#chkDsAll').prop('checked', false);
        }

        this.checkRecentSelctedTest_Suite(0);
      } else {
        // this.clear_addmessage("Unable to delete this test suite")
        this.toastr.error('Unable to delete this test suite');
      }
    }
  }
  // ---------- Check if Test Execution name is dupliacte{}
  async isExeNameDuplicate() {
    if (this.txtjobName != '') {
      let Data = {
        resource: this.txtjobName,
        type: Type.Execution,
        id: 0
      };
      await this.dataservice
        .checkDuplication_Execution(Data)
        .toPromise()
        .then((response: Boolean) => {
          // Success
          this.inValid = response;
          if (this.inValid) {
            this.toastr.error(
              'Test execution name "' + this.txtjobName + '" already exists'
            );
          } else {
            this.clear_TestExecutionMsg('');
          }
        })
        .catch(err => {
          console.log(err);
          this.toastr.error('Something went wrong');
        });
    }
  }

  // '------------- Save Execution Data
  async save_startExecution_Details(scheduleDetails) {
     
    if (scheduleDetails != null && scheduleDetails != undefined) {
      this.scheduleFlag = true;
      this.scheduleDetails = scheduleDetails;
      console.log('this.scheduleDetails', this.scheduleDetails);
    }
    $('#startExeBtnView').prop('disabled', true);
    this.dataservice.isDevicesLoading = true;
    this.jenkinsJobIdNum = 0;
    this.serverDevices.forEach(object => {
      this.platform;
      this.isWeb = this.platform;
      console.log(' object.platform object.platform', this.isWeb);
    });

    if (
      this.isWeb != 'Windows' &&
      this.deviceType != 'DeviceFarm' &&
      this.deviceType != Constvar.Headspin &&
      this.deviceType != Constvar.BrowserStack && this.deviceType!='LocalBrowserStack'
    ) {
      
      await this.getlocalDeviceFromJenkinsJob(false);
    } else if (this.isWeb == 'Windows') {
      await this.check_data_consoleOutFuc(scheduleDetails);
    }

    if (
      this.deviceType == 'DeviceFarm' ||
      this.deviceType == Constvar.Headspin ||
      this.deviceType == Constvar.BrowserStack || 
      this.deviceType =='LocalBrowserStack'
    ) {
      this.timeStart_execution = await new Promise<void>(resolve =>
        setTimeout(() => resolve(), 5000)
      )
        .then(async () => {
          if (this.dataservice.isconsoleOut == false) {
            clearInterval(this.timeStart_execution);
            await this.check_data_consoleOutFuc(scheduleDetails);
          }
        })
        .catch(err => {
          console.log(err);
          this.toastr.error('Something went wrong');
        });
    }
  }

  async check_data_consoleOutFuc(scheduleDetails) {
    this.isfromExecution = true;
    if (
      this.isWeb != 'Windows' &&
      this.deviceType != 'DeviceFarm' &&
      this.deviceType != Constvar.Headspin &&
      this.deviceType != Constvar.BrowserStack  && this.deviceType!='LocalBrowserStack'
    ) {
      if (this.serverDevices.length == 0) {
        return;
      }

      if (this.isPreviousArray.length > 0) {
        this.isPreviousArray.forEach(object => {
          delete object.TemplateName, (object.selected = false);
        });
        console.log(
          'TemplateName this.isPreviousArray---->',
          this.isPreviousArray
        );

        let isExists = this.dataservice.findIsExists(
          this.serverDevices,
          this.isPreviousArray
        );
        console.log('check isExist--->', isExists);
        if (!isExists) {
          if (this.serverDevices < this.isPreviousArray) {
            let isConfirm = confirm(
              'You have previously selected ' +
                this.isPreviousArray.length +
                ' device(s), but now have connected to only ' +
                this.serverDevices.length +
                ' device(s). Do you want to continue start execution only on ' +
                this.serverDevices.length +
                ' device(s)? '
            );
            if (!isConfirm) {
              $('#startExeBtnView').prop('disabled', false);
              this.dataservice.isDevicesLoading = false;
              return;
            }
          } else {
            this.toastr.error(
              'The device connected previously is no longer connected to the machine. Please connect and retry execution.'
            );
            return;
          }
        } else {
          if (this.serverDevices > this.isPreviousArray) {
            let isConfirm = confirm(
              'You have connected to ' +
                this.serverDevices.length +
                ' device(s) to your system, but you have selected to only ' +
                this.isPreviousArray.length +
                ' device(s). Do you want to continue start execution only on ' +
                this.isPreviousArray.length +
                ' device(s)? '
            );
            if (!isConfirm) {
              $('#startExeBtnView').prop('disabled', false);
              this.dataservice.isDevicesLoading = false;
              return;
            }
          }
        }
      }
    }
    if (this.inValid == false) {
      if (
        this.txtjobName != '' &&
        this.AppVersionName != '' &&
        this.environment_type != ''
      ) {
        if (
          this.deviceType == 'DeviceFarm' &&
          this.file == null &&
          this.ByUpload == ''
        ) {
          this.toastr.error('Please select file to upload.');
        } else if (
          this.jsonExecutiontbl == null ||
          this.jsonExecutiontbl.length <= 0
        ) {
          this.toastr.error(
            'Please select test suites and test cases to run test.'
          );
        } else if (
          this.deviceType == 'DeviceFarm' &&
          this.ByUpload == 'Using_Arn' &&
          this.appId == 0
        ) {
          this.toastr.error('Please upload / select file to run test.');
        } else if (
          this.deviceType == 'DeviceFarm' &&
          this.SelectedDevicePool_arn == ''
        ) {
          this.toastr.error('Please select device pool');
        } else if (this.ListOfCapabilities.length <= 0) {
          this.toastr.error('Please select capabilities.');
        } else if (this.validateTestCaseAndDataSets() == true) {
          return;
        } else {
          if (this.jsonExecutiontbl.length > 0 && this.txtjobName != '') {
            $('#btnStartExecution').prop('disabled', true);
            let nowDate = new Date();
            this.addSelectedCapabilities();
            let ExecutionHeaderDetails = {};
            let varexecutionMode =
              this.executionMode == 'DeviceFarm' ? 'AWS' : this.executionMode;
            this.slaveOs =
              this.deviceType == Constvar.Headspin ||
              this.deviceType == Constvar.BrowserStack
                ? 'Linux'
                : this.slaveOs;
            this.selectedSlave =
              this.deviceType == Constvar.Headspin ||
              this.deviceType == Constvar.BrowserStack
                ? 'master'
                : this.selectedSlave;
            if (scheduleDetails != null && scheduleDetails != undefined) {
              this.scheduleFlag = true;
              // if (this.deviceType == 'DeviceFarm') {
              //   if (this.file != null) {
              //     await this.upload_appFile_schedule();
              //   } else {
              //   }
              // }
              ExecutionHeaderDetails = {
                appId:this.appId,
                active: scheduleDetails.active,
                endDate: scheduleDetails.endDate,
                executeTime: scheduleDetails.executeTime,
                frequency: scheduleDetails.frequency,
                repeatSchedule: scheduleDetails.repeatSchedule,
                daysOfWeek: scheduleDetails.daysOfWeek,
                dateOfMonth: scheduleDetails.dateOfMonth,
                jobName: this.txtjobName,
                runningLocation: 0,
                startDate: scheduleDetails.startDate,
                status: 1,
                userId: this.userId,
                nodeName: this.selectedSlave,
                nodeOs: this.slaveOs,
                environment: this.environment_type,
                mobileVersion: this.AppVersionName,
                appVersion: this.appversion,
                appName: this.AppName,
                executionMode: varexecutionMode,
                devicepoolArn: this.SelectedDevicePool_arn,
                mobileAppFileName: this.appArn,
                hsAnyDevice: this.isHSAnyDeviceSelected,
                hsOsVersion: this.isHSAnyDeviceSelected
                  ? this.hsAnyDeviceSelectedOS
                  : '0',
                languageId: this.isFromHistory
                  ? this.reExeEnvDetails.languageId
                  : this.languageId
              };
            } else {
              let updatedExeTime =
                this.datepipe.transform(nowDate, 'HH:mm') +
                ':' +
                Intl.DateTimeFormat().resolvedOptions().timeZone;
              ExecutionHeaderDetails = {
                appId: this.appId,
                active: true,
                endDate: this.datepipe.transform(nowDate, 'yyyy-MM-dd'),
                executeTime: updatedExeTime,
                frequency: 0,
                jobName: this.txtjobName,
                runningLocation: 0,
                startDate: this.datepipe.transform(nowDate, 'yyyy-MM-dd'),
                status: 0,
                userId: this.userId,
                nodeName: this.selectedSlave,
                nodeOs: this.slaveOs,
                environment: this.environment_type,
                mobileVersion: this.AppVersionName,
                executionMode: varexecutionMode,
                devicepoolArn: this.SelectedDevicePool_arn,
                mobileAppFileName: this.appArn,
                appName: this.AppName,
                hsAnyDevice: this.isHSAnyDeviceSelected,
                hsOsVersion: this.isHSAnyDeviceSelected
                  ? this.hsAnyDeviceSelectedOS
                  : '0',
                languageId: this.isFromHistory
                  ? this.reExeEnvDetails.languageId
                  : this.languageId,
                exeHeaderId: this.isFromHistory ? this.headerId_fromHistory : 0
              };
            }

            console.log('ExecutionHeaderDetails', ExecutionHeaderDetails);
            this.dataservice
              .addExecutionHeader(ExecutionHeaderDetails)
              .subscribe(
                (response: any) => {
                  console.log('ExecutionHeaderDetails', ExecutionHeaderDetails);
                  let doc = this;
                  if (response != null) {
                    let ExecutionHeaderID = response.executionHeaderId;
                    this.exeHeaderId = response.executionHeaderId;
                    let HistoryID = response.executionHistoryId;
                    this.historyId = response.executionHistoryId;
                    console.log('this.executionId....', this.exeHeaderId);
                    console.log('this.historyId.....', this.historyId);
                    this.localJobIdNum = HistoryID;
                    if (
                      ExecutionHeaderID != '' &&
                      ExecutionHeaderID != undefined
                    ) {
                      let ExecutionData = {
                        userId: this.userId,
                        exeHeaderId: ExecutionHeaderID,
                        exeHistoryId: HistoryID,
                        suitesView: this.jsonExecutiontbl,
                        languageId: this.isFromHistory
                          ? this.reExeEnvDetails.languageId
                          : this.languageId
                      };
                      console.log('ExecutionData');
                      console.log(ExecutionData);
                      this.dataservice
                        .addExecutionDetails(ExecutionData)
                        .subscribe(
                          async res_exec_details => {
                            console.log('res_exec_details', res_exec_details);
                            $('#btnStartExecution').prop('disabled', true);
                            $('#popProgress').modal('show');
                            if (!this.scheduleFlag) { 
                            this.dataservice
                              .startJobExecution(
                                ExecutionHeaderID,
                                HistoryID,
                                varexecutionMode,
                                this.selectedSlave
                              )
                              .subscribe(
                                async res => {
                                  console.log('pathssss', res);
                                  if (res != null) {
                                    this.jenkinJobsPath = res;
                                 
                                    // this.isFileUpload = false;
                                    let languageId = this.isFromHistory
                                      ? this.reExeEnvDetails.languageId
                                      : this.languageId;
                                    // if (!this.scheduleFlag) {
                                      // if (this.deviceType == 'DeviceFarm') {
                                        // $('#popProgress').modal('show');
                                        this.isFileUpload = true;
                                        // await this.UploadFiles(
                                        //   'App',
                                        //   HistoryID
                                        // );
                                      //   $('#popProgress').modal('hide');
                                      //   this.clearExecutionFields();
                                      // } else if (
                                      //   this.deviceType == Constvar.Local ||
                                      //   this.deviceType == Constvar.Headspin
                                      // ) {
                                        $('#popProgress').modal('hide');
                                        this.clearExecutionFields();
                                      // }
                                    // }
                                  }
                                },
                                error => {
                                  console.log(error);
                                  $('#btnStartExecution').prop(
                                    'disabled',
                                    false
                                  );
                                  $('#startExeBtnView').prop('disabled', false);
                                  $('#popProgress').modal('hide');
                                  this.clearExecutionFieldsWithError();
                                  this.dataservice.isDevicesLoading = false;
                                  this.toastr.error(
                                    this.dataservice.error_500_status
                                  );
                                  return;
                                }
                              );
                            }
                            else if(this.scheduleFlag)
                            {
                              $('#popProgress').modal('hide');
                              this.clearExecutionFields();
                            }
                          },
                          error => {
                            this.toastr.error(
                              this.dataservice.error_500_status
                            );
                            $('#btnStartExecution').prop('disabled', false);
                            $('#startExeBtnView').prop('disabled', false);
                            $('#popProgress').modal('hide');
                            this.clearExecutionFieldsWithError();
                          }
                        );
                      
                     
                    } else {
                      this.toastr.error(
                        'Unable to save execution header details'
                      );
                    }
                  }
                },
                error => {
                  console.log(error);
                  this.toastr.error('Something went Wrong ');
                  $('#btnStartExecution').prop('disabled', false);
                  $('#startExeBtnView').prop('disabled', false);
                  $('#popProgress').modal('hide');
                  this.clearExecutionFieldsWithError();
                  this.dataservice.isDevicesLoading = false;
                  this.toastr.error(this.dataservice.error_500_status);
                }
              );
          } else {
            // this.clear_addmessage("No record to save");
            this.toastr.error('No record to save');
          }
        }
      } else {
        this.txtjobName != '' &&
          this.AppVersionName != '' &&
          this.environment_type != '';

        let msg =
          this.txtjobName == ''
            ? 'Please enter test execution name'
            : this.AppVersionName == ''
            ? 'Please enter app version'
            : this.environment_type
            ? 'Please enter environment type'
            : '';
        this.toastr.warning(msg);
      }
    } else {
      if (this.txtjobName == '') {
        this.toastr.error('Enter Test execution name');
      } else {
        this.toastr.error(
          'Test execution name "' + this.txtjobName + '" already exists'
        );
      }
    }
  }

  addSelectedCapabilities() {
    console.log('list of capabilities array', this.ListOfCapabilities);
    if (this.isFromHistory) {
      this.df_devices_template_list = this.TemplateList_forReexe;
    }
    if (
      this.deviceType == 'DeviceFarm' ||
      this.deviceType == Constvar.Headspin || this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
    ) {
      this.ListOfCapabilities = this.df_devices_template_list;
      console.log('this.ListOfCapabilities.....2', this.ListOfCapabilities);
    }
    if (this.jsonExecutiontbl != null) {
      // if(this.isFromHistory == false)
      // {
      this.jsonExecutiontbl.forEach(element => {
        if (
          this.deviceType == Constvar.Headspin &&
          this.isHSAnyDeviceSelected
        ) {
          element.templateNameList = [
            { capabilityTemplateName: this.df_templateName }
          ];
        } else {
          element.templateNameList = this.ListOfCapabilities;
        }
      });

      // }
      // else if(this.isFromHistory == true){
      //   this.jsonExecutiontbl.forEach(element => {
      //     element.templateNameList = this.TemplateList_forReexe;
      //   });
      // }
    }
  }
  validateTestCaseAndDataSets(): boolean {
    let IsDs_Case_DataValid = false;
    let erroPageNumber = 0;
    let suitename = '';
    this.getcapabilityByProjectTypeId();
    if (this.jsonExecutiontbl != null && this.jsonExecutiontbl.length > 0) {
      for (let k = 0; k < this.jsonExecutiontbl.length; k++) {
        suitename = this.jsonExecutiontbl[k].testSuiteName;
        if (this.jsonExecutiontbl[k].testCases.length == 0) {
          IsDs_Case_DataValid = true;
          erroPageNumber = k + 1;
          break;
        }
        for (let j = 0; j < this.jsonExecutiontbl[k].testCases.length; j++) {
          let caseId = this.jsonExecutiontbl[k].testCases[j].testCaseId;
          let dsList = this.jsonExecutiontbl[k].testCases[j].dataSetNameList;
          if (dsList == null || dsList.length <= 0) {
            IsDs_Case_DataValid = true;
            erroPageNumber = k + 1;

            break;
          } else if (caseId == '' || caseId == undefined) {
            IsDs_Case_DataValid = true;
            break;
          }
        }
        if (IsDs_Case_DataValid == true) {
          break;
        }
      }
    }
    if (IsDs_Case_DataValid == true) {
      this.ValidSteps[0]['step2'] = false;
      this.ValidSteps[0]['step3'] = false;
      this.ValidSteps[0]['step4'] = false;
      this.toastr.error(
        'Please select data set and test case of suite :-  ' +
          suitename +
          '  On page number- ' +
          erroPageNumber
      );
    } else {
      this.ValidSteps[0]['step2'] = true;
      this.ValidSteps[0]['step3'] = true;
    }
    return IsDs_Case_DataValid;
  }

  clearExecutionFields() {
    this.executionCancel(0);
    this.executionMode = '';
    this.txtjobName = '';
    this.file_arn = '';
    this.jsonExecutiontbl = [];
    this.serverDevices = [];
    this.dataservice.serverDevices = [];
    this.IsjobName_error = '';
    this.file = null;
    this.selectedAppFileName = '';
    this.deviceType = 'Local';
    if(!this.isfromExecution){
      this.getAllProjectType();
    }
    this.clear_case_list();
    this.clearSelectedSuite('');
    this.isArnTemplate = false;
    this.ddldevicepool = 0;
    this.jenkinsJobIdNum = 0;
    this.buildJobInfo - 0;
    this.jenkinsNode = 0;

    $('#scheduleModal').modal('hide');
    this.toastr.success('Test execution details saved successfully...');
    this.getshedulelink();
  }

  clearExecutionFieldsWithError() {
    this.executionCancel(0);
    this.txtjobName = '';
    this.file_arn = '';
    this.jsonExecutiontbl = [];
    this.IsjobName_error = '';
    this.file = null;
    this.selectedAppFileName = '';
    this.deviceType = 'Local';
    this.getAllProjectType();
    this.clear_case_list();
    this.clearSelectedSuite('');
    this.isArnTemplate = false;
    this.ddldevicepool = 0;
    $('#scheduleModal').modal('hide');
    this.getshedulelinkWithError();
  }
  // Export Test suite Data and Element Data Object
  exportData(ExecutionHeaderID) {
    this.dataservice.exportTestSuiteData(ExecutionHeaderID).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/xls' });
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'file.xls';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      error => {
        console.log(error);
        this.toastr.error('Something went Wrong ');
      }
    );

    this.dataservice.exportElementsDataAsObject(ExecutionHeaderID).subscribe(
      (res: any) => {
        const blob = new Blob([res], { type: 'application/xls' });
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'file.xls';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      error => {
        console.log(error);
        this.toastr.error('Something went Wrong ');
      }
    );
  }

  scheduleModal() {
    if (this.txtjobName != '') {
      $('#scheduleModal').modal('show');
    } else {
      this.toastr.error('Please enter test execution name');
    }
  }

  //---selectAll Devices CheckList --Akash
  selectHeadspinDevice(event, device) {
    if (event.target.checked) {
      if (
        this.selectedHeadspinDevices.length != 0 &&
        this.selectedHeadspinDevices.length < 3
      ) {
        const resultArr = this.serverDevices.filter(
          arrObj => arrObj.deviceId === device.deviceId
        );
        let appiumurl = this.headspinDevicesAppiumUrls.filter(
          arrObj => arrObj.capabilities.udid == device.deviceId
        );
        appiumurl[0].driver_url = appiumurl[0].driver_url.replace(
          '{api_token}',
          environment.urlConfig.headspinAPIToken
        );
        resultArr[0] = Object.assign(resultArr[0], {
          AppiumUrl: appiumurl[0].driver_url
        });
        this.selectedHeadspinDevices.push(resultArr[0]);
      } else if (this.selectedHeadspinDevices.length >= 3) {
        this.toastr.warning('Max 3 device you can select');
        $('#' + device.deviceId).prop('checked', false);
        $('#' + device.deviceId).prop('value', false);
      } else {
        let appiumurl = this.headspinDevicesAppiumUrls.filter(
          arrObj => arrObj.capabilities.udid == device.deviceId
        );
        appiumurl[0].driver_url = appiumurl[0].driver_url.replace(
          '{api_token}',
          environment.urlConfig.headspinAPIToken
        );
        device = Object.assign(device, { AppiumUrl: appiumurl[0].driver_url });
        this.selectedHeadspinDevices.push(device);
      }
    } else {
      const resultArr = this.selectedHeadspinDevices.filter(
        arrObj => arrObj.deviceId != device.deviceId
      );
      this.selectedHeadspinDevices = resultArr;
    }
  }
  checkAnyDevice(event) {
    if (event.target.checked) {
      this.isHSAnyDeviceSelected = true;
      if (
        this.selectedHeadspinDevices.length != 0 &&
        this.df_templateName != ''
      ) {
        this.selectedHeadspinDevices = [];
        this.df_templateName = '';
        this.serverDevices[0].TemplateName = '';
      }
    } else {
      this.isHSAnyDeviceSelected = false;
    }
  }
  SelectCheckbox(event, ControlName, check_index) {
    let selectedId;
    this.serverDevices =
      this.isMobile == true
        ? this.dataservice.serverDevices
        : this.WebCapabilities;
    console.log(ControlName);
    var index = this.serverDevices.findIndex(function(el) {
      selectedId = el.TemplateName;
      return el.id == event.target.id;
    });
    if (event.target.value == 'true') {
      $('#lblError' + check_index).removeClass('required-template-out');
      $('#lblError' + check_index).addClass('required-template-in');
      $('#' + ControlName).prop('disabled', true);
      $('#jobNameName' + event.target.id).val('');
      selectedId = '';
      this.serverDevices[index]['selected'] = false;
      $('#' + event.target.id).prop('checked', false);
      $('#' + event.target.id).prop('value', false);
    } else {
      $('#lblError' + check_index).removeClass('required-template-in');
      $('#lblError' + check_index).addClass('required-template-out');
      $('#' + ControlName).prop('disabled', false);
      this.serverDevices[index]['selected'] = true;
      $('#' + event.target.id).prop('checked', true);
      $('#' + event.target.id).prop('value', true);
    }
    if (selectedId != '') {
      var lenChecked = this.serverDevices.filter(item => item.selected == true)
        .length;
      var severLen = this.serverDevices.length;
      if (severLen == lenChecked) {
        $('#selectall').prop('checked', true);
      } else {
        $('#selectall').prop('checked', false);
      }
    }
  }

  // submit details of selected devices from list to DB

  async submitSelectedDevices() {
     
    this.ListOfCapabilities = [];
    this.IsTemplateNameExist = false;
    let TemplateName;
    this.selectedDevices = [];
    this.selectedDeviceNames = [];

    // on click of done if status code of 500
    if (
      this.dataservice.error_500_status != null &&
      this.dataservice.error_500_status != undefined &&
      this.dataservice.error_500_status != ''
    ) {
      return this.toastr.error(this.dataservice.error_500_status);
    }

    this.selectedDevices = this.serverDevices.filter(
      item => item.selected == true
    );


    if (this.active_step == 3) {
      this.isPreviousArray = [];
      this.isPreviousArray = this.selectedDevices;
      console.log('isPreviousArray on step 3------==>', this.isPreviousArray);
    }
    let IsTemplateRequired = false;
    if (this.deviceType == 'Local') {
      var selectedDevicesArray = this.selectedDevices.reduce((a, b) => {
        a[b.userId] = a[b.userId] || [];
        return a;
      }, {});

      console.log('slected devices array---->', selectedDevicesArray);

      if (!this.isErrorExist) {
        //is there is no error
        for (let k = 0; k < this.serverDevices.length; k++) {
          if (this.serverDevices[k]['selected'] == false) {
            $('#lblError' + k).removeClass('required-template-out');
            $('#lblError' + k).addClass('required-template-in');
          } else {
            $('#lblError' + k).removeClass('required-template-in');
            $('#lblError' + k).addClass('required-template-out');
          }
        }

        for (let m = 0; m < this.selectedDevices.length; m++) {
          let var_templateName = this.selectedDevices[m].TemplateName;
          TemplateName = var_templateName;
          if (var_templateName != '' && var_templateName != undefined) {
            TemplateName = var_templateName;
            IsTemplateRequired = false;
            this.var_manual_templatename_Error = '';
            $('#lblError' + m).removeClass('required-template-out');
            $('#lblError' + m).addClass('required-template-in');
          } else {
            if (IsTemplateRequired == false) {
              IsTemplateRequired = true;
              this.selectedDevices[m].TemplateName = '';
              this.var_manual_templatename_Error = 'Please enter template name';
              $(
                '#lblErrorMsg' + m
              ).innerText = this.var_manual_templatename_Error;
              return;
            }
          }
        }
      } else {
        return;
      }
      if (
        IsTemplateRequired == false &&
        this.var_manual_templatename_Error == ''
      ) {
        for (let k = 0; k < this.selectedDevices.length; k++) {
          let mod = this.selectedDevices[k].userId;

          console.log('mod========', mod);
          //Changes by mustaid to push capibility template name,os,device type,platform
          let keys = this.selectedDevices[k].key;

          let arrBl = this.brandList.filter(bl => bl.brandId == this.brandId);
          let brandName = '';
          if (arrBl[0] != null && arrBl[0] != undefined) {
            brandName = arrBl[0].brandName.toString().trim();
          }
          let platformName = '';
          let arrPl;
          if (this.platformId != 0 && this.platformId != undefined) {
            arrPl = this.platformList.filter(
              pl => pl.platformId == this.platformId
            );
            platformName = arrPl[0].platformName.toString().trim();
          }

          let varwebplatform = 'Chrome';
          if (platformName == 'Mozilla Firefox') {
            varwebplatform = 'Firefox';
          }
          let capabilityIdValuesViews: any = [];
          if (this.isMobile == true) {
            capabilityIdValuesViews.push(
              {
                capabilityName: 'deviceName',
                capabilityValue: this.selectedDevices[k].name.toString().trim(),
                platformId: this.isFromHistory
                  ? this.reExeEnvDetails.platformId
                  : this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'osVersion',
                capabilityValue: this.selectedDevices[k].os,
                platformId: this.isFromHistory
                  ? this.reExeEnvDetails.platformId
                  : this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'platform',
                capabilityValue: this.selectedDevices[k].platform,
                platformId: this.isFromHistory
                  ? this.reExeEnvDetails.platformId
                  : this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'udid',
                capabilityValue: this.selectedDevices[k].udid,
                platformId: this.isFromHistory
                  ? this.reExeEnvDetails.platformId
                  : this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'deviceType',
                capabilityValue: this.selectedDevices[k].deviceType,
                platformId: this.isFromHistory
                  ? this.reExeEnvDetails.platformId
                  : this.varplatformId,
                brandId: this.brandId
              }
            );
          } else {
            capabilityIdValuesViews.push(
              {
                capabilityName: 'deviceName',
                capabilityValue: this.selectedDevices[k].name.toString().trim(),
                platformId: this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'VERSION',
                capabilityValue: this.selectedDevices[k].os,
                platformId: this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'platform',
                capabilityValue: varwebplatform,
                platformId: this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'osVersion',
                capabilityValue: '8',
                platformId: this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'mobileAppPath',
                capabilityValue: 'D:WPchromedriver.exe',
                platformId: this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'brand',
                capabilityValue: brandName,
                platformId: this.varplatformId,
                brandId: this.brandId
              },
              {
                capabilityName: 'udid',
                capabilityValue: '189ac456e',
                platformId: this.varplatformId,
                brandId: this.brandId
              }
            );
          }

          selectedDevicesArray[mod].push({
            capabilityIdValuesViews: capabilityIdValuesViews,
            name: this.selectedDevices[k].TemplateName //name
          });
          //this.capabilityTemplate used to get template name from selected device and to send to AddTemplateCapability
          this.capabilityTemplate = this.selectedDevices[k].TemplateName; //name
          console.log('capability Name------->', this.capabilityTemplate);
          let capabilityDetails = this.ListOfCapabilities.filter(
            capability =>
              capability.capabilityTemplateName == this.capabilityTemplate
          );
          if (capabilityDetails.length <= 0) {
            this.ListOfCapabilities.push({
              capabilityTemplateName: this.capabilityTemplate
            });
          }
        }

        let keys = Object.keys(selectedDevicesArray);
        this.deviceJson = [];
        for (let l = 0; l < keys.length; l++) {
          this.TestCase = keys[l];

          let arrtemplates = [];
          for (let m = 0; m < selectedDevicesArray[this.TestCase].length; m++) {
            arrtemplates.push({
              capabilityIdValuesViews:
                selectedDevicesArray[this.TestCase][m].capabilityIdValuesViews,
              templateName: selectedDevicesArray[this.TestCase][m].name //name
                .toString()
                .trim(),
              usrTemplateName: selectedDevicesArray[this.TestCase][m].name //name
                .toString()
                .trim()
            });
          }
          this.deviceJson.push({
            userId: this.userId,
            templates: arrtemplates,
            projectType: this.projectTypeName,
            env: this.environment_type
          });
        }
        console.log('this.deviceJson', this.deviceJson);

        if (this.deviceJson.length > 0) {
          this.dataservice.addDeviceCapability(this.deviceJson).subscribe(
            resp => {
              console.log('this.deviceJson resp---------->', resp);
              if (resp != null) {
                this.toastr.success(
                  'Capability details saved successfully...'
                );
                //   $('#SelectDeviceTemplateModal').modal('hide');
                //  $('#createTemplateModal').modal('hide');
                this.getAllCapabilitytemplate();
                //check mandatory capability
                this.checkMandatoryCapabilities('', false);
              }
              setTimeout(() => {
                this.deviceJson = [];
                $('#SelectDeviceTemplateModal').modal('hide');
                this.showPopup = false;
                this.checkMandatoryCapabilities('', false);
              }, 2500);
            },
            error => {
              this.toastr.error(this.dataservice.error_500_status);
            }
          );
        } else {
          this.toastr.error('Please select device...');
        }
      } else {
      }
    } else if (
      this.deviceType == 'DeviceFarm' ||
      this.deviceType == Constvar.Headspin ||
      this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack'
    ) {
      if (
        this.selectedHeadspinDevices.length < 1 &&
        (this.deviceType == Constvar.Headspin  || this.deviceType=='LocalBrowserStack') &&
        !this.isHSAnyDeviceSelected
      ) {
        this.toastr.error('Please select atleast one device');
        return;
      }
      if (
        this.selectedHeadspinDevices.length < 1 &&
        (this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack') &&
        !this.isHSAnyDeviceSelected
      ) {
        this.toastr.error('Please select atleast one device');
        return;
      }
      // if (
      //   this.deviceType == Constvar.Headspin &&
      //   this.isHSAnyDeviceSelected &&
      //   this.hsAnyDeviceSelectedOS.length == 0
      // ) {
        // this.toastr.error('Please select atleast OS version');
      //   return;
      // }
      this.df_devices_template_list = [];
      if ((this.deviceType == Constvar.Headspin  || this.deviceType==Constvar.BrowserStack )&& !this.isHSAnyDeviceSelected) {
        this.serverDevices = this.selectedHeadspinDevices;
      }
      console.log('df_templateName', this.df_templateName);
       
      if (this.df_templateName != '' && this.df_templateName != undefined) {
        if (!this.isErrorExist) {
          if (this.serverDevices != null) {
            this.isFromStartExe = false;
            let ISExist = await this.CheckISCapbilityTemplateExist(
              this.df_templateName
            );
            if (ISExist == true) {
              console.log('ISEXIST', ISExist);
              if (this.isHSAnyDeviceSelected) {
                this.df_devices_template_list.push({
                  capabilityTemplateName: this.df_templateName
                });
              }
              for (let i = 0; i < this.serverDevices.length; i++) {
                let template_Name;
                let deviceName = this.serverDevices[i].name;
                // let osVersion =  this.serverDevices[i].os;
                // console.log('osVersion..DF' , osVersion);
                if (this.deviceType == Constvar.Headspin) {
                  if (this.serverDevices[i].deviceName != undefined) {
                    template_Name =
                      this.serverDevices[i].deviceName.toString().trim() +
                      '_' +
                      this.df_templateName;
                  }
                }else if (this.deviceType == Constvar.BrowserStack || this.deviceType=='LocalBrowserStack') {
                  if (this.serverDevices[i].device != undefined) {
                    template_Name = this.serverDevices[i].device+ '_'+ this.df_templateName;
                  }
                } else {
                  template_Name =
                    deviceName +
                    '_' +
                    this.devicePool +
                    '_' +
                    this.df_templateName;
                }
                if (!this.isHSAnyDeviceSelected) {
                  this.df_devices_template_list.push({
                    capabilityTemplateName: template_Name
                  });
                }
              }
              console.log(
                'df_template list while existing tempalte',
                this.df_devices_template_list
              );
              this.templateListData = [];
              this.replacedTemplateList = [];
              //when user comes from re execution with changes and select other device pool template
              if (this.isFromHistory) {
                this.ishsIsTrue = false;
                this.TemplateList_forReexe = this.df_devices_template_list;
                console.log(
                  'this.TemplateList_forReexe====>',
                  this.TemplateList_forReexe
                );
              }
              this.addTemplateCapability_arn(this.df_templateName);
            } else {
              this.templateListData = [];
              this.replacedTemplateList = [];
              $('#SelectDeviceTemplateModal').modal('hide');
              $('#popProgress').modal('show');
              await this.saveDevicePoolCapability(
                this.df_templateName,
                this.SelectedDevicePool_arn,
                this.serverDevices
              );
            }
          }
        } else {
          return;
        }
      } else {
        this.var_manual_templatename_Error = 'Please enter template name';
        return;
      }
    } else {
      this.df_devices_template_list = [];
      if (this.serverDevices != null) {
        this.isFromStartExe = false;
        let ISExist = await this.CheckISCapbilityTemplateExist(
          this.df_templateName
        );
        if (ISExist == true) {
          console.log('ISEXIST', ISExist);
          if (this.isBSDeviceselected) {
            this.df_devices_template_list.push({
              capabilityTemplateName: this.df_templateName
            });
          }
          for (let i = 0; i < this.Devices.length; i++) {
            let template_Name;
            let deviceName = this.Devices[i].device;
            // let osVersion =  this.serverDevices[i].os;
            // console.log('osVersion..DF' , osVersion);
            if (this.deviceType == Constvar.BrowserStack) {
              if (this.Devices[i].device != undefined) {
                template_Name = this.df_templateName;
              }
            } else {
              template_Name =
                deviceName + '_' + this.devicePool + '_' + this.df_templateName;
            }
            if (!this.isHSAnyDeviceSelected) {
              this.df_devices_template_list.push({
                capabilityTemplateName: template_Name
              });
            }
          }
          console.log(
            'df_template list while existing tempalte',
            this.df_devices_template_list
          );
          this.templateListData = [];
          this.replacedTemplateList = [];
          //when user comes from re execution with changes and select other device pool template
          if (this.isFromHistory) {
            this.ishsIsTrue = false;
            this.TemplateList_forReexe = this.df_devices_template_list;
            console.log(
              'this.TemplateList_forReexe====>',
              this.TemplateList_forReexe
            );
          }
          this.addTemplateCapability_arn(this.df_templateName);
        } else {
          this.templateListData = [];
          this.replacedTemplateList = [];
          $('#SelectDeviceTemplateModal').modal('hide');
          $('#popProgress').modal('show');
          await this.saveDevicePoolCapability(
            this.df_templateName,
            this.SelectedDevicePool_arn,
            this.serverDevices
          );
        }
      }
    }
  }

  submitSelectedBrowsers() {
    this.selectedDevices = [];
    this.ListOfCapabilities = [];
    let SelectedOp = [];
    this.selectedDevices = this.serverDevices.filter(
      item => item.selected == true
    );

    var selectedDevicesArray = this.selectedDevices.reduce((a, b) => {
      a[b.userId] = a[b.userId] || [];
      return a;
    }, {});

    for (let k = 0; k < this.selectedDevices.length; k++) {
      let mod = this.selectedDevices[k].userId;

      //Changes by mustaid to push capibility template name,os,device type,platform
      let keys = this.selectedDevices[k].key;

      let arrBl = this.brandList.filter(bl => bl.brandId == this.brandId);
      let brandName = '';
      if (arrBl[0] != null && arrBl[0] != undefined) {
        brandName = arrBl[0].brandName.toString().trim();
      }

      let platformName = '';
      let arrPl;

      if (this.platformId != 0 && this.platformId != undefined) {
        arrPl = this.platformList.filter(
          pl => pl.platformId == this.platformId
        );

        platformName = arrPl[0].platformName.toString().trim();
      }

      let capabilityIdValuesViews: any = [];

      capabilityIdValuesViews.push(
        {
          capabilityName: 'PLATFORM_NAME',
          capabilityValue: this.selectedDevices[k].name.toString().trim()
        },
        {
          capabilityName: 'BROWSER_NAME',
          capabilityValue: this.selectedDevices[k].os
        },
        {
          capabilityName: 'VERSION',
          capabilityValue: this.selectedDevices[k].platform
        }
      );
      selectedDevicesArray[mod].push({
        capabilityIdValuesViews: capabilityIdValuesViews,
        name: this.selectedDevices[k].name
      });

      this.capabilityTemplate = this.selectedDevices[k].name;
      console.log('capability Name------->', this.capabilityTemplate);
      this.ListOfCapabilities.push({
        capabilityTemplateName: this.capabilityTemplate
      });
    }

    let keys = Object.keys(selectedDevicesArray);
    this.deviceJson = [];
    for (let l = 0; l < keys.length; l++) {
      this.TestCase = keys[l];

      let arrtemplates = [];
      for (let m = 0; m < selectedDevicesArray[this.TestCase].length; m++) {
        arrtemplates.push({
          capabilityIdValuesViews:
            selectedDevicesArray[this.TestCase][m].capabilityIdValuesViews,
          templateName: selectedDevicesArray[this.TestCase][m].name
            .toString()
            .trim(),
          usrTemplateName: selectedDevicesArray[this.TestCase][m].name
            .toString()
            .trim()
        });
      }
      console.log('arrtemplates');
      console.log(arrtemplates);
      this.deviceJson.push({
        userId: this.userId,
        templates: arrtemplates,
        projectType: this.projectTypeName
      });
    }

    if (this.deviceJson.length > 0) {
      this.dataservice.addDeviceCapability(this.deviceJson).subscribe(
        resp => {
          console.log('this.deviceJson resp---------->', resp);
          if (resp != null) {
            this.toastr.success(
              'Capability details saved successfully...'
            );
            this.getAllCapabilitytemplate();
          }
          setTimeout(() => {
            this.deviceJson = [];
            $('#SelectDeviceTemplateModal').modal('hide');
            this.showPopup = false;
            this.checkMandatoryCapabilities('', false);
          }, 2500);
        },
        error => {
          console.log(error);
          this.toastr.error('Something went Wrong ');
        }
      );
    } else {
      this.toastr.error('Please select Browser...');
    }
  }
  selectDeviceMsg(message) {
    this.selectDevice_error = message;
    setTimeout(() => {
      this.selectDevice_error = '';
    }, 2500);
  }

  AllSelectedCheckbox(event) {
    const checked = event.target.checked;
    this.serverDevices.forEach(item => (item.selected = checked));
    this.ref.detectChanges();
  }
  //=========navigate schedule page.===================
  getshedulelink() {
    if (this.scheduleFlag) {
      this.toastr.info(
        'Schedule has been successfully created.',
        'Success'
      );
      $('#popProgress').modal('hide');
      $('.modal-backdrop').remove();
      this.router.navigate(['/execution/scheduled']);
    } else {
      this.toastr.info('Execution has started.', 'Success');
      $('#popProgress').modal('hide');
      $('.modal-backdrop').remove();
      this.router.navigate(['/execution/inprogress']);
    }
    $('#btnStartExecution').prop('disabled', true);
    this.ValidSteps = [
      { step0: true, step1: false, step2: false, step3: false, step4: false }
    ];
  }

  getshedulelinkWithError() {
    this.toastr.info('Unable to schedule test.', 'warning');
    $('#popProgress').modal('hide');
    $('.modal-backdrop').remove();
    this.router.navigate(['/execution/inprogress']);
    $('#btnStartExecution').prop('disabled', true);
    this.ValidSteps = [
      { step0: true, step1: false, step2: false, step3: false, step4: false }
    ];
  }

  async checkMandatoryCapabilities(templateName, isfromSelectTemplateName) {
     
    if (this.allCapabilityList != null) {
      if (this.deviceType == 'DeviceFarm') {
        this.arrMandatoryCapabilities = this.allCapabilityList.filter(
          cap =>
            cap.mandatory == true &&
            cap.capabilityName != 'arn' &&
            cap.platformName === this.platform &&
            cap.capabilityName != 'appiumServerURL' &&
            cap.capabilityName != 'mobileAppPath' &&
            cap.capabilityName != 'appId' &&
            cap.capabilityName != 'bundleId'
        );
        console.log(
          'this.arrMandatoryCapabilities_AWS',
          this.arrMandatoryCapabilities
        );
      } else if (
        this.deviceType == Constvar.Headspin ||
        this.deviceType == Constvar.BrowserStack
      ) {
        this.arrMandatoryCapabilities = this.allCapabilityList.filter(
          cap =>
            cap.mandatory == true &&
            cap.capabilityName != 'arn' &&
            cap.platformName === this.platform &&
            cap.capabilityName != 'appId' &&
            cap.capabilityName != 'bundleId'
        );
      } else if (this.deviceType == 'Local') {
        this.arrMandatoryCapabilities = this.allCapabilityList.filter(
          cap =>
            cap.mandatory == true &&
            cap.capabilityName != 'arn' &&
            cap.platformName === this.platform &&
            cap.capabilityName != 'appId' &&
            cap.capabilityName != 'bundleId'
        );
      }

      console.log(
        'arrMandatoryCapabilities......',
        this.arrMandatoryCapabilities
      );
      let tempListOfCapabilities = [];
       
      if (templateName != '') {
        if (
          this.deviceType == Constvar.Headspin ||
          this.deviceType == Constvar.DeviceFarm ||
          this.deviceType == Constvar.BrowserStack
        ) {
          tempListOfCapabilities = this.ListOfCapabilities.filter(
            s => s.capabilityTemplateName == templateName
          );
        } else {
          tempListOfCapabilities = this.ListOfCapabilities.filter(
            s => s.capabilityTemplateName == templateName
          );
        }
      } else {
        tempListOfCapabilities = this.ListOfCapabilities;
      }

      for (let i = 0; i <= tempListOfCapabilities.length - 1; i++) {
        let capTemp = tempListOfCapabilities[i];

        this.capDevice = [];
        await this.getTemplateByName(
          capTemp.capabilityTemplateName,
          true,
          false
        );
         
        this.counts = 0;
        let strMisingCapabilities = '';
        let capabilitiesLength: any;
        this.arrMandatoryCapabilities.forEach(mandatoryCap => {
          if (
            this.executionMode == Constvar.Headspin ||
            this.executionMode == Constvar.BrowserStack || this.executionMode=='LocalBrowserStack'
          ) {
            capabilitiesLength = this.capDevice[0].capabilityIdValuesViews.filter(
              el => el.capabilityName != mandatoryCap.capabilityName
            ).length;
          } else {
            capabilitiesLength = this.capDevice.capabilityIdValuesViews.filter(
              el => el.capabilityName != mandatoryCap.capabilityName
            ).length;
          }

          let capValuesViews =
            this.executionMode == Constvar.Headspin ||
            this.executionMode == Constvar.BrowserStack || this.executionMode=='LocalBrowserStack'
              ? this.capDevice[0].capabilityIdValuesViews
              : this.capDevice.capabilityIdValuesViews;
          if (capValuesViews.length == capabilitiesLength) {
            this.counts = this.counts + 1;
            console.log('counts counts', this.counts);
            if (this.deviceType == 'DeviceFarm') {
              if (mandatoryCap.capabilityName != 'appiumServerURL') {
                if (mandatoryCap.capabilityName != 'mobileAppPath') {
                  strMisingCapabilities =
                    mandatoryCap.capabilityName + ', ' + strMisingCapabilities;
                }
              }
            } else {
              strMisingCapabilities =
                mandatoryCap.capabilityName + ', ' + strMisingCapabilities;
            }
          }
        });

        if (
          this.arrMandatoryCapabilities.length != this.counts &&
          this.counts != 0
        ) {
          this.msg_capTemplate =
            'Add missing capabilities ' +
            strMisingCapabilities
              .toString()
              .substr(0, strMisingCapabilities.toString().length - 2);
          this.isValidCap = false;
          $('#popProgress').modal('hide');
          $('#createTemplateModal').modal('show');
          // this.ref.detectChanges();
          return;
        } else {
          if (this.showPopup != true) {
            this.isValidCap = true;
            if (isfromSelectTemplateName) {
              $('#createTemplateModal').modal('show');
            } else {
              $('#createTemplateModal').modal('hide');
            }
          }
        }
      }
    }
  }

  async getJenkinsSlave() {
     
    this.arrJenkinsSlave = [];
    this.selectedSlave = '';
    //Call for getNodeList API for ThirdParty
    this.dataservice.getNodeList().subscribe(data=>{
      this.arrJenkinsSlave=data;
      console.log("Get Node List:",this.arrJenkinsSlave);
    }, error => {
      console.log(error.message);
     });
  }

  getSlaveName(event) {
    this.selectedSlave = event.target.value;
    console.log("slave Name:",this.selectedSlave);
    this.machineName =
      event.target.options[event.target.options.selectedIndex].text;
      console.log("machine name:",this.machineName);
    let slaveIndex = this.arrJenkinsSlave.findIndex(
      slave => slave.displayName == this.selectedSlave
    );
    console.log("slaveIndex:",slaveIndex);
    // this.slaveOs = this.arrJenkinsSlave[slaveIndex].monitorData[
    //   'hudson.node_monitors.ArchitectureMonitor'
    // ];
    this.slaveOs=this.arrJenkinsSlave[slaveIndex].os;
     console.log("slave os:",this.slaveOs);
    if (this.slaveOs.toString().indexOf('Windows') > -1) {
      this.slaveOs = 'Windows';
    } else if (this.slaveOs.toString().indexOf('Mac') > -1) {
      this.slaveOs = 'Mac';
    }

  }
 



  async next() {
    var _this3 = this;
    switch (this.active_step) {
      case 0: {
        if (this.isFromHistory == false) {
          await this.isExeNameDuplicate();
        }

        this.validateFirstStep();
        break;
      }
      case 1: {
        this.validateSecondStep();
        break;
      }
      case 2: {
        this.validateThirdStep();
        break;
      }
      case 3: {
        this.validateFourthStep();
        break;
      }
      default: {
        break;
      }
    }
    if (!this.isInValid) {
      this.stepper.next();

      this.active_step = this.stepper['_currentIndex'] + 1;
      if (this.active_step == 2) {
        await this.getTestcasesOfAllSuite();
        await this.getDatasetsAllSuite();
        await this.checkRecentSelectedCasesAndDataset();
        await this.paginationGenerate(this.selectedSuiteList.length);
        this.activeIndexPaginition = this.selectedSuiteList.length - 1;
        this.selectedtSuiteStep_stepper = this.activeIndexPaginition;
      }
      this.highlightRecentsteps(this.active_step);
    }
  }

  previous() {
    this.stepper.previous();
    this.active_step = this.stepper['_currentIndex'] - 1;
    if (this.active_step == 2) {
      this.handlePagination(this.jsonExecutiontbl.length - 1);
    }
    this.highlightRecentsteps(this.active_step);
  }

  async GetCurrentStep(stepno) {
    if (stepno == 0 && this.isFromHistory == false) {
      await this.isExeNameDuplicate();
    }

    let step_no = stepno == 0 ? stepno : stepno - 1;
    switch (step_no) {
      case 0: {
        this.validateFirstStep();
        break;
      }
      case 1: {
        this.validateSecondStep();
        break;
      }
      case 2: {
        this.validateThirdStep();
        break;
      }
      case 3: {
        this.validateFourthStep();
        break;
      }
      default: {
        break;
      }
    }
    if (this.isInValid) {
      setTimeout(() => {
        this.stepper['_currentIndex'] = step_no;
        this.stepper['selectedIndex'] = step_no;
        this.active_step = step_no;
        this.stepper.to(step_no);
      }, 1000);
    }
    this.active_step = stepno;
    this.highlightRecentsteps(stepno);
    if (this.active_step == 2 && this.testCaseList != null) {
    }
    if (this.active_step == 2) {
      this.handlePagination(this.jsonExecutiontbl.length - 1);
    }
  }

  highlightRecentsteps(stepno) {
    for (let k = 0; k < 5; k++) {
      $('#step_no_' + k).removeClass('stepper_active');
    }
    for (let m = 0; m < stepno; m++) {
      $('#step_no_' + m).addClass('stepper_active');
    }
  }

  validateFirstStep() {
    if (this.AppVersionName.trim() == '') {
      this.isInValid = true;
      this.ValidSteps[0]['step0'] = false;
      this.ValidSteps[0]['step1'] = false;
      this.ValidSteps[0]['step2'] = false;
      this.ValidSteps[0]['step3'] = false;
      this.ValidSteps[0]['step4'] = false;

      this.toastr.error('Please enter the app version');
    } else if (this.txtjobName.trim() == '') {
      this.isInValid = true;
      this.ValidSteps[0]['step0'] = false;
      this.ValidSteps[0]['step1'] = false;
      this.ValidSteps[0]['step2'] = false;
      this.ValidSteps[0]['step3'] = false;
      this.ValidSteps[0]['step4'] = false;
      this.toastr.error('Please enter the test execution name');
    } else if (this.inValid == true) {
      this.isInValid = true;
      this.ValidSteps[0]['step0'] = false;
      this.ValidSteps[0]['step1'] = false;
      this.ValidSteps[0]['step2'] = false;
      this.ValidSteps[0]['step3'] = false;
      this.ValidSteps[0]['step4'] = false;
      this.toastr.error('Please enter the test execution name');
    } else {
      this.ValidSteps[0]['step0'] = true;
      this.ValidSteps[0]['step1'] = true;
      this.isInValid = false;
    }
  }

  async validateSecondStep() {
     
    if (this.projectTypeName == 'API' && this.deviceType == 'DeviceFarm') {
      this.isInValid = true;
      this.ValidSteps[0]['step1'] = false;
      this.ValidSteps[0]['step2'] = false;
      this.ValidSteps[0]['step3'] = false;
      this.ValidSteps[0]['step4'] = false;
      return this.toastr.error(
        'You cannot select API project type for Device Farm'
      );
    }
    if (this.jsonExecutiontbl.length == 0) {
      this.isInValid = true;
      this.toastr.error('Please select test suite');
      this.ValidSteps[0]['step1'] = false;
      this.ValidSteps[0]['step2'] = false;
      this.ValidSteps[0]['step3'] = false;
      this.ValidSteps[0]['step4'] = false;
    } else if (this.languageId == 0 && this.isFromHistory == false) {
      this.isInValid = true;
      this.toastr.error('Please select Language');
      this.ValidSteps[0]['step1'] = false;
      this.ValidSteps[0]['step2'] = false;
      this.ValidSteps[0]['step3'] = false;
      this.ValidSteps[0]['step4'] = false;
    } else {
      this.isInValid = false;
      this.ValidSteps[0]['step1'] = true;
      this.ValidSteps[0]['step2'] = true;

      // if (this.deviceType == Constvar.DeviceFarm) {
      //   await this.filterFilesOnPlatformId();
      // }

      // if (this.deviceType == Constvar.Headspin) {
      //   await this.getAllUploadedHeadspinFiles(false);
      // }
    }
  }

  async validateThirdStep() {
    // await this.getAllCapability(this.platformId);
    this.TotalSelectedTestCases = this.jsonExecutiontbl[0].testCases.length;
    console.log(
      'this.TotalSelectedTestCases.....',
      this.TotalSelectedTestCases
    );

    if (this.isFromHistory) {
      this.ListOfCapabilities = this.UserTemplateName_forReexe;
      console.log(
        ' = this.ListOfCapabilities_from history',
        this.ListOfCapabilities
      );
    }

    if (this.jsonExecutiontbl.length != 0) {
      this.isInValid = this.validateTestCaseAndDataSets();
    }
    this.dataservice.isDevicesLoading = false;

    if (this.deviceType != Constvar.Local) {
      // this.updatedtokenUploadfiles(false);
      await this.getUploadedApps(false);
      if(this.deviceType ==Constvar.DeviceFarm){
        await this.listDevicePools();
      }
    }
    else{
          await this.getJenkinsSlave();
    }

    // if (this.deviceType == Constvar.Headspin) {
    //    this.getAllUploadedHeadspinFiles(false);
    // }
  }

  async validateFourthStep() {
    console.log('appNAme 4', this.AppName);
    if (this.deviceType == 'Local') {
      console.log(this.deviceType);

      if (this.projectTypeName == 'Mobile' || this.projectTypeName == 'Web') {
        if (this.ListOfCapabilities.length <= 0) {
          this.isInValid = true;
          this.toastr.error('Please select capabilities.');
          this.ValidSteps[0]['step3'] = false;
          this.ValidSteps[0]['step4'] = false;
        } else if (
          this.msg_capTemplate != '' &&
          this.msg_capTemplate != undefined
        ) {
          this.isInValid = true;
          this.toastr.error('Please add missing capabilities.');
          this.ValidSteps[0]['step3'] = false;
          this.ValidSteps[0]['step4'] = false;
        } else {
          this.isInValid = false;
          this.ValidSteps[0]['step3'] = true;
          this.ValidSteps[0]['step4'] = true;
        }
      } else {
        this.ValidSteps[0]['step3'] = true;
        this.ValidSteps[0]['step4'] = true;
        this.isInValid = false;
      }
      if (this.isFromHistory) {
        if (
          this.selectedSlave == '' ||
          this.selectedSlave == null ||
          this.selectedSlave == undefined
        ) {
          this.toastr.error('Please select node to get devices');
          this.isInValid = true;
          this.ValidSteps[0]['step3'] = false;
          this.ValidSteps[0]['step4'] = false;
        }
      }
    } else if (this.deviceType == Constvar.Headspin) {
      // if(this.templateListData.length > 0)
      // {
      if (
        this.templateListData != null &&
        this.templateListData != undefined &&
        this.templateListData.length > 0
      ) {
        // this.replacedTemplateList =[];
        for (let i = 0; i < this.templateListData.length; i++) {
          // this.replacedTemplateList.push(Object.assign({},this.templateListData[i]));
          this.replacedTemplateList = this.templateListData;
          let replacedDeviceName = this.replacedTemplateList[
            i
          ].capabilityTemplateName.replace('_' + this.df_templateName, '');
          this.replacedTemplateList[
            i
          ].capabilityTemplateName = replacedDeviceName;
        }
      }
      // }
      // else{
      //   this.replacedTemplateList =[];
      // }
      if (this.AppName == '') {
        this.file = null;
      }
      console.log('Selected d pool arn' + this.SelectedDevicePool_arn);
      console.log('Selected app arn' + this.appArn);

      if (this.file == null && this.appArn == '') {
        this.isInValid = true;
        this.ValidSteps[0]['step3'] = false;
        this.ValidSteps[0]['step4'] = false;
        this.toastr.error('Please upload / select file to run test.');
      } else if (this.ListOfCapabilities.length <= 0) {
        this.isInValid = true;
        this.toastr.error('Please select capabilities.');
        this.ValidSteps[0]['step3'] = false;
        this.ValidSteps[0]['step4'] = false;
      } else if (
        this.msg_capTemplate != '' &&
        this.msg_capTemplate != undefined
      ) {
        this.isInValid = true;
        this.toastr.error('Please add missing capabilities.');
        this.ValidSteps[0]['step3'] = false;
        this.ValidSteps[0]['step4'] = false;
      } else if (this.oldMobile_app_path != this.AppName) {
        if (this.reExecution == true) {
          this.isAppChange = true;
        }
        console.log(
          'this.capabilityIdValuesViews.....',
          this.capabilityIdValuesViews
        );

        await this.getTemplateByName(this.df_templateName, false, true);
        await this.savecapabilityTemplate();
        this.isInValid = false;
        // this.ValidSteps[0]['step3'] = false;
        // this.ValidSteps[0]['step4'] = false;
        // this.toastr.error('Please Update Capability Template');
      } else {
        this.ValidSteps[0]['step3'] = true;
        this.ValidSteps[0]['step4'] = true;
        this.isInValid = false;
      }
    } else if (this.deviceType == Constvar.BrowserStack) {
      if (this.templateListData) {
        // this.replacedTemplateList =[];
        for (let i = 0; i < this.templateListData.length; i++) {
          // this.replacedTemplateList.push(Object.assign({},this.templateListData[i]));
          this.replacedTemplateList = this.templateListData;
          let replacedDeviceName = this.replacedTemplateList[
            i
          ].capabilityTemplateName.replace('_' + this.df_templateName, '');
          this.replacedTemplateList[
            i
          ].capabilityTemplateName = replacedDeviceName;
        }
        let selectedDevices = this.serverDevices;
        if (!this.isFromHistory) {
          this.selectedHeadspinDevices.push(selectedDevices[0]);
        }
      }
      // }
      // else{
      //   this.replacedTemplateList =[];
      // }
      if (this.AppName == '') {
        this.file = null;
      }
      console.log('Selected d pool arn' + this.SelectedDevicePool_arn);

      if (this.ListOfCapabilities.length <= 0) {
        this.isInValid = true;
        this.toastr.error('Please select capabilities.');
        this.ValidSteps[0]['step3'] = false;
        this.ValidSteps[0]['step4'] = false;
      } else if (
        this.msg_capTemplate != '' &&
        this.msg_capTemplate != undefined
      ) {
        this.isInValid = true;
        this.toastr.error('Please add missing capabilities.');
        this.ValidSteps[0]['step3'] = false;
        this.ValidSteps[0]['step4'] = false;
      } else if (this.oldMobile_app_path != this.AppName) {
        if (this.reExecution == true) {
          this.isAppChange = true;
        }
        console.log(
          'this.capabilityIdValuesViews.....',
          this.capabilityIdValuesViews
        );

        await this.getTemplateByName(this.df_templateName, false, true);
        await this.savecapabilityTemplate();
        this.isInValid = false;
        // this.ValidSteps[0]['step3'] = false;
        // this.ValidSteps[0]['step4'] = false;
        this.toastr.error('Please Update Capability Template');
      } else {
        this.ValidSteps[0]['step3'] = true;
        this.ValidSteps[0]['step4'] = true;
        this.isInValid = false;
      }
      this.dataservice.isDevicesLoading = false;
    } 
    else {
      if (this.deviceType == Constvar.DeviceFarm) {
        if (this.selectedAppFileName == '') {
          this.file = null;
        }
        console.log('Selected d pool arn' + this.SelectedDevicePool_arn);
        console.log('Selected app arn' + this.appArn);
        if (this.SelectedDevicePool_arn == '' && !this.isFromHistory) {
          this.isInValid = true;
          this.ValidSteps[0]['step3'] = false;
          this.ValidSteps[0]['step4'] = false;
          this.toastr.error('Please select device pool');
        } else {
          if (this.file == null && this.ByUpload == '') {
            this.isInValid = true;
            this.ValidSteps[0]['step3'] = false;
            this.ValidSteps[0]['step4'] = false;
            this.toastr.error('Please upload / select file to run test.');
          } else if (this.ListOfCapabilities.length <= 0) {
            this.isInValid = true;
            this.toastr.error('Please select capabilities.');
            this.ValidSteps[0]['step3'] = false;
            this.ValidSteps[0]['step4'] = false;
          } else if (
            this.msg_capTemplate != '' &&
            this.msg_capTemplate != undefined
          ) {
            this.isInValid = true;
            this.toastr.error('Please add missing capabilities.');
            this.ValidSteps[0]['step3'] = false;
            this.ValidSteps[0]['step4'] = false;
          } else if (
            this.dup_capTemplate != '' &&
            this.dup_capTemplate != undefined
          ) {
            this.isInValid = true;
            this.toastr.error(this.dup_capTemplate);
            this.ValidSteps[0]['step3'] = false;
            this.ValidSteps[0]['step4'] = false;
          } else if (
            this.cor_capTemplate != '' &&
            this.cor_capTemplate != undefined
          ) {
            this.isInValid = true;
            this.toastr.error(this.cor_capTemplate);
            this.ValidSteps[0]['step3'] = false;
            this.ValidSteps[0]['step4'] = false;
          } else if (this.ByUpload == 'Using_Arn' && this.appId == 0) {
            this.isInValid = true;
            this.ValidSteps[0]['step3'] = false;
            this.ValidSteps[0]['step4'] = false;
            this.toastr.error('Please upload / select file to run test.');
          } else if (this.oldMobile_app_path != this.mobileAppPath_Skipped) {
            console.log(
              'this.capabilityIdValuesViews.....',
              this.capabilityIdValuesViews
            );

            await this.getTemplateByName(this.df_templateName, false, true);
            await this.savecapabilityTemplate();
            this.isInValid = false;
            // this.ValidSteps[0]['step3'] = false;
            // this.ValidSteps[0]['step4'] = false;
            this.toastr.error('Please Update Capability Template');
          } else {
            this.ValidSteps[0]['step3'] = true;
            this.ValidSteps[0]['step4'] = true;
            this.isInValid = false;
            // this.savecapabilityTemplate();
          }
        }
      }
    }
  }

  async getTestSuiteByPlatform(platformId) {
     
    let platform;
    if (this.deviceType == Constvar.DeviceFarm) {
      this.AppName = '';
      this.Recentapps = 0;
    }
    this.capabilitiesByBrand = [];
    this.dataservice
      .getAppCapabilitiesByBrand(
        this.brandId,
        this.environment_type,
        this.platformId
      )
      .subscribe(
        getappActAppPack => {
          this.capabilitiesByBrand = getappActAppPack;
        },
        error => {
          this.toastr.error(error);
        }
      );
    if (!this.isFromHistory) {
      let objPlatform = this.platformList.filter(
        pl => pl.platformId === parseInt(platformId)
      );
      
      this.platform = objPlatform[0].platformName;
      this.varplatformId = platformId;
      this.getTestSuiteNameByPlatformId(platformId);
      // if (this.deviceType == Constvar.Headspin) {
      //   this.headspinDevicesAppiumUrls = this.headspinDevicesAppiumUrls.filter(
      //     dev => {
      //       if (dev.os.toUpperCase() == this.platform.toUpperCase()) {
      //         return dev;
      //       }
      //     }
      //   );
      //   console.log(this.headspinDevicesAppiumUrls);
      // }
    } else {
      this.varplatformId = this.reExeEnvDetails.platformId;
      this.getTestSuiteNameByPlatformId(this.reExeEnvDetails.platformId);
    }
  }


  // ------ New Design changes
  async setSelectedunseletedSuite(suiteId, suiteName, event, IsAll) {
    if (IsAll == true) {
      if (this.testSuiteList != null) {
        if (event.target.checked) {
          this.jsonExecutiontbl = [];
          for (let k = 0; k < this.testSuiteList.length; k++) {
            $('#ch' + k).prop('checked', true);
            this.jsonExecutiontbl.push({
              testSuiteName: this.testSuiteList[k].testSuiteName,
              testSuiteId: this.testSuiteList[k].testSuiteId,
              templateNameList:
                this.isFromHistory == true
                  ? this.TemplateList_forReexe
                  : this.ListOfCapabilities,
              testCases: [
                {
                  dataSetNameList: []
                }
              ]
            });
            this.selectedSuiteList.push(this.testSuiteList[k].testSuiteId);
          }
        } else {
          this.jsonExecutiontbl = [];
          for (let k = 0; k < this.testSuiteList.length; k++) {
            $('#ch' + k).prop('checked', false);
          }
          this.selectedSuiteList = [];
        }
      }
    } else {
      let IsAllFlag = false;
      for (let k = 0; k < this.testSuiteList.length; k++) {
        if ($('#ch' + k).prop('checked') == true) {
        } else {
          IsAllFlag = true;
        }
      }
      if (IsAllFlag == true) {
        $('#All').prop('checked', false);
      } else {
        $('#All').prop('checked', true);
      }
      if (event.target.checked) {
        this.jsonExecutiontbl.push({
          testSuiteName: suiteName,
          testSuiteId: suiteId,
          templateNameList:
            this.isFromHistory == true
              ? this.TemplateList_forReexe
              : this.ListOfCapabilities,
          testCases: [
            {
              dataSetNameList: []
            }
          ]
        });
        this.selectedSuiteList.push(suiteId);
      } else {
        var filtered = this.jsonExecutiontbl.filter(item => {
          return item.testSuiteId !== suiteId;
        });
        this.selectedSuiteList = this.selectedSuiteList.filter(testSuiteId => {
          return testSuiteId !== suiteId;
        });
        this.jsonExecutiontbl = filtered;
      }
    }
    if (this.jsonExecutiontbl == null) {
      this.ValidSteps = [
        { step0: true, step1: true, step2: false, step3: false, step4: false }
      ];
    }
    if (this.jsonExecutiontbl != null) {
      if (this.jsonExecutiontbl.length <= 0) {
        this.ValidSteps = [
          { step0: true, step1: true, step2: false, step3: false, step4: false }
        ];
      }
    }
  }
  //pagination handaling for auto selected page
  async handlePagination(stepno) {
    let datalist = [];
    this.activeIndexPaginition = stepno;
    this.selectedtSuiteId_stepper = this.jsonExecutiontbl[stepno].testSuiteId;
    this.selectedtSuiteStep_stepper = stepno;
    this.selectedTestSuiteName_step = this.jsonExecutiontbl[
      stepno
    ].testSuiteName;
    let testCaseList = this.jsonExecutiontbl[stepno].testCases;
    await this.checkUncheckCases(testCaseList, stepno);
    await this.checkUncheckDataset(stepno);
  }
  async checkUncheckCases(testCaseList, stepno) {
    console.log(testCaseList);
    if (!this.isFromHistory) {
      this.testCaseList = [];
      let updatetestCaseList = this.jsonCaselist[this.selectedtSuiteId_stepper];
      this.testCaseList = updatetestCaseList;

      console.log(this.testCaseList);
      if (this.testCaseList != null) {
        updatetestCaseList.forEach(element => {
          element.IsSelected = false;
          for (let i = 0; i < testCaseList.length; i++) {
            if (testCaseList[i].testCaseId == element.testCaseId) {
              element.IsSelected = true;
            }
          }
        });
        this.TestCaseList = updatetestCaseList;
        console.log(updatetestCaseList);

        if (this.testCaseList.length === testCaseList.length) {
          $('#chkcaseAll').prop('checked', true);
        } else {
          $('#chkcaseAll').prop('checked', false);
        }
      }
    } else {
      this.testCaseList = [];
      this.testCaseList = this.testSuiteList[stepno].testCases;
      this.testCaseList.forEach(element => {
        for (let i = 0; i < testCaseList.length; i++) {
          if (testCaseList[i].testCaseId == element.testCaseId) {
            element.IsSelected = true;
          }
        }
      });
      if (this.testCaseList.length === testCaseList.length) {
        $('#chkcaseAll').prop('checked', true);
      } else {
        $('#chkcaseAll').prop('checked', false);
      }
    }
  }
  async checkUncheckDataset(stepno) {
    let datalist = [];
    if (this.jsonExecutiontbl[stepno].testCases[0]) {
      datalist = this.jsonExecutiontbl[stepno].testCases[0].dataSetNameList;
    }
    this.dataset_list = [];
    this.dataSetName = '';
    this.dataset_list = this.jsonDatasetList[this.selectedtSuiteId_stepper];
    this.dataset_list ? this.dataset_list : (this.dataset_list = []);
    this.dataset_list = this.dataset_list.filter(ele => {
      return ele.dataSetName.trim().length != 0;
    });
    if (this.dataset_list != null) {
      this.dataset_list.forEach(element => {
        element.IsSelected = false;
        for (let i = 0; i < datalist.length; i++) {
          if (
            datalist[i].dataSetName.toUpperCase() ==
            element.dataSetName.toUpperCase()
          ) {
            element.IsSelected = true;
          }
        }
      });
      if (this.dataset_list.length === datalist.length) {
        $('#chkDsAll').prop('checked', true);
      } else {
        $('#chkDsAll').prop('checked', false);
      }
    }
  }
  async GetDsAndCases(suiteId, suiteName, stepno: number) {
    this.activeIndexPaginition = stepno;
    $('#chkcaseAll').prop('checked', false);
    $('#chkDsAll').prop('checked', false);
    this.caseList_stepper = [];
    //commected this code for no to null value when comes to step 2 after selecting test cases and sata set name
    // this.DsList_stepper = [];
    this.selectedTestSuiteName_step = suiteName;
    this.selectedtSuiteStep_stepper = stepno;
    this.selectedtSuiteId_stepper = suiteId;
    if (this.isFromHistory == true) {
      await this.getAllTestCase_ByProjects(suiteId);
    } else {
      await this.getAllTestCase_ByProjects(suiteId);
    }
    await this.getAllDataSetById(suiteId);

    await this.checkRecentSelctedTestCases(suiteId);

    await this.checkRecentSelctedTest_DS(suiteId);
    await this.paginationGenerate(stepno);
    await this.selectAllTcDs(stepno);
  }
  //Step-3 methods for getting alltestcases and datasets
  async getTestcasesOfAllSuite() {
    let testCaseId = Type.TestCaseId;
    let desc = Type.descending;
    let bodydata = {
      sortColumn: testCaseId,
      sortOrder: desc,
      regionId: this.regionId,
      brandId: this.brandId,
      projectTypeId: this.projectTypeId,
      projectName: this.projectName,
      testSuiteId: this.selectedSuiteList
    };
    await this.dataservice
      .getAllTestCasesByMultipleSuite(bodydata)
      .toPromise()
      .then(async (response: any) => {
        if (response) {
          if (response.length > 0) {
            this.jsonCaselist = await this.sortDatasetGroupby(
              response,
              'testSuiteId'
            );
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  async getDatasetsAllSuite() {
    this.jsonDatasetList = [];
    await this.dataservice
      .getDataSetNameList(this.selectedSuiteList)
      .toPromise()
      .then(async (response: any) => {
        if (response) {
          if (response.length > 0) {
            this.jsonDatasetList = await this.sortDatasetGroupby(
              response,
              'testSuiteId'
            );
            console.log('getDataSetNameList', this.jsonDatasetList);
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
  async sortDatasetGroupby(xs, key) {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  //End Step-3 methods for getting alltestcases and datasets
  selectAllTcDs(stepno: any) {
    this.caseList_stepper = [];
    if (this.testCaseList != null && this.testCaseList.length > 0) {
      $('#chkcaseAll').prop('checked', true);
      //added to select All test cases
      for (let k = 0; k < this.testCaseList.length; k++) {
        $('#chkcase' + k).prop('checked', true);
        console.log(
          'selected testCaseList --> ',
          this.testCaseList[k].testCaseName
        );
        this.caseList_stepper.push({
          testCaseId: this.testCaseList[k].testCaseId,
          testCaseName: this.testCaseList[k].testCaseName,
          dataSetNameList: this.DsList_stepper
        });
      }

      if (this.caseList_stepper.length <= 0) {
        this.jsonExecutiontbl[stepno].testCases = this.caseList_stepper;
      } else {
        if (this.jsonExecutiontbl.length > 0) {
          this.jsonExecutiontbl[stepno].testCases = this.caseList_stepper;
        }
      }
    }
  }

  setSelectedunseletedCase(
    suiteId: any,
    testCaseId: any,
    testCaseName: any,
    event: any,
    IsAll: any,
    stepno: any
  ) {
    if (this.testCaseList != null && this.testCaseList.length > 0) {
      if (IsAll == true) {
        this.caseList_stepper = [];
        if (this.testCaseList != null) {
          if (event.target.checked) {
            for (let k = 0; k < this.testCaseList.length; k++) {
              $('#chkcase' + k).prop('checked', true);
              this.caseList_stepper.push({
                testCaseId: this.testCaseList[k].testCaseId,
                testCaseName: this.testCaseList[k].testCaseName,
                dataSetNameList: this.DsList_stepper
              });
            }
          } else {
            for (let k = 0; k < this.testCaseList.length; k++) {
              $('#chkcase' + k).prop('checked', false);
            }
          }
        }
      } else {
        let IsAllFlag = false;
        this.caseList_stepper = [];
        for (let k = 0; k < this.testCaseList.length; k++) {
          if ($('#chkcase' + k).prop('checked') == true) {
          } else {
            IsAllFlag = true;
          }
        }
        if (IsAllFlag == true) {
          $('#chkcaseAll').prop('checked', false);
        } else {
          $('#chkcaseAll').prop('checked', true);
        }
        if (event.target.checked) {
          this.caseList_stepper = this.jsonExecutiontbl[stepno].testCases;
          const found = this.caseList_stepper.some(
            el => el.testCaseName.toUpperCase() === testCaseName.toUpperCase()
          );
          if (!found) {
            this.caseList_stepper.push({
              testCaseId: testCaseId,
              testCaseName: testCaseName,
              dataSetNameList: this.DsList_stepper
            });
          }
        } else {
          this.caseList_stepper = this.jsonExecutiontbl[stepno].testCases;

          var filtered = this.caseList_stepper.filter(item => {
            console.log(item.testCaseId + '' + testCaseId);
            return item.testCaseId !== testCaseId;
          });
          this.caseList_stepper = filtered;
        }
      }
      if (this.caseList_stepper.length <= 0) {
        this.jsonExecutiontbl[stepno].testCases = this.caseList_stepper;
      } else {
        if (this.jsonExecutiontbl.length > 0) {
          this.jsonExecutiontbl[stepno].testCases = this.caseList_stepper;
        }
      }
      this.jsonExecutiontbl[stepno].testCases.forEach(element => {
        element.IsSelected = true;
      });
      this.GetSelectedTestCases();
      console.log(this.jsonExecutiontbl);
    }
  }

  setSelectedunseletedDs(suiteId, dsName, event, IsAll, stepno) {
    if (this.dataset_list != null) {
      if (this.dataset_list.length > 0) {
        if (IsAll == true) {
          this.DsList_stepper = [];
          if (this.dataset_list != null) {
            if (event.target.checked) {
              for (let k = 0; k < this.dataset_list.length; k++) {
                $('#chkDs' + k).prop('checked', true);
                this.DsList_stepper.push({
                  dataSetName: this.dataset_list[k].dataSetName
                });
              }
            } else {
              for (let k = 0; k < this.dataset_list.length; k++) {
                $('#chkDs' + k).prop('checked', false);
              }
            }
          }
          if (this.jsonExecutiontbl[stepno].testCases != null) {
            for (
              let j = 0;
              j < this.jsonExecutiontbl[stepno].testCases.length;
              j++
            ) {
              this.jsonExecutiontbl[stepno].testCases[
                j
              ].dataSetNameList = this.DsList_stepper;
            }
          }
        } else {
          this.DsList_stepper = [];
          let IsAllFlag = false;
          for (let k = 0; k < this.dataset_list.length; k++) {
            if ($('#chkDs' + k).prop('checked') == true) {
            } else {
              IsAllFlag = true;
            }
          }
          if (IsAllFlag == true) {
            $('#chkDsAll').prop('checked', false);
          } else {
            $('#chkDsAll').prop('checked', true);
          }

          if (event.target.checked) {
            this.DsList_stepper = this.jsonExecutiontbl[
              stepno
            ].testCases[0].dataSetNameList;
            const found = this.DsList_stepper.some(
              el => el.dataSetName.toUpperCase() === dsName.toUpperCase()
            );
            if (!found) {
              this.DsList_stepper.push({
                dataSetName: dsName
              });
            }
          } else {
            this.DsList_stepper = this.jsonExecutiontbl[
              stepno
            ].testCases[0].dataSetNameList;
            var filtered = this.DsList_stepper.filter(item => {
              return item.dataSetName !== dsName;
            });
            this.DsList_stepper = filtered;
          }
          if (this.jsonExecutiontbl[stepno].testCases != null) {
            for (
              let j = 0;
              j < this.jsonExecutiontbl[stepno].testCases.length;
              j++
            ) {
              this.jsonExecutiontbl[stepno].testCases[
                j
              ].dataSetNameList = this.DsList_stepper;
            }
          }
        }
      }
    }
    console.log(this.jsonExecutiontbl);
  }

  GetSelectedTestCases() {
    this.TotalSelectedTestCases = 0;
    for (let i = 0; i < this.jsonExecutiontbl.length; i++) {
      this.TotalSelectedTestCases =
        this.TotalSelectedTestCases +
        (this.jsonExecutiontbl[i].testCases.length == undefined
          ? 0
          : this.jsonExecutiontbl[i].testCases.length);
    }
  }

  executionCancel(val) {
    let r = false;
    if (val != 0) {
      r = confirm('Are you sure you want to reset test suite data?');
    } else {
      r = true;
    }
    if (r == true) {
      this.ValidSteps = [
        { step0: true, step1: false, step2: false, step3: false, step4: false }
      ];
      this.txtjobName = '';
      this.file_arn = '';
      this.jsonExecutiontbl = [];
      this.IsjobName_error = '';
      this.file = null;
      this.selectedAppFileName = '';
      this.deviceType = 'Local';
      if(!this.isfromExecution){
      this.getAllProjectType();
    }
      this.clear_case_list();
      this.clearSelectedSuite('');
      this.isArnTemplate = false;
      this.ddldevicepool = 0;
      $('#scheduleModal').modal('hide');
      this.ResetSuiteData(1);
      this.stepper['_currentIndex'] = 0;
      this.stepper['selectedIndex'] = 0;
      this.active_step = 0;
      this.stepper.to(0);
      this.dataset_list = [];
      this.DsList_stepper = [];
      this.highlightRecentsteps(0);
      this.isInValid = false;
    }
  }

  setNext_Prev(btnval, btntype) {
    if ($('#p' + btnval)[0] != undefined) {
      $('#p' + btnval)
        .get(0)
        .click();
    }
    this.paginationGenerate(btnval);
  }

  checkRecentSelctedTestCases(suiteId) {
    $('#chkcaseAll').prop('checked', false);

    if (this.jsonExecutiontbl != null) {
      this.caseList_stepper = [];
      if (this.jsonExecutiontbl.length > 0) {
        var filtered = this.jsonExecutiontbl.filter(item => {
          return item.testSuiteId.toString() === suiteId.toString();
        });
        if (filtered.length > 0) {
          filtered[0].testCases.forEach(element => {
            if (element.testCaseId != '' && element.testCaseId != undefined) {
              this.testCaseList.some(obj => {
                if (
                  obj.testCaseId.toString() == element.testCaseId.toString()
                ) {
                  //change the value here
                  this.caseList_stepper.push({
                    testCaseId: obj.testCaseId,
                    testCaseName: obj.testCaseName,
                    dataSetNameList: this.DsList_stepper
                  });

                  obj.IsSelected = true;
                  return true;
                } else {
                }
              });
            }
          });
        }

        let IsAllFlag = false;
        if (this.testCaseList != null) {
          if (this.testCaseList.length > 0) {
            for (let k = 0; k < this.testCaseList.length; k++) {
              if (this.testCaseList[k].IsSelected != undefined) {
                if (this.testCaseList[k].IsSelected == true) {
                } else {
                  IsAllFlag = true;
                }
              }
            }
          }
        }
        if (IsAllFlag == true) {
          $('#chkcaseAll').prop('checked', false);
        } else {
          $('#chkcaseAll').prop('checked', true);
        }
      }
    }
  }
  checkRecentSelctedTest_DS(suiteId) {
    $('#chkDsAll').prop('checked', false);
    if (this.jsonExecutiontbl != null) {
      this.DsList_stepper = [];
      if (this.jsonExecutiontbl.length > 0) {
        var filtered = this.jsonExecutiontbl.filter(item => {
          return item.testSuiteId.toString() === suiteId.toString();
        });
        if (filtered.length > 0) {
          console.log(filtered[0].testCases[0]);
          if (filtered[0].testCases.length > 0) {
            filtered[0].testCases[0].dataSetNameList.forEach(element => {
              if (
                element.dataSetName != '' &&
                element.dataSetName != undefined
              ) {
                this.dataset_list.some(obj => {
                  if (
                    obj.dataSetName.toString() == element.dataSetName.toString()
                  ) {
                    this.DsList_stepper.push({
                      dataSetName: obj.dataSetName
                    });
                    obj.IsSelected = true;
                    return true;
                  }
                });
              }
            });
          } else {
          }
        }

        let IsAllFlag = false;
        if (this.dataset_list != null) {
          if (this.dataset_list.length > 0) {
            for (let k = 0; k < this.dataset_list.length; k++) {
              // first data set name selected bydefault
              this.dataset_list[0].IsSelected = true;
              $('#chkDs' + k).prop('checked', true);

              if (this.DsList_stepper.length == 0) {
                this.DsList_stepper.push({
                  dataSetName: this.dataset_list[0].dataSetName
                });

                let index = this.jsonExecutiontbl.findIndex(
                  ele => ele.testSuiteId == suiteId
                );
                console.log('check index', index);

                if (this.jsonExecutiontbl[index].testCases != null) {
                  for (
                    let j = 0;
                    j < this.jsonExecutiontbl[index].testCases.length;
                    j++
                  ) {
                    this.jsonExecutiontbl[index].testCases[
                      j
                    ].dataSetNameList = this.DsList_stepper;
                  }
                }
              }

              if (this.dataset_list[k].IsSelected != undefined) {
                if (this.dataset_list[k].IsSelected == true) {
                } else {
                  IsAllFlag = true;
                }
              }
            }
          }
        }
        if (IsAllFlag == true) {
          $('#chkDsAll').prop('checked', false);
        } else {
          $('#chkDsAll').prop('checked', true);
        }
      }
    }
  }
  async checkRecentSelectedCasesAndDataset() {
    if (!this.isFromHistory) {
      if (
        this.jsonExecutiontbl.length > 0 &&
        this.selectedSuiteList.length > 0
      ) {
        for (let i = 0; i < this.selectedSuiteList.length; i++) {
          this.caseList_stepper = [];
          this.DsList_stepper = [];

          let suiteName = this.jsonExecutiontbl[i].testSuiteName;
          let suiteId = this.jsonExecutiontbl[i].testSuiteId;
          this.testCaseList = this.jsonCaselist[suiteId];

          let dataSetList = this.jsonDatasetList[suiteId];

          let dataSetName;
          if (dataSetList) {
            dataSetName = dataSetList[0].dataSetName;
            this.DsList_stepper.push({
              dataSetName: dataSetName
            });
          } else {
            dataSetName = '';
            this.DsList_stepper = [];
            dataSetList = [];
          }
          this.testCaseList ? this.testCaseList : (this.testCaseList = []);
          for (let k = 0; k < this.testCaseList.length; k++) {
            this.caseList_stepper.push({
              testCaseId: this.testCaseList[k].testCaseId,
              testCaseName: this.testCaseList[k].testCaseName,
              dataSetNameList: this.DsList_stepper
            });
          }
          if (this.selectedSuiteList.length == i + 1) {
            this.selectedTestSuiteName_step = suiteName;
            this.testCaseList = this.testCaseList.map(element => ({
              ...element,
              IsSelected: true
            }));
            $('#chkcaseAll').prop('checked', true);
            this.dataset_list = dataSetList;
            if (this.DsList_stepper.length == this.dataset_list.length) {
              this.dataset_list = this.dataset_list.map(element => ({
                ...element,
                IsSelected: true
              }));
              $('#chkDsAll').prop('checked', true);
            } else {
              this.dataset_list.forEach(element => {
                for (let i = 0; i < this.DsList_stepper.length; i++) {
                  if (
                    this.DsList_stepper[i].dataSetName.toUpperCase() ==
                    element.dataSetName.toUpperCase()
                  ) {
                    element.IsSelected = true;
                  }
                }
              });
            }
          }
          this.jsonExecutiontbl[i].testCases = this.caseList_stepper;
        }
      }
    } else {
       
      if (
        this.jsonExecutiontbl.length > 0 &&
        this.selectedSuiteList.length > 0
      ) {
        for (let i = 0; i < this.selectedSuiteList.length; i++) {
          this.caseList_stepper = [];
          this.DsList_stepper = [];
          let suiteName = this.jsonExecutiontbl[i].testSuiteName;
          let suiteId = this.jsonExecutiontbl[i].testSuiteId;
          this.testCaseList = this.jsonCaselist[suiteId];

          let dataSetList = this.jsonDatasetList[suiteId];
          let selectedSuite = this.testSuiteList.filter(suitedata => {
            if (this.jsonExecutiontbl[i].testSuiteId == suitedata.testSuiteId) {
              return suitedata;
            }
          });
          let historydataset = selectedSuite[0].dataSetNameViews;
          let dataSetName;
          if (historydataset) {
            for (let j = 0; j < historydataset.length; j++) {
              dataSetName = dataSetList[j].dataSetName;
              this.DsList_stepper.push({
                dataSetName: dataSetName
              });
            }
          } else {
            dataSetName = '';
            this.DsList_stepper = [];
            dataSetList = [];
          }
          let historyCaselist = selectedSuite[0].testCases;

          this.testCaseList ? this.testCaseList : (this.testCaseList = []);
          for (let k = 0; k < this.testCaseList.length; k++) {
            this.caseList_stepper.push({
              testCaseId: this.testCaseList[k].testCaseId,
              testCaseName: this.testCaseList[k].testCaseName,
              dataSetNameList: this.DsList_stepper
            });
          }
          if (this.selectedSuiteList.length == i + 1) {
             
            this.selectedTestSuiteName_step = suiteName;
            this.testCaseList.forEach(element => {
              for (let i = 0; i < historyCaselist.length; i++) {
                if (element.oldTestCaseId ==0)
                {
                  if (element.testCaseId == historyCaselist[i].testCaseId) {
                    element.IsSelected = true;
                  } 
                } 
                else
                {
                  if (element.oldTestCaseId == historyCaselist[i].testCaseId
                    ||
                    element.testCaseId == historyCaselist[i].testCaseId) {
                    element.IsSelected = true;
                  }
                }
             
              }
            });
            if (this.testCaseList.length == historyCaselist.length) {
              $('#chkcaseAll').prop('checked', true);
            }
            this.dataset_list = dataSetList;
            if (this.DsList_stepper.length == this.dataset_list.length) {
              this.dataset_list = this.dataset_list.map(element => ({
                ...element,
                IsSelected: true
              }));
              $('#chkDsAll').prop('checked', true);
            } else {
              this.dataset_list.forEach(element => {
                for (let i = 0; i < this.DsList_stepper.length; i++) {
                  if (
                    this.DsList_stepper[i].dataSetName.toUpperCase() ==
                    element.dataSetName.toUpperCase()
                  ) {
                    element.IsSelected = true;
                  }
                }
              });
            }
          }
          this.jsonExecutiontbl[i].testCases = this.caseList_stepper;
        }
      }
    }
    console.log(this.jsonExecutiontbl);
  }
  async checkRecentSelctedTest_Suite(suiteId) {
    $('#All').prop('checked', false);

    if (this.jsonExecutiontbl != null && this.testSuiteList != null) {
      this.testSuiteList.some(obj => {
        obj.IsSelected = false;
      });
      if (this.jsonExecutiontbl.length > 0 && this.testSuiteList.length > 0) {
        if (this.jsonExecutiontbl.length == 1) {
          this.selectedtSuiteStep_stepper = 0;
          if (this.jsonExecutiontbl.length > 0) {
            this.selectedTestSuiteName_step = this.jsonExecutiontbl[0].testSuiteName;
            let suiteid = this.jsonExecutiontbl[0].testSuiteId;
            this.TotalSelectedTestCases =
              this.TotalSelectedTestCases -
              this.jsonExecutiontbl[0].testCases.length;
            this.selectedtSuiteId_stepper = suiteid;
            await this.getAllTestCase_ByProjects(suiteid);
            await this.getAllDataSetById(suiteid);
            this.setNext_Prev(this.selectedtSuiteStep_stepper, 0);
          }
        }
        for (let i = 0; i < this.testSuiteList.length; i++) {
          var filtered = this.jsonExecutiontbl.filter(item => {
            return (
              item.testSuiteId.toString() ===
              this.testSuiteList[i].testSuiteId.toString()
            );
          });
          if (filtered.length > 0) {
            filtered.forEach(element => {
              if (
                element.testSuiteId != '' &&
                element.testSuiteId != undefined
              ) {
                this.testSuiteList.some(obj => {
                  if (
                    obj.testSuiteId.toString() == element.testSuiteId.toString()
                  ) {
                    //change the value here
                    obj.IsSelected = true;
                    return true;
                  }
                });
              }
            });
          }
        }
      }
      let IsAllFlag = false;
      if (this.testSuiteList != null) {
        if (this.testSuiteList.length > 0) {
          for (let k = 0; k < this.testSuiteList.length; k++) {
            if (this.testSuiteList[k].IsSelected != undefined) {
              if (this.testSuiteList[k].IsSelected == true) {
              } else {
                $('#ch' + k).prop('checked', false);
                IsAllFlag = true;
              }
            }
          }
        }
      }
      if (IsAllFlag == true) {
        $('#All').prop('checked', false);
      } else {
        $('#All').prop('checked', true);
      }
    }
  }

  // ---------------- Suite Paginition------------------
  pageRange(page, pageCount) {
    var start = page - 2,
      end = page + 2;

    if (end > pageCount) {
      start -= end - pageCount;
      end = pageCount;
    }
    if (start <= 0) {
      end += (start - 1) * -1;
      start = 1;
    }

    end = end > pageCount ? pageCount : end;
    return {
      start: start,
      end: end
    };
  }

  paginationGenerate(page) {
    var pageCount = this.jsonExecutiontbl.length;
    var range = this.pageRange(page, pageCount);
    var start = range.start;
    var end = range.end;
    for (let k = 0; k < pageCount; k++) {
      $('#p' + k).addClass('p_out');
    }
    for (var page_id = start; page_id <= end; page_id++) {
      $('#p' + (page_id - 1)).removeClass('p_out');
      $('#p' + (page_id - 1)).addClass('p_in');
    }
  }
  ChangeExecutionMode(event) {
    this.executionMode = event.target.value;
  }

  async CheckIsTemplateAssignedToDevice(event, i) {
     
    this.dataservice.error_500_status = '';

    this.isHSAnyDeviceSelected = false;
    this.selectedHeadspinDevices = [];
    let TemplateName = event.target.value;
    if (TemplateName.trim() != '') {
      this.loaderService.hide();
      if (
        this.deviceType == Constvar.Headspin ||
        this.deviceType == Constvar.BrowserStack
      ) {
        $('.checkBoxClass').attr('disabled', false);
        $('.checkBoxClass').prop('checked', false);
        await this.dataservice
          .getcapabilityTemplateByNameHeadspin(
            this.deviceType,
            TemplateName,
            this.userId
          )
          .toPromise()
          .then(res => {
            if (res != null) {
              let templateArray = [];
              this.loaderService.show();
              for (const property in res) {
                templateArray.push(res[property].templateName);
              }

              let platform = res[0].capabilityIdValuesViews.filter(cap => {
                if (cap.capabilityName == 'platform') {
                  return cap;
                }
              });
              if (res[0].hsAnyDevice) {
                this.isHSAnyDeviceSelected = true;
                $('.hsanycheck').attr('disabled', true);
                $('.hsanycheck').prop('checked', true);
                res[0].capabilityIdValuesViews.map(cap => {
                  if (cap.capabilityName == 'osVersion') {
                    this.hsAnyDeviceSelectedOS = cap.capabilityValue;
                  }
                });
              } else if (platform[0].capabilityValue == this.platform) {
                this.isHSAnyDeviceSelected = false;
                let strreplace = '_' + TemplateName;
                templateArray = templateArray.map(tempArray =>
                  tempArray.replace(strreplace, '')
                );
                for (let i = 0; i < templateArray.length; i++) {
                  this.serverDevices.map((devices, index) => {
                    let udid = res[0].capabilityIdValuesViews.filter(cap => {
                      if (cap.capabilityName == 'udid') {
                        return cap;
                      }
                    });
                    let os = res[0].capabilityIdValuesViews.filter(cap => {
                      if (cap.capabilityName == 'osVersion') {
                        return cap;
                      }
                    });
                    if(this.deviceType !=Constvar.BrowserStack){
                    if (
                      templateArray[i] == devices.deviceName &&
                      udid[0].capabilityValue == devices.deviceId
                    ) {
                      this.selectedHeadspinDevices.push(devices);
                      $('#' + devices.deviceId).prop('checked', true);
                    }}else if(this.deviceType ==Constvar.BrowserStack){
                      if (
                        templateArray[i] == devices.device &&
                        udid[0].capabilityValue == devices.device
                      ) {
                        this.selectedHeadspinDevices.push(devices);
                        $("input[id='"+devices.device+"_"+os[0].capabilityValue+"']").prop('checked',true)
                      }
                    }

                    $('#' + devices.device+'_'+os[0].capabilityValue).attr('disabled', true);
                  });
                }

                this.loaderService.hide();
                // $('.hsanycheck').attr('disabled', true);
              } else {
                this.toastr.warning(
                  'Selected Template Created for another Platform'
                );
                this.df_templateName = '';
              }
            } else {
              if (this.isHSAnyDeviceSelected) {
                $('.checkBoxClass').attr('disabled', false);
                $('.checkBoxClass').prop('checked', false);
                $('.hsanycheck').attr('disabled', false);
                $('.hsanycheck').prop('checked', true);
              } else {
                $('.hsanycheck').attr('disabled', false);
                $('.hsanycheck').prop('checked', false);
              }
            }
          })
          .catch(err => {
            this.toastr.error(this.dataservice.error_500_status);
          });
      } else {
        await this.dataservice
          .getcapabilityTemplateByName(TemplateName)
          .toPromise()
          .then(res => {
            this.loaderService.hide();
            if (res != null) {
              let keyValue = '';
              res['capabilityIdValuesViews'].forEach(element => {
                keyValue = element.capabilityName;
                if (this.projectTypeName == 'Web' && keyValue == 'deviceName') {
                  let devicename = this.serverDevices[i].name;
                  if (element.capabilityValue == devicename) {
                    this.var_manual_templatename_Error = '';
                    $(
                      '#lblErrorMsg' + i
                    ).innerHTML = this.var_manual_templatename_Error;
                    $('#lblError' + i).removeClass('required-template-out');
                    $('#lblError' + i).addClass('required-template-in');
                  } else {
                    $('#lblError' + i).removeClass('required-template-in');
                    $('#lblError' + i).addClass('required-template-out');
                    event.target.value = '';
                    TemplateName = '';
                    this.df_templateName = '';
                    this.var_manual_templatename_Error =
                      TemplateName +
                      ' Template name already in use for another device / browser';
                    $(
                      '#lblErrorMsg' + i
                    ).innerHTML = this.var_manual_templatename_Error;
                    this.serverDevices[i]['TemplateName'] = TemplateName;
                    return;
                  }
                } else if (
                  this.projectTypeName == 'Mobile' &&
                  keyValue == 'udid'
                ) {
                  let udid =
                    this.deviceType == 'DeviceFarm'
                      ? this.SelectedDevicePool_arn + '_' + this.devicePool
                      : this.deviceType == 'Local'
                      ? this.serverDevices[i].udid
                      : '';
                  console.log(udid);
                  console.log(element.capabilityValue);

                  if (element.capabilityValue == udid) {
                    this.var_manual_templatename_Error = '';
                    $('#lblError' + i).removeClass('required-template-out');
                    $('#lblError' + i).addClass('required-template-in');
                    //added by mustaid
                    $(
                      '#lblErrorMsg' + i
                    ).innerHTML = this.var_manual_templatename_Error;
                  } else {
                    $('#lblError' + i).removeClass('required-template-in');
                    $('#lblError' + i).addClass('required-template-out');
                    event.target.value = '';
                    TemplateName = '';
                    this.df_templateName = '';

                    this.var_manual_templatename_Error =
                      TemplateName +
                      ' Template name already in use for another device / browser';
                    $(
                      '#lblErrorMsg' + i
                    ).innerHTML = this.var_manual_templatename_Error;
                    this.serverDevices[i]['TemplateName'] = TemplateName;
                    return;
                  }
                }
              });
            }
          })
          .catch(err => {
            this.toastr.error(this.dataservice.error_500_status);
          });
      }
    }
  }

  UpdateTempateNameInlocalJson(UpdateIndex, event) {
     
    if (this.serverDevices != null) {
      if (this.serverDevices.length > 0) {
        let TemplateName = event.target.value;
        this.select_browseer_temp_name = event.target.value;
        if (TemplateName != '') {
          let IsTemplateNameExist = false;
          let objtemplatesExist = this.serverDevices.filter(o =>
            o.TemplateName == '' || o.TemplateName == undefined
              ? ''
              : o.TemplateName.toUpperCase() == TemplateName.toUpperCase()
          );
          if (objtemplatesExist != null) {
            if (objtemplatesExist.length > 0) {
              IsTemplateNameExist = true;
            }
          }
          if (IsTemplateNameExist == true) {
            this.var_manual_templatename_Error =
              TemplateName + ' Template name already in use';
            this.isErrorExist = true;
            $(
              '#lblErrorMsg' + UpdateIndex
            ).innerText = this.var_manual_templatename_Error;
            $('#lblError' + UpdateIndex).removeClass('required-template-in');
            $('#lblError' + UpdateIndex).addClass('required-template-out');
          } else {
            this.var_manual_templatename_Error = '';
            this.isErrorExist = false;
            $(
              '#lblErrorMsg' + UpdateIndex
            ).innerText = this.var_manual_templatename_Error;
            $('#lblError' + UpdateIndex).removeClass('required-template-out');
            $('#lblError' + UpdateIndex).addClass('required-template-in');
          }
        } else {
          this.var_manual_templatename_Error = 'Please Enter Template Name';

          $(
            '#lblErrorMsg' + UpdateIndex
          ).innerText = this.var_manual_templatename_Error;
          $('#lblError' + UpdateIndex).removeClass('required-template-in');
          $('#lblError' + UpdateIndex).addClass('required-template-out');
        }
        this.serverDevices[UpdateIndex]['TemplateName'] = TemplateName;
      }
    }
  }

  /*---------WTAF-753( Stop Execution)-----------------
  ---------- By :Shafa -----------------------
  -----------Date :March 12 2020--------------=------
  */
  updateJobId() {
    let jenkinsJobId = this.jenkinsJobIdNum;
    let localJobId = this.localJobIdNum;
    if (
      (jenkinsJobId != null ||
        jenkinsJobId != 0 ||
        jenkinsJobId != undefined) &&
      (localJobId != null || localJobId != 0 || localJobId != undefined)
    ) {
      this.dataservice
        .addJenkinsJobId(localJobId, jenkinsJobId)
        .subscribe(
          res => {
            console.log('update job Id------------------', res);
            //changes by mustaid for updating start time after execution start
            if (res != null && res != '' && res != undefined) {
              if (!this.scheduleFlag) {
                this.dataservice
                  .updateHistorystatus(this.historyId, true, 1, 1)
                  .subscribe(
                    update_status_res => {
                      console.log(
                        ' update status res--------',
                        update_status_res
                      );
                    },
                    error => {
                      console.log(error);
                      this.toastr.error('Something went Wrong ');
                    }
                  );
              }
            }
          },
          error => {
            console.log(error);
            this.toastr.error('Something went Wrong ');
          }
        );
    } else {
      this.errorMessage = 'undefined';
    }
  }

  refreshData() {
    this.getJenkinsSlave();
  }

  refreshDevicePoolData() {
    this.listDevicePools();
  }

  refAppfile(refreshAppfile) {
    this.isUpdateListOfUpload = true;
    this.clearFiles();
    if (this.deviceType != Constvar.Local) {
      this.getUploadedApps(refreshAppfile);
    }
  }
  changeLanguage(e) {
    console.log(parseInt(e.target.value));
    this.languageId = parseInt(e.target.value);
    this.languageList.forEach(element => {
      if (element.languageId == this.languageId) {
        this.languageCode = element.languageCode;
        console.log('language code', this.languageCode);
      }
    });
  }

  /* get all language */
  getAllLanguage() {
     
    this.dataservice.getAllLanguage().subscribe(
      (response: {}) => {
        this.languageList = response;
        if (response != null) {
          if (this.isFromHistory == false) {
            this.languageId = this.languageList[0].languageId;
            this.languageCode = this.languageList[0].languageCode;
            console.log('this.languageCode', this.languageCode);
            if (this.languageList.length == 1) {
              this.languageId = this.languageList[0].languageId;
              console.log('this.languageId', this.languageId);
            } else {
              //  this.languageLabelForm.controls['languageId'].setValue('');
            }
          }
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong ');
      }
    );
  }
  submitReorders() {
    let reorderDetails = [];
    for (let m = 0; m < this.sortedSteps.length; m++) {
      console.log(this.sortedSteps[m]);

      let index = this.jsonExecutiontbl.findIndex(
        x => x.testSuiteName.trim() === this.sortedSteps[m].trim()
      );
      console.log(index);
      reorderDetails.push(this.jsonExecutiontbl[index]);
    }
    this.jsonExecutiontbl = reorderDetails;
  }
  reExecution_withCustomChanges(headerId_fromHistory, historyId_fromHistory) {
    this.reExecution = true;
    this.TemplateList_forReexe = [];
    this.dup_capTemplate = '';
    this.cor_capTemplate = '';
    this.templateListData = [];
    this.replacedTemplateList = [];
    this.ishsIsTrue = false;
    // this.replacedTemplateList =[];
    this.dataservice
      .getTemplateNameByHistoryId(historyId_fromHistory)
      .toPromise()
      .then(async templateList => {
        console.log('templateList', templateList);
        this.templateListData = templateList;
        // this.selectedHeadspinDevices = templateListData;
        if (
          this.templateListData != null &&
          this.templateListData != undefined &&
          this.templateListData.length > 0
        ) {
          for (let i = 0; this.templateListData.length > i; i++) {
            this.df_templateName = templateList[i].usrCapabilityTemplateName;
            console.log('df_ tempalte name', this.df_templateName);

            this.osVersion = templateList[i].osVersion;
            console.log('templateList[i].osVersion', templateList[i].osVersion);

            this.TemplateList_forReexe.push({
              capabilityTemplateName: templateList[i].capabilityTemplateName
            });
            this.UserTemplateName_forReexe = [
              {
                capabilityTemplateName:
                  templateList[i].usrCapabilityTemplateName
              }
            ];
          }
          console.log(
            'this.TemplateList_forReexe------- ',
            this.TemplateList_forReexe
          );
          this.df_devices_template_list = this.TemplateList_forReexe;

          console.log(
            'this.UserTemplateName_forReexee------- ',
            this.UserTemplateName_forReexe
          );

          //let capabilityIdValuesViews_res = await this.dataservice.validateCapabilityTemplate(templateListData)
          for (let i = 0; i < this.templateListData.length; i++) {
            console.log(
              'in templateListData[i].usrCapabilityTemplateName',
              templateList[i].usrCapabilityTemplateName
            );
            await this.dataservice
              .getcapabilityTemplateByName(
                templateList[i].usrCapabilityTemplateName
              )
              .toPromise()
              .then(res => {
                console.log(res);
                const capability_result = [];
                if (res == null) {
                  console.log('in show error');
                  this.toastr.error(
                    'Capability Template removed, hence cannot re-execute. Please re-execute with changes with a new capability template.'
                  );
                  return;
                }
                let capabilityIdValuesViews_res =
                  res['capabilityIdValuesViews'];

                const map = new Map();
                for (const item of capabilityIdValuesViews_res) {
                  if (!map.has(item.capabilityId)) {
                    map.set(item.capabilityId, true); // set any value to Map
                    capability_result.push({
                      capTemplateId: item.capTemplateId,
                      capabilityId: item.capabilityId,
                      capabilityName: item.capabilityName,
                      capabilityValue: item.capabilityValue,
                      platformId: item.platformId
                    });
                  }
                }
                console.log('capability_result....', capability_result);
                this.dup_capTemplate = this.dataservice.checkIfCapabilityTemplateHasDuplicates(
                  capability_result
                );
                console.log('this.dup_capTemplate', this.dup_capTemplate);
                if (this.dup_capTemplate != null) {
                  this.toastr.error(this.dup_capTemplate);
                  return;
                }

                this.cor_capTemplate = this.dataservice.checkIfCapabilityTemplateIsCorrputed(
                  capability_result
                );
                console.log('this.cor_capTemplate', this.cor_capTemplate);
                if (this.cor_capTemplate != null) {
                  this.toastr.error(this.cor_capTemplate);
                  return;
                }
              });
          }

          this.dataservice
            .getSelectedSuitedata(
              headerId_fromHistory,
              historyId_fromHistory
            )
            .toPromise()
            .then(async suiteList => {
              console.log('suiteList-----', suiteList);
              this.testSuiteList = suiteList;
              this.selectedSuiteList = this.testSuiteList.map(
                suite => suite.testSuiteId
              );

              console.log('in reexecution', this.selectedSuiteList);

              let temp_test_case;
              this.testSuiteList.forEach(element => {
                element.IsSelected = true;
                console.log(
                  'element.testCases_with history',
                  element.testCases
                );
                temp_test_case = element.testCases;
                // let temp_testCase_list= this.testCaseList;
                const testCases_reslt = [];
                const map = new Map();
                for (const item of temp_test_case) {
                  if (!map.has(item.testCaseId)) {
                    map.set(item.testCaseId, true); // set any value to Map
                    testCases_reslt.push({
                      brandId: item.brandId,
                      modifiedBy: item.modifiedBy,
                      modifiedOn: item.modifiedOn,
                      projectName: item.projectName,
                      projectTypeId: item.projectTypeId,
                      regionId: item.regionId,
                      testCaseDesc: item.testCaseDesc,
                      testCaseId: item.testCaseId,
                      testCaseName: item.testCaseName,
                      testCaseNumber: item.testCaseNumber,
                      testSuiteId: item.testSuiteId,
                      testSuiteName: item.testSuiteName,
                      total_Number_Of_TestSteps: item.total_Number_Of_TestSteps,
                      userId: item.userId
                    });
                  }
                }

                element.testCases = testCases_reslt;
                element.total_Test_Case = testCases_reslt.length;
              });
              // console.log('temp_test_case----=-sass',temp_test_case);

              if (this.testSuiteList.length >= 0) {
                // platformName
              }
              this.selectAllSuite();
              if (
                this.testSuiteList != null &&
                this.testSuiteList != undefined &&
                this.testSuiteList.length > 0
              ) {
                this.dataservice
                  .getEnvironmentdetails(
                    headerId_fromHistory,
                    historyId_fromHistory
                  )
                  .toPromise()
                  .then(environmentDetails => {
                    let enviDetails: any;
                     
                    enviDetails = environmentDetails;
                    console.log('environmentDetails', enviDetails);
                    this.AppVersionName = enviDetails.version;
                    this.txtjobName = enviDetails.testExecutionName;
                    this.deviceType =
                      enviDetails.executionMode == Constvar.AWS
                        ? Constvar.DeviceFarm
                        : enviDetails.executionMode == Constvar.Local
                        ? Constvar.Local
                        : enviDetails.executionMode == Constvar.Headspin
                        ? Constvar.Headspin
                        : enviDetails.executionMode == Constvar.BrowserStack
                        ? Constvar.BrowserStack
                        : Constvar.Server;
                    console.log(enviDetails);
                    this.environment_type = enviDetails.environment;
                    this.AppName = enviDetails.appName;
                    this.mobileAppPath_Skipped = enviDetails.appName;
                    this.oldMobile_app_path = enviDetails.appName;
                    this.reExeEnvDetails = enviDetails;
                    this.SelectedDevicePool_arn = this.reExeEnvDetails.devicepoolArn;
                    this.appArn = this.reExeEnvDetails.mobileAppFileName;
                    this.projectTypeName = this.reExeEnvDetails.projectTypeName;
                    this.projectTypeId = this.reExeEnvDetails.projectTypeId;
                    this.regionId = this.reExeEnvDetails.regionId;
                    this.brandId = this.reExeEnvDetails.brandId;
                    this.brandName = this.reExeEnvDetails.brandName;
                    this.projectName = this.reExeEnvDetails.projectName;
                    this.languageCode = enviDetails.language;
                    this.localeCapabilityValue = enviDetails.locale;
                    this.isHSAnyDeviceSelected = enviDetails.hsAnyDevice;
                    this.appId = enviDetails.appId; 
                    console.log(
                      ' this.isHSAnyDeviceSelected',
                      this.isHSAnyDeviceSelected
                    );
                    console.log('language code', this.languageCode);
                    this.platform = enviDetails.platformName;
                    this.platformId = enviDetails.platformId;
                    if (this.projectTypeName == 'Mobile') {
                      this.strAvailable =
                        this.deviceType == Constvar.Headspin ||
                        this.deviceType == Constvar.BrowserStack
                          ? 'Select upto 3 devices below OR'
                          : 'Select Devices';
                      this.strTitle = 'Select Devices';
                      this.deviceBrowserName = 'Device Name';
                      // this.strAvailable = 'Available Devices';
                      this.isMobile = true;
                    } else if (this.projectTypeName == 'Web') {
                      this.strTitle = 'Select Browsers';
                      this.deviceBrowserName = 'Browser Name';
                      this.strAvailable = 'Available Browsers';
                      this.isMobile = false;
                    }
                    console.log(
                      'this.appArn------in re execution',
                      this.appArn
                    );
                    console.log(
                      'project type name',
                      this.reExeEnvDetails.projectTypeName
                    );
 
                    if (enviDetails.executionMode == Constvar.AWS) {
                      this.executionMode = enviDetails.executionMode;
                      this.ByUpload = Constvar.Using_Arn;
                      console.log('Constvar.Using_Arn', Constvar.Using_Arn);
                      this.getValue('a');
                      this.capabilitiesByBrand = [];
                      this.dataservice
                        .getAppCapabilitiesByBrand(
                          this.brandId,
                          this.environment_type,
                          this.platformId
                        )
                        .subscribe(
                          getappActAppPack => {
                            this.capabilitiesByBrand = getappActAppPack;
                          },
                          error => {
                            this.toastr.error(error);
                          }
                        );
                    } else if (enviDetails.executionMode == Constvar.Headspin) {
                      this.executionMode = enviDetails.executionMode;
                      this.getValue('a');
                      this.capabilitiesByBrand = [];
                      this.dataservice
                        .getAppCapabilitiesByBrand(
                          this.brandId,
                          this.environment_type,
                          this.platformId
                        )
                        .subscribe(
                          getappActAppPack => {
                            this.capabilitiesByBrand = getappActAppPack;
                          },
                          error => {
                            this.toastr.error(error);
                          }
                        );
                    } else if (
                      enviDetails.executionMode == Constvar.BrowserStack
                    ) {
                      this.executionMode = enviDetails.executionMode;
                      this.getValue('a');
                      this.capabilitiesByBrand = [];
                      this.dataservice
                        .getAppCapabilitiesByBrand(
                          this.brandId,
                          this.environment_type,
                          this.platformId
                        )
                        .subscribe(
                          getappActAppPack => {
                            this.capabilitiesByBrand = getappActAppPack;
                          },
                          error => {
                            this.toastr.error(error);
                          }
                        );
                    }
                  })
                  .catch(err => {
                    console.log(err);
                  });
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
      .catch(err => {
        console.log(err);
        this.toastr.error('Something went wrong');
      });
    console.log('history json', this.jsonExecutiontbl);
  }
  checkFailedSuite() {
    this.selectedSuiteList = [];

    this.testSuiteList.forEach(element => {
      if (element.testSuiteStatus == 3) {
        element.IsSelected = true;
        this.selectedSuiteList.push(element.testSuiteId);
      } else {
        element.IsSelected = false;
      }
    });

    let IsAllFlag = false;
    for (let k = 0; k < this.testSuiteList.length; k++) {
      if (this.testSuiteList[k].IsSelected) {
        $('#ch' + k).prop('checked', true);
        $('#All').prop('checked', false);
      } else {
        $('#ch' + k).prop('checked', false);
      }
      if ($('#ch' + k).prop('checked') == true) {
      } else {
        IsAllFlag = true;
      }
    }
    if (IsAllFlag == true) {
      $('#All').prop('checked', false);
    } else {
      $('#All').prop('checked', true);
    }
    this.jsonExecutiontbl = [];
    for (let k = 0; k < this.testSuiteList.length; k++) {
      if (this.testSuiteList[k].IsSelected) {
        $('#ch' + k).prop('checked', true);
        this.jsonExecutiontbl.push({
          testSuiteName: this.testSuiteList[k].testSuiteName,
          testSuiteId: this.testSuiteList[k].testSuiteId,
          templateNameList:
            this.isFromHistory == true
              ? this.TemplateList_forReexe
              : this.ListOfCapabilities,
          testCases: [
            {
              dataSetNameList: []
            }
          ]
        });
      }
    }
    console.log(this.jsonExecutiontbl);
  }

  selectAllSuite() {
    if (this.testSuiteList != null) {
      if (this.isFromHistory == true) {
        $('#All').prop('checked', true);
        this.jsonExecutiontbl = [];
        for (let k = 0; k < this.testSuiteList.length; k++) {
          $('#ch' + k).prop('checked', true);
          this.jsonExecutiontbl.push({
            testSuiteName: this.testSuiteList[k].testSuiteName,
            testSuiteId: this.testSuiteList[k].testSuiteId,
            templateNameList: this.isFromHistory
              ? this.TemplateList_forReexe
              : this.ListOfCapabilities,
            testCases: [
              {
                dataSetNameList: []
              }
            ]
          });
        }
      } else {
        console.log('from normal execution' + this.testCaseList);
        this.jsonExecutiontbl = [];
        for (let k = 0; k < this.testSuiteList.length; k++) {
          $('#ch' + k).prop('checked', false);
        }
      }
    }
  }

  getUploadedApps(isfromRefresh)
  {
    this.dataservice.getUploadedAppFile(this.brandId,this.environment_type,this.platformId).subscribe(
      res => {
        console.log('new res.....', res);
         
        this.appDataByBrandandEnv=res;
        console.log('this.appDataByBrandandEnv.....',  this.appDataByBrandandEnv);
        if (isfromRefresh) {
          this.toastr.success('App list is updated!');
        }
      },
      error => {
        console.log(error.message);
        this.toastr.error('Something went Wrong');
      }
    );
  }

  getTempCap(templateName, isfromSelectTemplateName) {
    this.checkMandatoryCapabilities(templateName, isfromSelectTemplateName);
  }
  isEnabled(val) {
    if (val.trim() != '' && val != undefined && val != null) {
      if(this.deviceType == Constvar.Headspin){
        return this.defaultCapability.includes(val) &&
        this.deviceType == Constvar.Headspin
        ? false
        : true;
      }
      else{
        return this.defaultCapability.includes(val) &&
        this.deviceType == Constvar.BrowserStack
        ? false
        : true;
      }
      
    } else {
      return true;
    }
  }
  ngOnDestroy() {
    this.serverDevices = [];
    if (!this.ref['destroyed']) {
      this.ref.detectChanges();
    }
    /*-------------------End Function-------------*/
  }
}
