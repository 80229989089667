export enum Constvar {
    AWS = 'AWS',
    Local = 'Local',
    BrowserStack = 'BrowserStack',
    DeviceFarm = 'DeviceFarm',
    LocalBrowserStack ='LocalBrowserStack',
    Headspin = 'Headspin',
    Server = 'Server',
    Using_Arn = 'Using_Arn',
    AndroidApptype ='ANDROID_APP',
    IosApptype = 'IOS_APP',
    SucceedStatus ='SUCCEEDED',
    Android = 'Android',
    Ios= 'Ios',
    AllPlatform = 'All',
    Mobile='Mobile',
    MissingBatchFile = "'getalldevices.bat' is not recognized as an internal or external command",
    FinishedFailure = 'Finished: FAILURE',
    FinishedSuccess = 'Finished: SUCCESS',
    locale='locale',
    CreatedBy ="CreatedBy",
    CreatedOn = "CreatedOn",
    ModifiedBy = "ModifiedBy",
    ModifiedOn = "ModifiedOn",
    localeUS ="US",
    deviceAlreadyUnlock ='Device is already unlocked.',
    lockDevice ='Device unlocked.',
    availableDevice = 'Available',
    busyDevice ='Busy',
    deviceUnlocked = 'Device unlocked.',
    anyDeviceAppiumUrl = 'https://appium-dev.headspin.io/v0/56622c0bd8d34035a7e2904d64d83dec/wd/hub',
    bsAppiumUrl = 'https://prodigioadmin_Qzug7NX:ULUVSnvdpoeTb4a32VZc@hub-cloud.browserstack.com/wd/hub',
    testSpecOutput = 'Test spec output',
    WTAFBackend= 'WTAF-Backend',
    API='API'
}
