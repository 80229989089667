<div class="row marginCss mt-2"> <span class="breadcrumCss">Home /Execution</span></div>
<div id="stepper1" class="bs-stepper">
    <div class="bs-stepper-header d-flex justify-content-between col-md-12 col-lg-12 col-sm-12">
        <div [ngClass]="this.ValidSteps[0]['step0']==true ? 'enabled-steps': 'disabled-steps'"
            class="step col-md-2 no-pad" data-target="#test-l-1" id="step0">
            <button (click)="GetCurrentStep(0)" class="step-trigger">
                <span id="step_no_0" class="bs-stepper-circle">1</span>
                <span class="bs-stepper-label">Select Environment</span>
            </button>
        </div>
        <div [ngClass]="this.ValidSteps[0]['step1']==true? 'enabled-steps': 'disabled-steps'"
            class="step col-md-2 no-pad" data-target="#test-l-3" aria-disabled="true" id="step1" disabled="disabled">
            <button [ngClass]="this.ValidSteps[0]['step1']==true? 'enabled-steps': 'pointer-events-none'"
                (click)="GetCurrentStep(1)" class="step-trigger">
                <span id="step_no_1" class="bs-stepper-circle">2</span>
                <span class="bs-stepper-label">Select Test Suites</span>
            </button>
        </div>
        <div [ngClass]="this.ValidSteps[0]['step2']==true? 'enabled-steps': 'disabled-steps'"
            class="step col-md-3 no-pad col-md-3_custom " data-target="#test-l-4" id="step2">
            <button [ngClass]="this.ValidSteps[0]['step2']==true? 'enabled-steps': 'pointer-events-none'"
                (click)="GetCurrentStep(2)" class="step-trigger">
                <span id="step_no_2" class="bs-stepper-circle">3</span>
                <span class="bs-stepper-label">Select Dataset & Test Cases </span>
            </button>
        </div>
        <div [ngClass]="this.ValidSteps[0]['step3']==true? 'enabled-steps': 'disabled-steps'"
            class="step col-md-2 no-pad " data-target="#test-l-2" id="step3">
            <button [ngClass]="this.ValidSteps[0]['step3']==true? 'enabled-steps': 'pointer-events-none'"
                (click)="GetCurrentStep(3)" class="step-trigger">
                <span id="step_no_3" class="bs-stepper-circle">4</span>
                <span class="bs-stepper-label">{{this.strTitle}}</span>
            </button>
        </div>
        <div [ngClass]="this.ValidSteps[0]['step4']==true? 'enabled-steps': 'disabled-steps'"
            class="step col-md-2 no-pad " data-target="#test-l-5" id="step4">
            <button [ngClass]="this.ValidSteps[0]['step4']==true? 'enabled-steps': 'pointer-events-none'"
                (click)="GetCurrentStep(4)" class="step-trigger">
                <span id="step_no_4" class="bs-stepper-circle">5</span>
                <span class="bs-stepper-label">Review & Run </span>
            </button>
        </div>
    </div>

    <div class="bs-stepper-content" style="background-color: white;">
        <div id="test-l-1" class="content p-2" style=" background-color: white;">
            <div class="text-center errorMessage headermsg" *ngIf="this.IsjobName_error">{{this.IsjobName_error}}
            </div>

            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="col-md-12" style="padding-top: 35px;">
                        <div class="form-row align-right_ex exc_mr-10">
                            <span class="col-md-5 col-form-label lbl_exec executionTxtCss">Execution Mode*</span>
                            <select [disabled]="this.isFromHistory == true" class="form-control txtControlCss col-md-7"
                                (change)="getValue($event)" [(ngModel)]='deviceType'>
                                <option hidden value="select" disabled selected>Select</option>
                                <!-- <option value="DeviceFarm">DeviceFarm</option> -->
                                <option value="BrowserStack">BrowserStack</option>
                                <option value="Headspin">Headspin</option>
                                <option value="Local">Local</option>
                                <option value="LocalBrowserStack">LocalBrowserStack</option>
                                <option disabled value="Server">Server</option>
                            </select>
                        </div>
                        <!-- {{deviceType}} -->
                        <div class="form-row align-right_ex exc_mr-10">
                            <span class="col-md-5 lbl_exec col-form-label executionTxtCss">Environment*</span>
                            <select (change)="GetEnv_type($event)" [(ngModel)]='environment_type'
                                [disabled]="this.isFromHistory == true" class="form-control txtControlCss col-md-7">
                                <option hidden value="select" disabled selected>Select</option>
                                <option value="STG">STG</option>
                                <option value="Dev">Dev</option>
                                <option value="Prod">Prod</option>
                                <option value="QA">QA</option>
                            </select>
                        </div>
                        <!-- {{this.environment_type}} -->
                        <div class="form-row align-right_ex exc_mr-10">
                            <span class="col-md-5 col-form-label lbl_exec executionTxtCss">Testing Type*</span>
                            <select (change)="GetAppVersion($event)" [(ngModel)]='AppVersionName'
                                [disabled]="this.isFromHistory == true" class="form-control txtControlCss col-md-7" id="AppVersionName" name="AppVersionName">
                                <option hidden value="select" disabled selected>Select</option>
                                <!-- <option value="default-version">Default-Version</option> -->
                                <option value="Smoke">Smoke</option>
                                <option disabled value="CICD">CICD</option>
                                <option value="Regression">Regression</option>
                                <option value="Compatibility">Compatibility</option>
                                <option value="Localization">Localization</option>
                                <option value="trial-run">Trial-Run</option>
                            </select>
                        </div>
                        <!-- <div class="form-row align-right_ex exc_mr-10">
                            <span class="col-md-5 col-form-label lbl_exec executionTxtCss">App Name*</span>
                            <input type="text" [(ngModel)]='AppName' [disabled]="this.isFromHistory == true"
                                placeholder="Enter App Name"
                                class="form-control col-md-7 txtControlCss" id="AppName" name="AppName" />
                        </div> -->
                        <!-- {{AppVersionName}} -->
                        <div class="form-row align-right_ex exc_mr-10">
                            <span class="col-md-5 lbl_exec col-form-label executionTxtCss">Test Execution Name*</span>
                            <input type="text" [(ngModel)]='txtjobName' [disabled]="this.isFromHistory == true"
                                placeholder="Enter Test Execution Name" placeholder="Enter Test Execution Name"
                                class="form-control col-md-7 txtControlCss" id="jobName" name="jobName" />
                        </div>


                    </div>
                </div>
            </div>

        </div>
        <!-- Second Step -->
        <div id="test-l-2" class="content p-2" style=" background-color: white;">
            <div class="text-center errorMessage pt-2" *ngIf="this.capTempError">{{this.capTempError}}
            </div>
            <div class="text-center successMessage pt-2" *ngIf="this.nodeUpdate">{{this.nodeUpdate}}
            </div>
            <div class="text-center errorMessage headermsg" *ngIf="this.IsjobName_error">{{this.IsjobName_error}}
            </div>
            <div class="row mt-4" style="padding-top: 20px;" *ngIf="this.deviceType=='Local' || this.deviceType=='LocalBrowserStack'">
                <div class="col-md-5 text-right">
                    <span class="executionTxtCss">Machine Name</span>
                </div>
                <div class=" d-flex"
                    [ngClass]="{'col-md-5': deviceType === 'LocalBrowserStack', 'col-md-6':deviceType === 'Local' }">
                    <select [(ngModel)]='jenkinsNode' class="form-control txtControlCss col-md-6"
                        (change)="getSlaveName($event)">
                        <option hidden value="0" disabled selected>Select Node</option>
                        <option [value]="node.displayName"
                            *ngFor="let node of this.arrJenkinsSlave | orderBy:'displayName'"
                            [disabled]="node.offline === true" [style.color]="node.offline === false ? 'green' : 'red'">
                            {{node.displayName}} </option>
                    </select> &nbsp;&nbsp;
                    <div class="loadingDeviceDiv">
                        <img class="mb-1" (click)="refreshData()" *ngIf="this.dataservice.isDevicesLoading==false"
                            class="" style="cursor: pointer;" src="assets/images/refresh-ccw.png">
                    </div>
                </div>
            </div>

            <div class="row mt-4" *ngIf="this.deviceType=='Local' && this.projectTypeName != 'API'">
                <div class="col-md-5 text-right">
                    <span class="executionTxtCss">{{this.strTitle}}</span>
                </div>
                <div class="col-md-6">
                    <button type="button" (click)="getDevices()" class="btn clearBtnCss1">
                        <span *ngIf="this.dataservice.isDevicesLoading==false">{{this.strTitle}}</span>
                        <span title="Loading Devices" *ngIf="this.dataservice.isDevicesLoading==true">Loading...
                            <span style="color: #1F83FE;" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span></span>
                    </button>
                </div>
            </div>
           

            <div class="row mt-4" *ngIf="this.deviceType=='Local' && this.projectTypeName != 'API'">
                <div class="col-md-5 text-right">
                    <span class="executionTxtCss">Capabilities Template</span>
                </div>
                <div class="col-md-6">
                    <div class="">
                        <button id="btnCreateSuiteTemplate" type="button"
                            class="btn btn-primary clearBtnPopupCss2 btn-add_clr1"
                            (click)="createTemplatesModal()">Create</button>
                    </div>
                    <div class="col-md-12 mt-1" style="max-height: 80px;overflow-y: auto;cursor: pointer;"
                        *ngIf="this.isArnTemplate==false">
                        <ul class="list-group list-group-horizontal row">
                            <li class="list-group-item d-flex justify-content-between align-items-center col-md-6 mb-2"
                                *ngFor="let allcapabilities of ListOfCapabilities;">
                                <span class="listGroupLableCss" id="{{capabilityTemplateName}}"
                                    (click)="getTempCap(allcapabilities.capabilityTemplateName,true)">{{allcapabilities.capabilityTemplateName}}</span>
                                <span><img
                                        (click)="deletecapabilitytemplateByName(allcapabilities.capabilityTemplateName)"
                                        id="{{capabilityTemplateName}}" src="assets/images/close.png" /></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mt-4" *ngIf="this.deviceType=='DeviceFarm'">
                <div class="col-md-5 devicePoolDiv text-right">
                    <span class="executionTxtCss">Device Pool</span>
                </div>
                <div class="col-md-6 pl-0 pr-0 row">
                    <!-- Server Devices -->
                    <div class="col-md-5 selectDevicePoolDiv"  (change)="getAWSDevForCapability_Creation($event)">
                        <select [(ngModel)]="ddldevicepool" class="form-control txtControlCss">
                            <option hidden value="0" disabled selected>Select Device Pool</option>
                            <option [value]="devicepool.arn" *ngFor="let devicepool of this.serverDevicepools">
                                {{devicepool.name}}</option>
                        </select>
                    </div>
                    <div class="loadingDeviceDiv">
                        <button *ngIf="this.file != null || this.appId != ''" id="btnViewDevices"
                            style="border: 1px solid #1F83FE !important; background: white;" type="button">
                            <img class="mb-1" (click)="getServerDevices()"
                                *ngIf="this.dataservice.isDevicesLoading==false" class="" src="assets/images/eye.png">
                            <span title="Loading device pools" *ngIf="this.dataservice.isDevicesLoading==true">
                                <span style="color: #1F83FE;" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span></span>
                        </button> &nbsp;
                        <img class="mb-1" (click)="refreshDevicePoolData()"
                            *ngIf="this.dataservice.isDevicesLoading==false" style="cursor: pointer;"
                            src="assets/images/refresh-ccw.png">
                    </div>
                </div>
            </div>

            <div class="mt-3 row" *ngIf="this.deviceType=='DeviceFarm'">
                <div class="col-md-5 mt-2 text-right">
                    <span class="executionTxtCss  no-pad ">Select APK / IPA</span>
                </div>
                <div class="col-md-6">
                    <!-- Server Devices -->
                    <!-- <div class="custom-file" style="width: 44.5% !important;margin-right: -1;">
                        <input [disabled]="this.ByUpload=='Using_Arn' && Recentapps !='0'" type="file" accept="{{this.platform =='Android' ? '.apk' : '.ipa'}}"
                            style="height: 32px !important; border-right: 0px;" (change)="getFileUploadName($event)"
                            class="custom-file-input" #appfile id="appfile">
                        <label style=" height: 32px !important;font-size: 12px;border-right: 0px;overflow: hidden !important;
                        text-overflow: ellipsis !important;white-space: nowrap !important;" class="custom-file-label"
                            for="exampleInputFile">{{this.selectedAppFileName}}</label>
                    </div> -->
                    <!-- <span class="input-group-addon " style="margin-left: -4px;">
                        <button [disabled]="this.ByUpload=='Using_Arn' && Recentapps !='0' " id="btnViewDevices"
                            (click)="clearFiles()"
                            style="border: 1px solid #d4c4c4 !important; border-radius: 0px 3px 3px 0px; height: 32px; background: #ffffff;padding-bottom: 3px;"
                            type="button">
                            <i style="color: red;font-size: 14px;" class="fa fa-close"></i>
                        </button>
                    </span> -->
                    
                    <!-- <div class="d-flex justify-content-center col-md-6">
                        <span class="input-group-addon pad-10 executionTxtCss">OR</span>
                    </div> -->
                    <div class="col-md-8 mt-2 row" style="padding-right: 6px;">
                        <select *ngIf="this.deviceType=='DeviceFarm'" [(ngModel)]="Recentapps"
                            (change)="getRecentapp_arn($event)"
                            [disabled]="this.appDataByBrandandEnv!=null &&  this.file!=null"
                            class="form-control txtControlCss col-md-9">
                            <option hidden value="0" disabled selected>Recently Uploaded File</option>
                            <option *ngFor="let app of this.appDataByBrandandEnv" [value]="app.appId">{{app.fileName}}</option>
                        </select>

                        <div class="loadingDeviceDiv col-md-3">
                            <img class="mb-1" [hidden]="this.listofUploads!=null &&  this.file!=null"
                                (click)="refAppfile(true)" *ngIf="this.dataservice.isDevicesLoading==false" class=""
                                style="cursor: pointer;" src="assets/images/refresh-ccw.png">
                        </div>
                    </div>

                    <div class="col-md-12 mt-2" style="max-height: 80px;overflow-y: auto;cursor: pointer;"
                        *ngIf="this.isArnTemplate==false">
                        <ul class="list-group list-group-horizontal row ">
                            <li class="list-group-item d-flex justify-content-between align-items-center mt-1"
                                *ngFor="let allcapabilities of ListOfCapabilities;">
                                <span class="listGroupLableCss" id="{{capabilityTemplateName}}"
                                    (click)="getTempCap(allcapabilities.capabilityTemplateName,true)">{{allcapabilities.capabilityTemplateName}}</span>
                                <span class="ml-2"><img
                                        (click)="deletecapabilitytemplateByName(allcapabilities.capabilityTemplateName)"
                                        id="{{capabilityTemplateName}}" src="assets/images/close.png" /></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="mt-3 row"*ngIf="this.deviceType=='Headspin' || this.deviceType=='BrowserStack' || this.deviceType=='LocalBrowserStack'">
                <div class="col-md-5 mt-2 text-right">
                    <span class="executionTxtCss  no-pad ">Select APK / IPA</span>
                </div>
                <div class="col-md-6">
                    <!-- Server Devices -->
                     <div class="col-md-8 mt-2 row" style="padding-right: 6px;">
                        <select *ngIf="this.deviceType=='Headspin' || this.deviceType=='BrowserStack' || this.deviceType=='LocalBrowserStack'" [(ngModel)]="Recentapps"
                            (change)="getRecentapp_id($event)"
                            [disabled]="this.appDataByBrandandEnv!=null &&  this.file!=null"
                            class="form-control txtControlCss col-md-9">
                            <option hidden value="0" disabled selected>Recently Uploaded File</option>
                            <option *ngFor="let app of this.appDataByBrandandEnv"
                                [value]="app.appId">{{app.fileName}}</option>
                        </select>
                        <div class="loadingDeviceDiv col-md-3">
                            <img class="mb-1" [hidden]="this.appDataByBrandandEnv!=null &&  this.file!=null"
                                (click)="refAppfile(true)" *ngIf="this.dataservice.isDevicesLoading==false" class=""
                                style="cursor: pointer;" src="assets/images/refresh-ccw.png">
                        </div>
                    </div>
                    
                     <!-- <div class="d-flex justify-content-center col-md-6">
                        <span class="input-group-addon pad-10 executionTxtCss">OR</span>
                    </div> -->
                    <!-- <div class="custom-file" style="width: 44.5% !important;margin-right: -1;">
                        <input [disabled]="this.ByUpload=='Using_Arn' && Recentapps !='0'" type="file" accept="{{this.platform =='Android' ? '.apk' : '.ipa'}}"
                            style="height: 32px !important; border-right: 0px;" (change)="getFileUploadName($event)"
                            class="custom-file-input" #appfile id="appfile">
                        <label style=" height: 32px !important;font-size: 12px;border-right: 0px;overflow: hidden !important;
                        text-overflow: ellipsis !important;white-space: nowrap !important;" class="custom-file-label"
                            for="exampleInputFile">{{this.selectedAppFileName}}</label>
                    </div> -->
                    <!-- <span class="input-group-addon " style="margin-left: -4px;">
                        <button [disabled]="this.ByUpload=='Using_Arn' && Recentapps !='0' " id="btnViewDevices"
                            (click)="clearFiles()"
                            style="border: 1px solid #d4c4c4 !important; border-radius: 0px 3px 3px 0px; height: 32px; background: #ffffff;padding-bottom: 3px;"
                            type="button">
                            <i style="color: red;font-size: 14px;" class="fa fa-close"></i>
                        </button>
                    </span> -->
                </div>

            <!-- <div class="mt-3 row"*ngIf="this.deviceType=='Headspin'">
                <div class="col-md-5 mt-2 text-right">
                    <span class="executionTxtCss  no-pad ">Select APK / IPA</span>
                </div>
                <div class="col-md-6">
                     Server Devices -->
                    <!-- <div class="col-md-8 mt-2 row" style="padding-right: 6px;">
                        <select *ngIf="this.deviceType=='Headspin'" [(ngModel)]="Recentapps"
                            (change)="getRecentapp_id($event)"
                            [disabled]="this.allUploadedHeadspinFiles!=null &&  this.file!=null"
                            class="form-control txtControlCss col-md-9">
                            <option hidden value="0" disabled selected>Recently Uploaded File</option>
                            <option *ngFor="let app of this.allUploadedHeadspinFiles"
                                [value]="this.platform =='Android' ? app.apk_id : app.ipa_id">{{app.ts_created +' '+
                                app.hs_tag}}</option>
                        </select>
                        <div class="loadingDeviceDiv col-md-3">
                            <img class="mb-1" [hidden]="this.listofUploads!=null &&  this.file!=null"
                                (click)="refAppfile(true)" *ngIf="this.dataservice.isDevicesLoading==false" class=""
                                style="cursor: pointer;" src="assets/images/refresh-ccw.png">
                        </div>
                    </div>
                    
                    <div class="d-flex justify-content-center col-md-6">
                        <span class="input-group-addon pad-10 executionTxtCss">OR</span>
                    </div>
                    <div class="custom-file" style="width: 44.5% !important;margin-right: -1;">
                        <input [disabled]="this.ByUpload=='Using_Arn' && Recentapps !='0'" type="file" accept="{{this.platform =='Android' ? '.apk' : '.ipa'}}"
                            style="height: 32px !important; border-right: 0px;" (change)="getFileUploadName($event)"
                            class="custom-file-input" #appfile id="appfile">
                        <label style=" height: 32px !important;font-size: 12px;border-right: 0px;overflow: hidden !important;
                        text-overflow: ellipsis !important;white-space: nowrap !important;" class="custom-file-label"
                            for="exampleInputFile">{{this.selectedAppFileName}}</label>
                    </div>
                    <span class="input-group-addon " style="margin-left: -4px;">
                        <button [disabled]="this.ByUpload=='Using_Arn' && Recentapps !='0' " id="btnViewDevices"
                            (click)="clearFiles()"
                            style="border: 1px solid #d4c4c4 !important; border-radius: 0px 3px 3px 0px; height: 32px; background: #ffffff;padding-bottom: 3px;"
                            type="button">
                            <i style="color: red;font-size: 14px;" class="fa fa-close"></i>
                        </button>
                    </span>
                </div> -->

            </div> 
            <!-- <div class="row mt-4" *ngIf="this.deviceType=='Headspin'">
                <div class="col-md-5 text-right">
                    <span class="executionTxtCss">{{this.strTitle}}</span>
                </div>
                <div class="col-md-6" style="padding-right: 50px;">
                    <button type="button" (click)="getDeviceHeadspinDevicesList()" class="btn clearBtnCss1">
                        <span *ngIf="this.dataservice.isDevicesLoading==false">{{this.strTitle}}</span>
                        <span title="Loading Devices" *ngIf="this.dataservice.isDevicesLoading==true">Loading...
                            <span style="color: #1F83FE;" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span></span>
                    </button>
                    <div class="col-md-12 mt-2" style="max-height: 80px;overflow-y: auto;cursor: pointer;"
                    *ngIf="this.isArnTemplate==false">
                    <ul class="list-group list-group-horizontal row ">
                        <li class="list-group-item d-flex justify-content-between align-items-center mt-1"
                            *ngFor="let allcapabilities of ListOfCapabilities;">
                            <span class="listGroupLableCss" id="{{capabilityTemplateName}}"
                                (click)="getTempCap(allcapabilities.capabilityTemplateName,true)">{{allcapabilities.capabilityTemplateName}}</span>
                            <span class="ml-2"><img
                                    (click)="deletecapabilitytemplateByName(allcapabilities.capabilityTemplateName)"
                                    id="{{capabilityTemplateName}}" src="assets/images/close.png" /></span>
                        </li>
                    </ul>
                </div>
                </div>
            </div> -->
            <div class="row mt-4" *ngIf="this.deviceType=='Headspin'">
                <div class="col-md-5 text-right">
                    <span class="executionTxtCss">{{this.strTitle}}</span>
                </div>
                <div class="col-md-6" style="padding-right: 50px;">
                    <button type="button" (click)="getDeviceHeadspinDevicesList()" class="btn clearBtnCss1">
                        <span *ngIf="this.dataservice.isDevicesLoading==false">{{this.strTitle}}</span>
                        <span title="Loading Devices" *ngIf="this.dataservice.isDevicesLoading==true">Loading...
                            <span style="color: #1F83FE;" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span></span>
                    </button>
                    <div class="col-md-12 mt-2" style="max-height: 80px;overflow-y: auto;cursor: pointer;"
                    *ngIf="this.isArnTemplate==false">
                    <ul class="list-group list-group-horizontal row ">
                        <li class="list-group-item d-flex justify-content-between align-items-center mt-1"
                            *ngFor="let allcapabilities of ListOfCapabilities;">
                            <span class="listGroupLableCss" id="{{capabilityTemplateName}}"
                                (click)="getTempCap(allcapabilities.capabilityTemplateName,true)">{{allcapabilities.capabilityTemplateName}}</span>
                            <span class="ml-2"><img
                                    (click)="deletecapabilitytemplateByName(allcapabilities.capabilityTemplateName)"
                                    id="{{capabilityTemplateName}}" src="assets/images/close.png" /></span>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
            <div class="row mt-4" *ngIf="this.deviceType=='BrowserStack' || this.deviceType=='LocalBrowserStack'">
                <div class="col-md-5 text-right">
                    <span class="executionTxtCss">{{this.strTitle}}</span>
                </div>
                <div class="col-md-6" style="padding-right: 50px;">
                    <button type="button" (click)="getDeviceBrowserStackDevicesList()" class="btn clearBtnCss1">
                        <span *ngIf="this.dataservice.isDevicesLoading==false">{{this.strTitle}}</span>
                        <span title="Loading Devices" *ngIf="this.dataservice.isDevicesLoading==true">Loading...
                            <span style="color: #1F83FE;" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span></span>
                    </button>
                    <div class="col-md-12 mt-2" style="max-height: 80px;overflow-y: auto;cursor: pointer;"
                    *ngIf="this.isArnTemplate==false">
                    <ul class="list-group list-group-horizontal row ">
                        <li class="list-group-item d-flex justify-content-between align-items-center mt-1"
                            *ngFor="let allcapabilities of ListOfCapabilities;">
                            <span class="listGroupLableCss" id="{{capabilityTemplateName}}"
                                (click)="getTempCap(allcapabilities.capabilityTemplateName,true)">{{allcapabilities.capabilityTemplateName}}</span>
                            <span class="ml-2"><img
                                    (click)="deletecapabilitytemplateByName(allcapabilities.capabilityTemplateName)"
                                    id="{{capabilityTemplateName}}" src="assets/images/close.png" /></span>
                        </li>
                    </ul>
                </div>
                </div>
            </div>
        </div>
        <!-- Third Step -->
        <div id="test-l-3" class="content">
            <div class=" justify-content-between">
                <div class="row marginCss paddingCss whiteBackgroundCss mt-2">
                    <div class="row col-md-12  justify-content-center">
                        <div class="row col-md-12">
                            <div class="row col-md-12 mb-2">
                                <div class="form-group col-md-4">
                                    <label for="projectTypeId" class="txtControlLblCss">Project
                                        Type*</label>
                                    <select class="form-control txtControlCss" style="font-size: 12px;"
                                        [disabled]="this.isFromHistory == true" id="projectTypeId"
                                        (change)="changeProjectType($event)" [(ngModel)]="projectTypeId">
                                        <option hidden value="0" disabled selected>Select</option>
                                        <option [value]="projectType.projectTypeId"
                                            *ngFor="let projectType of this.projectTypeList">
                                            {{projectType.projectTypeName}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="regionId" class="txtControlLblCss">Region*</label>
                                    <select class="form-control txtControlCss" style="font-size: 12px;" id="regionId"
                                        (change)="changeRegion($event)" [(ngModel)]="regionId"
                                        [disabled]="this.isFromHistory == true">
                                        <option hidden value="0" disabled selected>Select</option>
                                        <option [value]="region.regionId" *ngFor="let region of this.regionList">
                                            {{region.regionCode}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="brandId" class="txtControlLblCss">Brand*</label>
                                    <select class="form-control txtControlCss" style="font-size: 12px;" id="brandId"
                                        [(ngModel)]="brandId" (change)="changeBrand($event)"
                                        [disabled]="this.isFromHistory == true">
                                        <option hidden value="0" disabled selected>Select</option>
                                        <option [value]="brand.brandId" *ngFor="let brand of this.brandList">
                                            {{brand.brandName}}</option>
                                    </select>
                                </div>

                            </div>
                            <div class="row col-md-12 mb-2">

                                <div class="form-group col-md-4">
                                    <label for="projectName" class="txtControlLblCss">Product Name*</label>
                                    <select class="form-control txtControlCss" style="font-size: 12px;" id="projectName"
                                        [(ngModel)]="projectName" (change)="changeProjectName($event)"
                                        [disabled]="this.isFromHistory == true">
                                        <option hidden value="" disabled selected>Select</option>
                                        <option [value]="projectName.projectName"
                                            *ngFor="let projectName of this.projectNameList">
                                            {{projectName.projectName}}</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-4" *ngIf="this.projectTypeName == 'Mobile'">
                                    <label for="projectTypeId" class="txtControlLblCss">Platform*</label>
                                    <select class="form-control txtControlCss" style="font-size: 12px;" id="platformId"
                                        [(ngModel)]="platformId" (change)="getTestSuiteByPlatform(platformId)"
                                        [disabled]="this.isFromHistory == true">
                                        <option value="0" disabled selected>Select</option>
                                        <option [value]="platform.platformId"
                                            *ngFor="let platform of this.platformList">
                                            {{platform.platformName}}</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="languageId" class="txtControlLblCss">Language<span>*</span></label>
                                    <select class="form-control txtControlCss" [disabled]="this.isFromHistory == true"
                                        [(ngModel)]="languageId" style="font-size: 12px;" id="languageId"
                                        (change)="changeLanguage($event)" name="languageId">
                                        <option hidden value="0" disabled selected>Select</option>
                                        <option [value]="language.languageId"
                                            *ngFor="let language of this.languageList">
                                            {{language.languageName}}</option>
                                    </select>
                                    <!-- <div *ngIf="this.submitted && f.languageId.errors" class="text-danger">
                                        <label *ngIf="f.languageId.errors.required">Please select the language</label>
                                    </div> -->
                                </div>
                            </div>
                            <div *ngIf="this.isFromHistory == false" class="row col-md-11 offset-1 mb-2">
                                <div class="form-group col-md-12">
                                    <span style="color: white;">. </span>
                                    <div [ngClass]="this.projectTypeName == 'Mobile' ? 'text-right': ''">

                                        <button type="button" class="btn clearBtnCss"
                                            (click)="ResetSuiteData(0)">Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.isFromHistory == true" class="row col-md-11  mb-2">
                                <div class="form-group col-md-12">
                                    <span style="color: white;">. </span>
                                    <div>

                                        <button type="button" class="btn clearBtnCss" (click)="checkFailedSuite()">Check
                                            failed Test Suite</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="max-height: 250px; width: 100%; overflow: auto;">

                        <table width="100%"
                            class="table  table-responsive  table-sticky table-striped table-bordered mt-4">
                            <thead>
                                <tr>
                                    <th class="checkth WrapInLine">
                                        <div class="row">
                                            <div>
                                                <label class="container_ ml-2">
                                                    <span class="mr-2"
                                                        style="font-size: 18px;margin-left: 4px;">Execute</span>
                                                    <input id="All" class="checkBoxClass"
                                                        (change)="setSelectedunseletedSuite(0, 0, $event, true)"
                                                        type="checkbox">
                                                    <span class="checkmark mt-2"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </th>
                                    <th class="WrapInLine">
                                        <span>Test Suite Name&nbsp;</span>
                                    </th>
                                    <th class="WrapInLine">
                                        <span>Appliance &nbsp;</span>

                                    </th>
                                    <th class="WrapInLine">
                                        <span>Test Case Count &nbsp;</span>
                                    </th>
                                    <th class="WrapInLine">
                                        <span>Modified On &nbsp;</span>

                                    </th>
                                    <th class="WrapInLine">
                                        <span>Modified By &nbsp;</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let suite of testSuiteList; let i=index">
                                    <td>
                                        <label class="container_ text-center" style="margin-left: 22px;">
                                            <input id="ch{{i}}" class="checkBoxClass" (change)="setSelectedunseletedSuite(suite.testSuiteId,
                                        suite.testSuiteName, $event, false)" [checked]="suite.IsSelected"
                                                type="checkbox">
                                            <span class="checkmark"></span>
                                        </label>
                                    </td>

                                    <td>{{suite.testSuiteName}}</td>
                                    <td>{{suite.applianceName}}</td>
                                    <td>{{suite.total_Test_Case}}</td>
                                    <td>{{suite.modifiedOn}}</td>
                                    <td>{{suite.modifiedBy}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Four Step -->

        <div id="test-l-4" class="content">
            <div class=" justify-content-between">
                <div class="row marginCss paddingCss whiteBackgroundCss mt-2">
                    <div class="row col-md-12">


                        <div class="col-md-12 d-flex justify-content-center">
                            <!--  (click)="GetDsAndCases(cnt.testSuiteId, cnt.testSuiteName, i)" -->
                            <div class="pagination" *ngIf="this.testSuiteList!=null">
                                <a style="border: none;" *ngIf="this.jsonExecutiontbl.length > 0"
                                    [class.disabled]="this.selectedtSuiteStep_stepper<=0"
                                    (click)="setNext_Prev(this.selectedtSuiteStep_stepper - 1)"><span>
                                        < </span> Prev </a> <a id="p{{i}}" (click)="handlePagination(i)" class="p_out"
                                    [class.active]="activeIndexPaginition === i"
                                    *ngFor="let cnt of this.jsonExecutiontbl; let i= index">
                                    {{i+1}}
                                </a>
                                <a style="border: none;" *ngIf="this.jsonExecutiontbl.length > 0"
                                    [class.disabled]="this.selectedtSuiteStep_stepper==this.jsonExecutiontbl.length-1"
                                    (click)="setNext_Prev(this.selectedtSuiteStep_stepper+1)">Next<span> > </span></a>
                            </div>
                        </div>
                        <div *ngIf="this.jsonExecutiontbl!=null">
                            <div class=" col-md-12">
                                <span class="txtControlLblCss"> <b class="lbl"> Total Suite Selected:</b>
                                    <span>{{this.jsonExecutiontbl.length==null? 0: this.jsonExecutiontbl.length
                                        }}</span>
                                </span>
                            </div>
                            <div class=" col-md-12">
                                <span class="txtControlLblCss"> <span class="lbl">
                                        {{this.selectedtSuiteStep_stepper + 1}}. Test
                                        Suite Name: </span>
                                    <span>{{this.selectedTestSuiteName_step}}</span> </span>
                            </div>
                        </div>
                    </div>

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-6"
                                style="max-height: 250px; width: 100%; overflow-y: auto;overflow-x: hidden;">
                                <span class="txtControlLblCss">Test Cases *</span>
                                <table width="100%"
                                    class="table  table-responsive  table-sticky table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="WrapInLine">
                                                <span>Sr. No.&nbsp;</span>
                                                <div class="inlineImages my-auto">
                                                    <img src="assets/images/up.png">
                                                    <img src="assets/images/down.png">
                                                </div>
                                            </th>
                                            <th class="WrapInLine">
                                                <span>Test Case Name &nbsp;</span>
                                                <div class="inlineImages my-auto">
                                                    <img src="assets/images/up.png">
                                                    <img src="assets/images/down.png">
                                                </div>
                                            </th>

                                            <th class="checkth WrapInLine">
                                                <div class="row">
                                                    <div>
                                                        <span style="margin-left: 24px;">Execute</span>
                                                    </div>
                                                    <div>
                                                        <label class="container_">
                                                            <input id="chkcaseAll" class="checkBoxClass" type="checkbox"
                                                                (change)="setSelectedunseletedCase(this.selectedtSuiteId_stepper, 0, '', $event, true, this.selectedtSuiteStep_stepper)">
                                                            <span class="checkmark"
                                                                style="margin-top: 3px;margin-left: 3px;"></span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let case of this.testCaseList; let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{case.testCaseName}}</td>
                                            <td>
                                                <label class="container_ text-center"
                                                    style="margin-left: 22px !important;">
                                                    <input id="chkcase{{i}}" class="checkBoxClass"
                                                        [checked]="case.IsSelected"
                                                        (change)="setSelectedunseletedCase(this.selectedtSuiteId_stepper, case.testCaseId, case.testCaseName, $event, false, this.selectedtSuiteStep_stepper)"
                                                        type="checkbox">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6" style="max-height: 250px; width: 100%; overflow: auto;">
                                <span class="txtControlLblCss">Data set*</span>
                                <table width="100%"
                                    class="table table-responsive  table-sticky table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="WrapInLine">
                                                <span>Sr. No.&nbsp;</span>
                                                <div class="inlineImages my-auto">
                                                    <img src="assets/images/up.png">
                                                    <img src="assets/images/down.png">
                                                </div>
                                            </th>
                                            <th class="WrapInLine">
                                                <span>Data Set Name &nbsp;</span>
                                                <div class="inlineImages my-auto">
                                                    <img src="assets/images/up.png">
                                                    <img src="assets/images/down.png">
                                                </div>
                                            </th>

                                            <th class="checkth WrapInLine">
                                                <div class="row">
                                                    <div>
                                                        <span style="margin-left: 25px;">Select</span>
                                                    </div>
                                                    <div>
                                                        <label class="container_">
                                                            <input id="chkDsAll" class="checkBoxClass"
                                                                (change)="setSelectedunseletedDs(this.selectedtSuiteId_stepper,'', $event, true, this.selectedtSuiteStep_stepper)"
                                                                type="checkbox">
                                                            <span class="checkmark"
                                                                style="margin-top: 3px;margin-left: 14px;"></span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let ds of this.dataset_list; let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{ds.dataSetName}}</td>
                                            <td>
                                                <label class="container_ text-center">
                                                    <input [checked]="ds.IsSelected" id="chkDs{{i}}" type="checkbox"
                                                        class="checkBoxClass"
                                                        (change)="setSelectedunseletedDs(this.selectedtSuiteId_stepper,ds.dataSetName, $event, false, this.selectedtSuiteStep_stepper)">
                                                    <span class="checkmark "
                                                        style="margin-left: 10px !important; "></span>
                                                </label>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </div>

                <div class="col-md-12" style="padding-right: 0px;">
                    <!-- ------------3rd -->
                </div>
            </div>




        </div>


        <div id="test-l-5" class="content" style=" background-color: white;padding: 20px;">
            <div class="row col-md-12">
                <div class=" col-md-3">
                    <div>
                        <span class="executionTxtCss">Test Run Name</span>
                    </div>
                    <div class="border-bottom">
                        <span>{{this.txtjobName}}</span>
                    </div>
                </div>

                <div class=" col-md-3">
                    <div>
                        <span class="executionTxtCss">Execution Mode</span>
                    </div>
                    <div class="border-bottom">
                        <span>{{this.executionMode}}</span>
                    </div>
                  
                </div>

                <div class=" col-md-3">
                    <div>
                        <span class="executionTxtCss">Environment</span>
                    </div>
                    <div class="border-bottom">
                        <span>{{this.environment_type}}</span>
                    </div>
                </div>

                <div class="col-md-3" *ngIf="this.deviceType=='Headspin'">
                  
                    <div>
                        <span class="executionTxtCss">{{!isHSAnyDeviceSelected ? 'Selected Devices' :'OS Version'}}</span>
                    </div>

                    <ng-container *ngIf='!isHSAnyDeviceSelected && (this.selectedHeadspinDevices.length > 0)'>
                    <div  *ngFor='let selectedDevices of this.selectedHeadspinDevices' class="border-bottom">
                        <span>{{selectedDevices.deviceName + '('+ selectedDevices.osVersion+')'}}</span>
                        <!-- <span *ngFor='let selectedDevices of this.selectedHeadspinDevices'>{{selectedDevices.device_skus[0] + ','}}</span> -->
                    </div>
                </ng-container>

                <ng-container *ngIf='isHSAnyDeviceSelected'>
                    <div class="border-bottom">
                        <span>{{this.osVersion}}</span>
                    </div>
                </ng-container>

                <ng-container *ngIf='!isHSAnyDeviceSelected  && (this.replacedTemplateList.length > 0)
                '>
                <div *ngFor='let selectedDevices of this.replacedTemplateList'  class="border-bottom">
                    <span>{{selectedDevices.capabilityTemplateName + '('+ selectedDevices.osVersion+')'}}</span>
                    <!-- <span *ngFor='let selectedDevices of this.replacedTemplateList'>{{selectedDevices.capabilityTemplateName+ ','}}</span> -->
                </div>
                </ng-container>

                <!-- <ng-container *ngIf='isHSAnyDeviceSelected'>
                    <div class="border-bottom">
                        <span>{{this.osVersion}}</span>
                    </div>
                </ng-container> -->
                 <!-- </ng-container> -->
                </div>

                <!-- <div class="col-md-3" *ngIf="this.deviceType=='Headspin'">
                   
                </div> -->
                

                <div class=" col-md-3" *ngIf="this.machineName!=''">
                    <div>
                        <span class="executionTxtCss">Running On Machine</span>
                    </div>
                    <div class="border-bottom">
                        <span>{{this.machineName}}</span>
                    </div>
                </div>
            </div>
            <div class="row col-md-12 mt-2">
                <div class=" col-md-3" *ngIf="this.brandName!=''">
                    <div>
                        <span class="executionTxtCss">Brand</span>
                    </div>
                    <div class="border-bottom">
                        <span>{{this.brandName}}</span>
                    </div>
                </div>

                <div class=" col-md-3" *ngIf="this.AppVersionName!=''">
                    <div>
                        <span class="executionTxtCss">Application Version</span>
                    </div>
                    <div class="border-bottom">
                        <span>{{this.AppVersionName}}</span>
                    </div>
                </div>

                <div class="col-md-3">
                    <div>
                        <span class="executionTxtCss">Capability</span>
                    </div>
                    <div class="col-md-12  border-bottom" style="max-height: 80px;overflow-y: auto;cursor: pointer;"
                        *ngIf="this.isArnTemplate==false">
                        <ul class="list-group list-group-horizontal row">
                            <li class="list-group-item d-flex justify-content-between align-items-center col-md-12 mb-2"
                                *ngFor="let allcapabilities of ListOfCapabilities;">
                                <span class="listGroupLableCss" id="{{capabilityTemplateName}}"
                                    (click)="getTempCap(allcapabilities.capabilityTemplateName,true)">{{allcapabilities.capabilityTemplateName}}</span>
                                <span><img
                                        (click)="deletecapabilitytemplateByName(allcapabilities.capabilityTemplateName)"
                                        id="{{capabilityTemplateName}}" src="assets/images/close.png" /></span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3" *ngIf="this.deviceType=='Headspin' || this.deviceType=='BrowserStack' || this.deviceType=='LocalBrowserStack' && this.AppName != ''">
                    <div>
                        <span class="executionTxtCss">Mobile App</span>
                    </div>
                    <div class="border-bottom">
                        <span>{{this.AppName}}</span>
                    </div>
                </div>
         

            </div>


            <div class="col-md-12 mt-4">
                <span class="executionTxtCss">Selected Test Suite ({{this.jsonExecutiontbl.length==undefined? 0:
                    this.jsonExecutiontbl.length}}) & Test Cases({{this.TotalSelectedTestCases}})</span>
            </div>
            <!-- ---------------2nd *ngIf="this.jsonExecutiontbl.length > 0 " -->
            <div class="col-md-12 marginLeftRightCss pl-0 whiteBackgroundCss mt-2">
                <div class="">
                    <table width="100%" id="tblTesttable" class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th class="WrapInLine" width="70px">
                                    <span> Sr.No &nbsp;</span>
                                </th>
                                <th class="WrapInLine">
                                    <span>Test Suite &nbsp;</span>

                                </th>
                                <th class="WrapInLine">
                                    <span>Test Case Selected &nbsp;</span>

                                </th>
                                <th class="WrapInLine">
                                    <span>Data set &nbsp;</span>

                                </th>
                                <th class="col-delete WrapInLine">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <span><i (click)="hideShowtableRow(row.testSuiteId)"
                                class="clickable" id="rr{{row.testSuiteId}}" class="caret"></i></span> -->
                            <ng-container *ngFor="let row of  this.jsonExecutiontbl ; let i=index">
                                <tr>
                                    <td width="70px" id='TS{{row.testSuiteId}}' class="closeaccord"
                                        (click)="hideShowtableRow(row.testSuiteId)">
                                        > {{i+1}}</td>
                                    <td> {{row.testSuiteName}}</td>
                                    <td>{{row.testCases.length==1 && row.testCases[0].testCaseId==undefined? 0:
                                        row.testCases.length}}
                                    </td>
                                    <td>
                                        <span *ngIf="row.testCases!=undefined && row.testCases.length>0">
                                            <span *ngFor="let ds of row.testCases[0].dataSetNameList; let m=index">
                                                {{ds.dataSetName}}
                                                <span *ngIf="(m+1) < row.testCases[0].dataSetNameList.length">
                                                    , </span>
                                            </span>
                                        </span>

                                    </td>

                                    <td class="col-delete WrapInLine">
                                        <a (click)="deleteTestSuite(row.testSuiteId)">
                                            <img src="assets/images/trash.png">
                                        </a>
                                    </td>
                                </tr>
                                <!-- <tr id="nn{{row.testSuiteId}}" class="out">
                                    <td colspan="5">
                                        <table id="casetableExecution" class="table" width="100%">
                                            <tbody>
                                                <tr class="tblexec-row" *ngFor="let cases of row.testCases">
                                                    <td class="tblexec-td" width="40%"></td>
                                                    <td class="tblexec-td">
                                                        {{cases.testCaseName}}
                                                    </td>
                                                    <td class="tblexec-td col-delete-nested WrapInLine">
                                                        <a (click)="deleteTestCase(row.testSuiteId, cases.testCaseId)">
                                                            <img src="assets/images/trash.png">
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr> -->
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-end pb-4 mr-2" style="margin-top: 7%;">

            <button type="button" class="btn previousBtnCss mr-2" (click)="executionCancel(1)"
                *ngIf="active_step==4">Cancel</button>
            <button (click)="previous()" *ngIf="active_step!=0 " type="button"
                class="btn previousBtnCss mr-2">Previous</button>
            <button class="btn btn-primary nextBtnCss mr-2" (click)="scheduleModal()"
                *ngIf="active_step==4 &&  this.jsonExecutiontbl.length>0">Schedule</button>
            <button class="btn btn-primary nextBtnCss mr-2" id="startExeBtnView" (click)="save_startExecution_Details()"
                *ngIf="active_step==4 &&  this.jsonExecutiontbl.length>0">

                <span *ngIf="this.dataservice.isDevicesLoading==false">Start Execution</span>
                <span title="Loading Devices"
                    *ngIf="this.dataservice.isDevicesLoading==true && scheduleFlag==false">Executing...
                    <span style="color: white;" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span></span>

            </button>
            <button (click)="next()" *ngIf="active_step!=4 " class="btn btn-primary nextBtnCss">Next Step</button>
        </div>
    </div>
</div>
<!-- The Create Template Modal -->
<form [formGroup]="capabilitytemplateForm" (ngSubmit)="savecapabilityTemplate()">
    <div class="modal" style="overflow-y: scroll;" id="createTemplateModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="headerModal commonBorderBottom marginLeftRightCss row mt-2 pt-2">
                    <div class="ml-3">
                        <h4 class="popupTitleTxt">Capability Template</h4>
                    </div>
                    <div class="ml-auto mr-3">
                        <button type="button" class="close" (click)=" closeModal()">&times;</button>
                    </div>
                </div>
                <div class="col-md-12 ">
                    <div class="text-center errorMessage headermsg" *ngIf="this.msg_capTemplate">
                        {{this.msg_capTemplate}}</div>
                    <div class="text-center errorMessage headermsg" *ngIf="this.addCapSuccess">{{this.addCapSuccess}}
                    </div>
                </div>
                <!-- Modal body -->
                <div class="modal-body">
                    <div class="text-danger text-center errorMessage" *ngIf="this.errorMessage">Capability Template Name
                        is already
                        in use.</div>
                    <div class="text-danger text-center errorMessage" *ngIf="this.capabilityError ">Capability is
                        already in use.
                    </div>
                    <div class="form-group paddingLeftCss col-md-5">
                        <label for="templateName" class="dropLableCss">Template Name <span>*</span></label>
                        <input type="text" [readonly]="isEditable" (change)="checkDuplication()"
                            class="form-control mt-2" [ngClass]="{ 'is-invalid': submitted && f.templateName.errors }"
                            style="font-size: 12px;" name="templateName" formControlName="templateName"
                            id="templateName" placeholder="Enter Name">
                        <div *ngIf="this.submitted && f.templateName.errors" class="text-danger">
                            <label *ngIf="f.templateName.errors.required">Please enter template name</label>
                        </div>
                    </div>
                    <div class="commonBorderBottom paddingLeftCss col-md-12">
                        <span class="dropLableCss">Add Capability</span>
                    </div>
                </div>
                <div class="row col-md-12 paddingRightCss">
                    <div class="col-md-5">
                        <span class="dropLableCss">Capability</span>
                    </div>
                    <div class="col-md-5 ml-2">
                        <span class="dropLableCss">Value</span>
                    </div>
                    <div class=" ml-auto">
                        <button type="button" class="btn btn-primary addBtnPopupCss btn-add"
                            (click)="addcapabilityRow()"><img class="mr-1" src="assets/images/add.png">Add</button>
                    </div>
                </div>
                <div formArrayName="capabilityIdValuesViews">
                    <ng-container 
                    *ngFor="let item of capabilityIdValuesViews.controls;  let pointIndex=index"
                    [formGroupName]="pointIndex">
                    <div class="paddingRightCss row col-md-12 form-group" *ngIf="isEnabled(capabilitytemplateForm.controls['capabilityIdValuesViews'].controls[pointIndex].controls.capabilityName.value)">
                        <div class="form-group col-md-5 "  *ngIf="isEnabled(capabilitytemplateForm.controls['capabilityIdValuesViews'].controls[pointIndex].controls.capabilityName.value)">
                            <select class="form-control txtControlCss mt-2"
                                [ngClass]="{ 'is-invalid': submitted && item.get('capabilityId').errors?.required  }"
                                (change)="onSelectionChange($event, pointIndex)" style="font-size: 12px;"
                                name="capabilityId_{{pointIndex}}" id="capabilityId_{{pointIndex}}"
                                formControlName="capabilityId" >
                                <option hidden value="0" disabled selected>Select</option>
                                <option [value]="capabilities.capabilityId"
                                    *ngFor="let capabilities of this.allCapabilityList">
                                    {{capabilities.capabilityName}}</option>
                            </select>
                            <div *ngIf="this.submitted && item.get('capabilityId').errors?.required "
                                class="text-danger">
                                <label *ngIf="item.get('capabilityId').errors?.required ">Please select the
                                    capability</label>
                            </div>

                        </div>
                        <div class="form-group col-md-5"  *ngIf="isEnabled(capabilitytemplateForm.controls['capabilityIdValuesViews'].controls[pointIndex].controls.capabilityName.value)">
                            <input type="text" class="form-control  mt-2" style="font-size: 12px;"
                                [ngClass]="{ 'is-invalid': submitted && item.get('capabilityValue').errors?.required  }"
                                formControlName="capabilityValue" name="capabilityValue_{{pointIndex}}"
                                id="capabilityValue_{{pointIndex}}" placeholder="Enter Value"  >
                            <!--   -->
                            <div *ngIf="this.submitted && item.get('capabilityValue').errors?.required "
                                class="text-danger">
                                <label *ngIf=" item.get('capabilityValue').errors?.required ">Please enter capability
                                    value</label>
                            </div>
                        </div>
                        <div class="marginTop10 col-md-1"  *ngIf="isEnabled(capabilitytemplateForm.controls['capabilityIdValuesViews'].controls[pointIndex].controls.capabilityName.value)">
                            <a><img (click)=" deleteCapabilityRow(pointIndex)" src="assets/images/trash.png"></a>
                        </div>
                    </div>
                </ng-container>
                    
                </div>

                <!-- Modal footer -->
                <div class="mt-5 pb-2 ml-auto mr-3 form-group">
                    <button type="button" *ngIf="!this.isEditable" (click)="clear()"
                        class="btn btn-primary clearBtnPopupCss mr-2">Reset</button>
                    <button type="submit" *ngIf="!this.isEditable" class="btn btn-primary saveBtnPopupCss">Save</button>
                    <button type="submit" *ngIf="this.isEditable"
                        class="btn btn-primary saveBtnPopupCss">Update</button>
                </div>

            </div>
        </div>
    </div>
</form>
<!-- View capability template -->

<div class="modal fade" id="viewCapabilityTemplateModal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="headerModal commonBorderBottom marginLeftRightCss row mt-2 pt-2">
                <div class="ml-3">
                    <h4 class="popupTitleTxt">View Capability Template</h4>
                </div>
                <div class="ml-auto mr-3">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
            </div>

            <!-- Modal body -->
            <div class="paddingRightCss modal-body">
                <div class="paddingRightCss row col-md-12">
                    <div class="paddingLeftCss col-md-10">
                        <span class="dropLableCss">Capability Name:</span><span class="capabilityTxt">Capability
                            1</span>
                    </div>
                    <div class=" col-md-2">
                        <button type="button" class="btn btn-primary clearBtnPopupCss"><img class="mr-2"
                                src="assets/images/edit.png">Edit</button>
                    </div>
                </div>
            </div>

            <div class="paddingRightCss row col-md-12 pb-2">
                <div class="form-group col-md-4">
                    <label for="regionId" class="dropLableCss">Capability</label>
                    <input type="text" class="form-control  mt-2" style="font-size: 12px;" name="capabilityName"
                        id="capabilityName" placeholder="Enter Name">
                    <input type="text" class="form-control  mt-2" style="font-size: 12px;" name="capabilityName"
                        id="capabilityName" placeholder="Enter Name">

                </div>

                <div class="form-group col-md-4">
                    <label for="regionId" class="dropLableCss">Type</label>
                    <input type="text" class="form-control  mt-2" style="font-size: 12px;" name="typeName" id="typeName"
                        placeholder="Enter Type">
                    <input type="text" class="form-control  mt-2" style="font-size: 12px;" name="typeName" id="typeName"
                        placeholder="Enter Type">

                </div>

                <div class="form-group col-md-4">
                    <label for="regionId" class="dropLableCss">Value</label>
                    <input type="text" class="form-control  mt-2" style="font-size: 12px;" name="capabilityValue"
                        id="capabilityValue" placeholder="Enter Value">
                    <input type="text" class="form-control  mt-2" style="font-size: 12px;" name="capabilityValue"
                        id="capabilityValue" placeholder="Enter Value">

                </div>
            </div>
        </div>
    </div>
</div>


<!-- view Data Set Modal -->


<!-- view Data Set Modal -->
<div class="modal fade" id="viewDataTemplateModal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="headerModal commonBorderBottom marginLeftRightCss row mt-2 pt-2">
                <div class="ml-3">
                    <h4 class="popupTitleTxt">View Data Set</h4>
                </div>
                <div class="ml-auto mr-3">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
            </div>

            <!-- Modal body -->
            <div class="paddingRightCss modal-body">
                <div class="paddingRightCss row col-md-12">
                    <div class="paddingLeftCss col-md-5">
                        <span class="dropLableCss">Test Suite:</span><span class="capabilityTxt">
                            {{ this.Sele_testSuiteText}}
                        </span>
                    </div>
                    <div class="paddingLeftCss col-md-5">
                        <span class="dropLableCss">Data Set Name:</span><span class="capabilityTxt">
                            {{this.dataSetName}}</span>
                    </div>
                </div>

            </div>
            <div>
                <div class="col-md-12 my-auto" *ngIf="this.Message_ds_modal">
                    <div class="text-center errorMessage">{{this.Message_ds_modal}}
                    </div>
                </div>
                <div *ngIf="this.Test_steps_iterations.length>0">
                    <div class="row marginCss  whiteBackgroundCss mt-2">
                        <div class="col-md-12 no-pad-right text-right">
                            <div class="searchMarginLeft">
                                <div class="form-group">
                                    <button type="button" (click)="AddIteration()"
                                        class="btn clearBtnCss btnIteration ">Add
                                        Iteration</button>
                                </div>
                            </div>
                        </div>
                        <div class="outer">
                            <table class="table  table-bordered dt" id="tblIterationData">
                                <thead style="max-height: 20px;">
                                    <tr>
                                        <th class="headcol1 WrapInLine">
                                            <span>Step No.</span>
                                        </th>
                                        <th class="headcol2 WrapInLine">
                                            <span> Test Case</span>
                                        </th>
                                        <th class="headcol2 WrapInLine">
                                            <span>Steps</span>
                                        </th>
                                        <th class="headcol3 WrapInLine">
                                            <span>Keyword</span>
                                        </th>
                                        <th class="headcol4 WrapInLine">
                                            <span>Element</span>
                                        </th>
                                        <th *ngFor="let steps of this.No_of_col; let i=index">
                                            <div class="WrapInLine" style="white-space:nowrap; min-width: 100px">
                                                Iteration{{this.Iterations_keys[i]}}
                                                <span style="float:right;">
                                                    <a style="cursor: pointer;"
                                                        (click)="deleteIteration(this.Iterations_keys[i])"> <img
                                                            src="assets/images/trash.png"> </a>
                                                </span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dt of  this.Test_steps_iterations; let p=index ;">
                                        <td class="headcol1" id="{{dt.testStepId}}">
                                            {{p+1}}
                                        </td>
                                        <td class="headcol2" id="{{dt.testCaseId}}">
                                            {{ dt.testCaseName}}
                                        </td>
                                        <td class="headcol2" id="{{dt.testStepId}}">
                                            {{ dt.testStepName}}
                                        </td>
                                        <td class="headcol3" id="{{dt.keyword}}">
                                            {{ dt.keyword}}
                                        </td>
                                        <td class="headcol4" id="{{dt.element}}">
                                            {{ dt.element}}
                                        </td>
                                        <td class="{{ this.Test_steps_iterations[p].isdataRequired==false ? 'datanot-req' : ''}}"
                                            [style.background]="this.Test_steps_iterations[p].isdataRequired==false ? '#f2f2f2': 'white'"
                                            [attr.contenteditable]="this.Test_steps_iterations[p].isdataRequired"
                                            *ngFor="let coldt of  this.No_of_col; let i = index; ">
                                            {{this.Test_steps_iterations[p]["Iteration"+ this.Iterations_keys[i]]}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-12 text-right no-pad-right">
                            <div class="searchMarginLeft">
                                <div class="form-group">
                                    <button type="button" (click)="addDataSet()" class="btn btn saveBtnCss">
                                        Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Select Device Modal -->
<div class="modal fade" id="SelectDeviceTemplateModal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="headerModal commonBorderBottom marginLeftRightCss row mt-2 pt-2">
                <div class="ml-3">
                    <h4 class="popupTitleTxt">{{this.strTitle}}</h4>
                </div>
                <div class="ml-auto mr-3">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="col-md-12 ">
                    <div class="text-center errorMessage headermsg" *ngIf="this.selectDevice_error">
                        {{this.selectDevice_error}}
                    </div>
                </div>
            </div>

            <!-- Modal body -->
            <div class=" modal-body" style="overflow-y: scroll;height:500px;">

                <div *ngIf="this.serverDevices.length>0">
                    <div *ngIf="this.deviceType=='DeviceFarm'" class=" row col-md-12">
                        <div class="paddingLeftCss col-md-5" style="margin-bottom: 10px;">
                            <span class="devicePoolNameCss">Device Pool :</span>
                            <span class="devicePoolNameCss ml-1">{{this.devicePool}}</span>
                        </div>
                    </div>

                    <div *ngIf="this.deviceType == 'DeviceFarm' || this.deviceType == 'Headspin' || this.deviceType == 'BrowserStack' || this.deviceType=='LocalBrowserStack'" 
                        class=" row col-md-12">

                        <div class="devicePoolNameCss">Template Name :</div>
                        <div class="paddingLeftCss col-md-4 ml-1">
                            <div class="">
                                <input type="text" (change)="CheckIsTemplateAssignedToDevice($event,0)"
                                class="form-control txtControlCss txtTemplate-name" [(ngModel)]="df_templateName"
                                (change)="UpdateTempateNameInlocalJson(0,$event)" name="{{this.serverDevices.id}}"
                                autocomplete="off" pattern="[^\s]+[a-zA-Z ]*[^\s]+"
                                    placeholder="Enter Template  Name" list="DataSetList_TemplateNames"/>
                                <datalist id="DataSetList_TemplateNames">
                                    <option *ngFor="let data of this.allCapabilityTemplate; let i=index">
                                        {{data.usrCapabilityTemplateName}}</option>
                                        
                                </datalist>
                               
                            </div>
                            <div style="font-size: 10px;" class="text-center errorMessage headermsg">
                                <span id="lblErrorMsg{{i}}"></span>
                                {{this.var_manual_templatename_Error}}
                            </div>
                        </div>
                    </div>
                    <div class="paddingRightCss row col-md-12">
                        <div class="paddingLeftCss col-md-3" style="margin-top: 7px !important;" [ngStyle]="{'margin-bottom':this.deviceType == 'Headspin' || this.deviceType=='BrowserStack'  ? '10px;' : '0px;' }" >
                            <span class="dropLableCss1" [ngStyle]="{'line-height':this.deviceType == 'Headspin' || this.deviceType=='BrowserStack' || this.deviceType=='LocalBrowserStack' ? '37px;':'' }">{{this.strAvailable}}</span>
                        </div>
                        <div class="col-md-4 p-0" style="margin-left: -20px;" *ngIf=" this.deviceType == 'Headspin' || this.deviceType=='BrowserStack' || this.deviceType=='LocalBrowserStack'">
                            <label class="container_ ">
                                <input id="All" class="checkBoxClass hsanycheck" (click)="checkAnyDevice($event)" type="checkbox">
                                <span class="checkmark mt-2"></span>
                                <span class="txtControlLblCss mr-2 dropLableCss1" style="margin-left: 24px;">Execute
                                    on Any One Device</span>
                            </label>

                        </div>

                    </div>
                    <!-- <div *ngIf="this.isHSAnyDeviceSelected" class="paddingRightCss row col-md-12 mt-1 mb-1" style="border-top:2px solid #ccc;">
                        <div class="form-group col-md-6 paddingLeftCss">
                            <label for="os_version" class="txtControlLblCss">OS Versions<span>*</span></label>
                            <select class="form-control txtControlCss" [(ngModel)]='hsAnyDeviceSelectedOS' style="font-size: 12px;" id="os_version">
                                <option hidden value="" disabled selected>Select</option>
                                <option [ngValue]="os_version" *ngFor="let os_version of this.headspinDevicesOS">
                                    {{os_version}}</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="table-responsive" *ngIf="!this.isHSAnyDeviceSelected">
                        <table width="auto" class="table table-striped table-bordered">
                            <thead class="dropLableCss1">
                                <tr style="text-align: left;">
                                    <th *ngIf="this.deviceType != 'DeviceFarm'" width="4%"></th>
                                    <th *ngIf="this.deviceType != 'DeviceFarm' && this.deviceType != 'Headspin' && this.deviceType!='BrowserStack' && this.deviceType!='LocalBrowserStack'"
                                        class="WrapInLine">
                                        <span>Template Name &nbsp;</span>
                                        <div class="inlineImages my-auto">
                                        </div>
                                    </th>
                                    <th class="WrapInLine">
                                        <span>{{this.deviceBrowserName}} &nbsp;</span>
                                        <div class="inlineImages my-auto">
                                        </div>
                                    </th>
                                    <th class="WrapInLine">
                                        <span>Platform &nbsp;</span>
                                        <div class="inlineImages my-auto">
                                        </div>
                                    </th>
                                    <th class="WrapInLine" width="10%">
                                        <span>OS Version &nbsp;</span>
                                    </th>
                                    <th *ngIf="this.deviceType != 'BrowserStack' && this.deviceType!='LocalBrowserStack'" class="WrapInLine">
                                        <span>Form Factor &nbsp;</span>
                                        <div class="inlineImages my-auto">
                                        </div>
                                    </th>
                                    <th *ngIf="this.deviceType != 'BrowserStack' && this.deviceType!='LocalBrowserStack'" class="WrapInLine">
                                        <span>Availability &nbsp;</span>
                                        <div class="inlineImages my-auto">
                                        </div>
                                    </th>
                                    <th *ngIf="this.deviceType == 'Headspin'" class="WrapInLine">
                                        <span>Location &nbsp;</span>
                                        <div class="inlineImages my-auto">
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="tbody" *ngIf="this.deviceType != 'Headspin' && this.deviceType!='BrowserStack' && this.deviceType!='LocalBrowserStack'">
                                <ng-container>
                                    <tr *ngFor="let device of this.serverDevices | filter:SearchDeviceName || OSVersion || Platform || formFactor || Availability; let i=index"
                                        id="thFont">
                                        <td *ngIf="this.deviceType != 'DeviceFarm'">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="checkBoxClass"
                                                    class="custom-control-input checkBoxClass" id="{{device.id}}"
                                                    name="serverDevices" [checked]="device.selected" value="false"
                                                    (click)="SelectCheckbox($event, 'jobNameName'+ device.id, i )">
                                                <label class="custom-control-label" for="{{device.id}}"></label>
                                            </div>
                                        </td>
                                        <td *ngIf="this.deviceType != 'DeviceFarm'">
                                            <div class="col-md-12">
                                                <input type="text" (change)="CheckIsTemplateAssignedToDevice($event,i)"
                                                    class="form-control txtControlCss txtTemplate-name" disabled
                                                    id="jobNameName{{device.id}}"
                                                    (change)="UpdateTempateNameInlocalJson(i,$event)"
                                                    name="jobNameName{{device.id}}" autocomplete="off"
                                                    pattern="[^\s]+[a-zA-Z ]*[^\s]+" placeholder="Enter Template  Name"
                                                    list="DataSetList_TemplateNames" />
                                                <datalist id="DataSetList_TemplateNames">
                                                    <option
                                                        *ngFor="let data of this.allCapabilityTemplate; let i=index">
                                                        {{data.usrCapabilityTemplateName}}</option>
                                                </datalist>
                                            </div>
                                            <div id="lblError{{i}}" class="col-md-12 required-template-in">
                                                <div style="font-size: 10px;"
                                                    class="text-center errorMessage headermsg">
                                                    <span id="lblErrorMsg{{i}}"></span>
                                                    {{this.var_manual_templatename_Error}}
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{device.name}}</td>
                                        <td>{{device.platform}}</td>
                                        <td>{{device.os}}</td>
                                        <td>{{device.formFactor}}</td>
                                        <td>{{device.availability}}</td>
                                      
                                    </tr>
                                </ng-container>

                            </tbody>
                            <tbody id="tbody" *ngIf="this.deviceType == 'Headspin'">
                                <ng-container>
                                    <tr *ngFor="let device of this.serverDevices | filter:SearchDeviceName || OSVersion || Platform || formFactor || Availability; let i=index"
                                        id="thFont">
                                        <td>
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="checkBoxClass"
                                                    class="custom-control-input checkBoxClass" id="{{device.deviceId}}"
                                                    name="serverDevices"
                                                    (click)="selectHeadspinDevice($event, device )">
                                                <label class="custom-control-label" for="{{device.deviceId}}"></label>
                                            </div>
                                        </td>
                                        <td>{{device.deviceName}}</td>
                                        <td>{{device.platform}}</td>
                                        <td>{{device.osVersion}}</td>
                                        <td>Phone</td>
                                        <td>{{device.availability}}</td>
                                        <td>{{device.deviceLocation}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody id="tbody" *ngIf="this.deviceType=='BrowserStack' || this.deviceType=='LocalBrowserStack'">
                                <ng-container>
                                    <tr *ngFor="let device of this.serverDevices | filter:SearchDeviceName || OSVersion || Platform; let i=index"
                                    id="thFont">
                                    <td>
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" class="checkBoxClass"
                                                class="custom-control-input checkBoxClass" id="{{device.device}}_{{device.os_version}}"
                                                name="serverDevices"
                                                (click)="selectDevices($event, device )">
                                            <label class="custom-control-label" for="{{device.device}}_{{device.os_version}}"></label>
                                        </div>
                                    </td>
                                    <td >{{device.device}}</td>
                                    <td >{{device.os}}</td>
                                    <td >{{device.os_version}}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="this.serverDevices.length <= 0">
                    <div class="row marginCss paddingCss whiteBackgroundCss mt-2">
                        <div class="col-md-12 no-pad-right text-center" style="margin-top: 100px;">
                            <div class="searchMarginLeft">
                                <div class="form-group">
                                    <span style="color: #cfbcbc; font-size: 23px;"> Device list not available
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 modal-footer" style="padding-top: 0px; padding-bottom: 0px;">
                <div class="text-right">
                    <button id="btnSelectDeviceSave" type="submit" (click)="submitSelectedDevices()"
                        class="btn saveBtnCss1">Done</button>&nbsp;
                </div>
            </div>
        </div>
    </div>
</div>

<!-- --------- Display Progress bar Modal---------- -->
<div class="modal fade" id="popProgress" data-keyboard="false" data-backdrop="static"
    style="z-index: 2147483647;background: black;opacity: 0.8;">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <!-- Modal body -->
            <div class=" modal-body">
                <div class="container">
                    <h2 style="font-size: 12px;">{{this.steps}}</h2>
                    <div class="progress" *ngIf="this.isFileUpload">
                        <div class="progress-bar" [style.width]="widthfooter">{{widthfooter}}</div>
                    </div>
                    <div *ngIf="!this.isFileUpload">
                        <img src="assets/images/Buffer.gif" height="50px" width="430px">
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<wtaf-schedule-popup></wtaf-schedule-popup>